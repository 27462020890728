// @flow
import React, { Component } from 'react';
import type { Props } from 'react';
import { clientEnhance, getDeviceType } from '@fil-global/eiswagen';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, get } from 'lodash';

// Global Import
import { onDeviceResize } from './global/actions';

// Style Import
import './styles/styles.scss';

// Routes Import
import ApplicationLayout from './components/templates/ApplicationLayout';

class App extends Component<Props> {
  setDeviceConfig = debounce(() => {
    const { deviceType, onDeviceResizeAction } = this.props;

    const currentDeviceType = getDeviceType();

    if (deviceType && currentDeviceType !== deviceType) {
      onDeviceResizeAction({ deviceType: currentDeviceType });
    }
  }, 250);

  componentDidMount = () => {
    window.addEventListener('resize', this.setDeviceConfig);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.setDeviceConfig);
  };

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <ApplicationLayout />
      </Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  deviceType: get(state, 'global.globalData.deviceType', ''),
});

const mapDispatchToProps = (dispatch) => ({
  onDeviceResizeAction: bindActionCreators(onDeviceResize, dispatch),
});

// To initialise the store
export default clientEnhance(App, {
  key: 'app',
  mapStateToProps,
  mapDispatchToProps,
});
