// @flow
import {
  SAVE_TNC,
  FETCH_TNC,
  FETCH_TNC_SUCCESS,
  FETCH_TNC_FAILURE,
  SAVE_TNC_FAILURE,
  SAVE_TNC_SUCCESS,
  CLEAR_TNC_ERROR,
} from './TermsAndConditions.constants';

import type { Labels } from './types';

export const saveTnc = (): { type: typeof SAVE_TNC } => ({ type: SAVE_TNC });

export const fetchTnc = (): { type: typeof FETCH_TNC } => ({ type: FETCH_TNC });

export const fetchTncSuccess = (labels: Labels): { type: typeof FETCH_TNC_SUCCESS } => ({
  type: FETCH_TNC_SUCCESS,
  labels,
});

export const fetchTncFailure = (): { type: typeof FETCH_TNC_FAILURE } => ({
  type: FETCH_TNC_FAILURE,
});

export const saveTncFailure = (): { type: typeof SAVE_TNC_FAILURE } => ({
  type: SAVE_TNC_FAILURE,
});

export const saveTncSuccess = (): { type: typeof SAVE_TNC_SUCCESS } => ({
  type: SAVE_TNC_SUCCESS,
});

export const clearError = (): { type: typeof CLEAR_TNC_ERROR } => ({ type: CLEAR_TNC_ERROR });

export default [fetchTnc];
