/*
 *
 * ForgotLoginDetails constants
 *
 */

export const VERIFY_USERNAME = '@FIL/PVLOGIN/VERIFY_USERNAME';
export const VERIFY_USERNAME_SUCCESS = '@FIL/PVLOGIN/VERIFY_USERNAME_SUCCESS';
export const VERIFY_USERNAME_FAILURE = '@FIL/PVLOGIN/VERIFY_USERNAME_FAILURE';

export const NO_USER_CODE = 'IAMAU5707';
export const EMAIL_NOT_VERIFIED_CODE = 'WI0600013';
export const RESET_PASSWORD_DISABLE_CODE = 'WI0600115';
export const DEVICE_ASSOCIATED_CODE = 'WI0600118';
export const USER_VERIFICATION_CODE = 'WI0600117';
export const SECURITY_QUES_CODE = 'WI0600119';

export const NO_USER = 'noUserNameFound';
export const RESET_PASSWORD_DISABLE = 'resetPasswordDisabled';
export const VERIFICATION_REQUIRED = 'verificationRequired';
export const ANALYTICS_SQ = 'security question';
export const ANALYTICS_VERFIFY_OTP = 'otp';
export const ANALYTICS_UNABLE_RESET = 'enter username: unable to reset';
export const ANALYTICS_VERIFICATION_REQUIRED =
  'enter username: enter username: verification required';

export const ANALYTICS_USER_REMINDER = 'enter username: username reminder';
