/*
 *
 * ForgotLoginDetails reducer
 *
 */

// @flow
import { combineReducers } from 'redux';
import { updateState } from '@fil-global/eiswagen';

import ResetPassword from '../ResetPassword/ResetPassword.reducer';

import {
  VERIFY_USERNAME,
  VERIFY_USERNAME_SUCCESS,
  VERIFY_USERNAME_FAILURE,
} from './ForgotLoginDetails.constants';

export function verifyUsername(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case VERIFY_USERNAME:
      return updateState(state, { isFetching: true });
    case VERIFY_USERNAME_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case VERIFY_USERNAME_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

// $FlowFixMe eslint-disable-line
const userReducer = combineReducers({ verifyUsername, ResetPassword });

export default userReducer;
