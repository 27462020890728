import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';

import LoginCompleteComponent from '../../../components/organisms/LoginComplete';

export const mapStateToProps = (state) => ({
  labels: get(state, 'global.globalData.labels.loginCompleteLabels', ''),
});
export const mapDispatchToProps = {};

export default clientEnhance(LoginCompleteComponent, {
  key: 'LoginComplete',
  mapStateToProps,
  mapDispatchToProps,
});
