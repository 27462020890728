// @flow
import { takeLatest, call, put, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';
import { setLocalStorage } from '@fil-global/eiswagen';

import {
  RETRIEVE_USER_NAME,
  ANALYTICS_UNABLE_TO_FIND,
  ANALYTICS_UNABLE_TO_RETRIEVE,
  ANALYTICS_VERIFICATION_REQUIRED,
  ANALYTICS_CONFIRMATION,
  ANALYTICS_OTP,
} from './RetrieveUserName.constants';
import {
  SERVICE_ERROR,
  NO_USER_DETAILS_CODE,
  NO_USER_DETAILS,
  CANT_RETRIEVE_USER_CODE,
  USER_VERIFICATION_CODE,
  USER_OTP_VERIFY_CODE,
  CANT_RETRIEVE_USER,
  LOGIN_PAGE,
  SHOW_USERNAME_PAGE,
  OTP_VERIFY,
  VERIFICATION_REQUIRED,
  RESET_PWD_PAGE,
  STATUS_M,
  STATUS_L,
  USER_OTP_VERIFY_CODE_STATUS_L,
  STATUS_D_CODE,
  INVALID_CREDENTIALS,
} from '../../../global/constants';
import {
  setActivePage,
  showServiceError,
  setActivePopup,
  setToken,
  setUserStatus,
} from '../../../global/actions';
import { retrieveUserNameSuccess, retrieveUserNameFailure } from './RetrieveUserName.actions';
import { getMemberStatusAction } from '../../templates/LoginPage/LoginPage.actions';
import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import type { Action, Error } from './types';

import {
  SITE_SECTION_RETRIEVE,
  CHANNEL_ACRONYM_RETRIEVE,
  SITE_SECTION_RESET,
  CHANNEL_ACRONYM_RESET,
} from '../../../global/AnalyticsTracking/constants';

import { LOADER } from '../../templates/LoginPage/LoginPage.constants';

export function* handleError(error: Error): Generator<any, any, any> {
  const data = (error && error.data) || {};

  let currentPopup = {};
  switch (data.code) {
    case NO_USER_DETAILS_CODE:
      currentPopup = {
        isShowPopup: true,
        newActivePopup: NO_USER_DETAILS,
      };
      yield put(
        setActivePopup(
          currentPopup,
          ANALYTICS_UNABLE_TO_FIND,
          SITE_SECTION_RETRIEVE,
          CHANNEL_ACRONYM_RETRIEVE
        )
      );
      break;

    case STATUS_D_CODE:
      currentPopup = {
        isShowPopup: true,
        newActivePopup: INVALID_CREDENTIALS,
      };
      setLocalStorage('local-storage-user-status', { userStatus: 'D', token: error.data.token });
      yield put(setActivePopup(currentPopup));
      break;
    case CANT_RETRIEVE_USER_CODE:
      currentPopup = {
        isShowPopup: true,
        newActivePopup: CANT_RETRIEVE_USER,
        redirectPage: LOGIN_PAGE,
      };
      yield put(
        setActivePopup(
          currentPopup,
          ANALYTICS_UNABLE_TO_RETRIEVE,
          SITE_SECTION_RETRIEVE,
          CHANNEL_ACRONYM_RETRIEVE
        )
      );
      break;
    case USER_OTP_VERIFY_CODE:
      currentPopup = {
        isPageChanged: true,
        newActivePage: OTP_VERIFY,
      };
      yield put(setToken(error.data.token));
      yield put(
        setActivePage(currentPopup, ANALYTICS_OTP, SITE_SECTION_RETRIEVE, CHANNEL_ACRONYM_RETRIEVE)
      );
      break;
    case USER_OTP_VERIFY_CODE_STATUS_L:
      currentPopup = {
        isPageChanged: true,
        newActivePage: OTP_VERIFY,
      };
      yield put(setToken(error.data.token));
      yield put(
        setActivePage(currentPopup, ANALYTICS_OTP, SITE_SECTION_RESET, CHANNEL_ACRONYM_RESET)
      );
      break;
    case USER_VERIFICATION_CODE:
      currentPopup = {
        isShowPopup: true,
        newActivePopup: VERIFICATION_REQUIRED,
      };
      yield put(setToken(error.data.token));

      yield put(
        setActivePopup(
          currentPopup,
          ANALYTICS_VERIFICATION_REQUIRED,
          SITE_SECTION_RETRIEVE,
          CHANNEL_ACRONYM_RETRIEVE
        )
      );

      break;
    default:
      yield put(showServiceError(SERVICE_ERROR));
      break;
  }
}

export function* redirectToActivePage(data, userStatus) {
  if (userStatus === STATUS_M) {
    const resetPasswordPage = {
      isPageChanged: true,
      newActivePage: RESET_PWD_PAGE,
    };
    yield put(retrieveUserNameSuccess());
    yield put(setActivePage(resetPasswordPage));
  } else {
    const currentPage = {
      isPageChanged: true,
      newActivePage: SHOW_USERNAME_PAGE,
    };
    yield put(retrieveUserNameSuccess(data.username));
    if (data.state === STATUS_M) {
      yield put(setUserStatus(STATUS_M));
    }
    yield put(
      setActivePage(
        currentPage,
        ANALYTICS_CONFIRMATION,
        SITE_SECTION_RETRIEVE,
        CHANNEL_ACRONYM_RETRIEVE
      )
    );
  }
}

export function* retrieveUserName(action: Action): Saga<void> {
  const memberCode = get(action, 'formData.memberCode');
  const inputData = {
    uid: memberCode,
    payload: { memberCode },
  };
  try {
    let url = apiEndPoints.retrieveUserName;
    let param = action.formData;
    if (param) {
      const nino = param.NINO ? param.NINO.replace(/\s/g, '').toUpperCase() : param.NINO;
      param.NINO = nino;
    }

    const store = yield select();
    const userStatus = get(store, 'global.userStatus.status');
    if (action.postValidation) {
      url = apiEndPoints.retrieveUserNamePostValidate;
      param = {
        token: get(store, 'global.loginData.token'),
      };
    }
    if (userStatus === STATUS_M || userStatus === STATUS_L) {
      url = apiEndPoints.validatePersonelDetails;
      param.token = get(store, 'global.loginData.token');
    }
    const { data } = yield call(axiosServiceClient.post, url, param);
    yield put(setToken(data.token));
    yield put(getMemberStatusAction(inputData));
    yield call(redirectToActivePage, data, userStatus);
    yield put({
      type: LOADER,
      active: false,
    });
  } catch (e) {
    yield put(retrieveUserNameFailure());
    if (get(e, 'response.data.code') !== NO_USER_DETAILS_CODE) {
      yield put(getMemberStatusAction(inputData));
    }

    yield call(handleError, e.response);
    yield put({
      type: LOADER,
      active: false,
    });
  }
}

export default function* retrieveUserNameSaga(): Saga<void> {
  yield takeLatest(RETRIEVE_USER_NAME, retrieveUserName);
}
