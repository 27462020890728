/*
 *
 * AnswerSecurityQuestions actions
 *
 */
// @flow

import {
  ANSWER_SECURITY_QUESTION,
  ANSWER_SECURITY_QUESTION_FAILURE,
  ANSWER_SECURITY_QUESTION_SUCCESS,
  PICK_REF_DETAILS,
  PICK_REF_DETAILS_SUCCESS,
  PICK_REF_DETAILS_FAILURE,
} from './AnswerSecurityQuestions.constantsV2';

const answerSecurityQuestions = (payload: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION,
  payload,
});
export const answerSecurityQuestionsSuccess = (data?: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION_SUCCESS,
  data,
});
export const answerSecurityQuestionsFailure = (error?: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION_FAILURE,
  error,
});

export const pickRefDetails = (): DefaultActionType => ({
  type: PICK_REF_DETAILS,
});
export const pickRefDetailsSuccess = (data?: { [string]: string }) => ({
  type: PICK_REF_DETAILS_SUCCESS,
  data,
});
export const pickRefDetailsFailure = (error?: { [string]: string }) => ({
  type: PICK_REF_DETAILS_FAILURE,
  error,
});
export const initialActions = [pickRefDetails];

export default answerSecurityQuestions;
