/**
 *
 * RememberDevicePopup
 *
 */

// @flow

import React, { PureComponent } from 'react';
import { UnsafeHtml, Button, Modal, getLabel, Grid, Cell } from '@fil-global/eiswurfel';

import type { Props } from './types';

class RememberDevicePopup extends PureComponent<Props> {
  static defaultProps = {};

  handleSubmit = (arcotSave: boolean) => {
    const { submitAuthenticate, closeModal } = this.props;
    closeModal();
    submitAuthenticate({ isArcot: arcotSave });
  };

  handleClose = () => {
    const { closeModal } = this.props;
    closeModal();
    this.handleSubmit(false);
  };

  render() {
    const { labels, showModal, closeModal } = this.props;
    const cancellable = {
      buttonText: getLabel(labels, 'close'),
      closeButtonHandler: this.handleClose,
    };
    return (
      <Modal
        showModal={showModal}
        cancellable={cancellable}
        closeModal={closeModal}
        {...this.props}
        title={getLabel(labels, 'title')}
        className="remember-device-popup popup-wrapper reveal">
        <UnsafeHtml wrapper="div">{getLabel(labels, 'content')}</UnsafeHtml>

        <Grid padding="grid-margin-x align-center cta-container">
          <Cell extraClasses="medium-6 text-right">
            <Button
              onClick={() => {
                this.handleSubmit(false);
              }}
              extraClasses="tertiary">
              {getLabel(labels, 'no')}
            </Button>
          </Cell>
          <Cell extraClasses="medium-6">
            <Button
              onClick={() => {
                this.handleSubmit(true);
              }}
              id="registerDevice">
              {getLabel(labels, 'yes')}
            </Button>
          </Cell>
        </Grid>
      </Modal>
    );
  }
}

export default RememberDevicePopup;
