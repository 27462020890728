/** * * SetupSecurityQuestions * */
// @flow
import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';
import reducer from './SetupSecurityQuestions.reducer';
import saga from './SetupSecurityQuestions.saga';
import {
  initialActions,
  fetchSecurityQuestion,
  submitSecurityQuestion,
} from './SetupSecurityQuestions.actions';
import { redirectLogout, redirectToLoginPage } from '../../templates/LoginPage/LoginPage.actions';
import {
  closeCurrentElements,
  setActivePopup,
  setSessionTimerId,
  LogOut,
} from '../../../global/actions';
import SetupSecurityQuestionsComponent from '../../../components/organisms/SetupSecurityQuestions';
import { PAGE_TIMEOUT, SESSION_TIMEOUT } from '../../../global/constants';
import type { State } from './types';

export const mapStateToProps = (state: State) => ({
  activePopup: get(state, 'global.loginData.activePopup'),
  pageTimeoutLabels: get(state, 'global.globalData.labels.pageTimeout', {}),
  isFetchingSecurityQues: get(state, 'SetupSecurityQuestions.fetchSecurityQuestion.isFetching'),
  isfetchingSubmitQues: get(state, 'SetupSecurityQuestions.submitSecurityQuestion.isFetching'),
  serviceError: get(state, 'global.globalData.serviceError', ''),
  errorLabels: get(state, 'global.globalData.labels.serviceErrors', ''),
  securityQuestionsLabels: get(state, 'global.globalData.labels.securityQuestionsLabels', ''),
  sessionTimeoutLabels: get(state, 'global.globalData.labels.sessionTimeout', {}),
  fetchAllSecurityQues: get(
    state,
    'SetupSecurityQuestions.fetchSecurityQuestion.data.Questions',
    []
  ),
});
export const mapDispatchToProps = {
  setActivePopup: setActivePopup.bind(null, { isShowPopup: true, newActivePopup: PAGE_TIMEOUT }),
  redirectLogout,
  closeCurrentElement: closeCurrentElements,
  fetchSecurityQuestions: fetchSecurityQuestion,
  setSecurityQuestions: submitSecurityQuestion,
  showSessionTimeoutPopup: setActivePopup.bind(null, {
    isShowPopup: true,
    newActivePopup: SESSION_TIMEOUT,
  }),
  setSessionTimerId,
  LogOut,
  redirectToLoginPage,
};

export default clientEnhance(SetupSecurityQuestionsComponent, {
  key: 'SetupSecurityQuestions',
  saga,
  reducer,
  initialActions,
  mapStateToProps,
  mapDispatchToProps,
});
