// @flow

import { CHANNEL, CHANNEL_ACRONYM, COUNTRY, IS_SPA, DEFAULT_SITE_SECTION } from './constants';
import { DEFAULT_LOCALE } from '../../constants';

const trackingOptions = () => ({
  channel: CHANNEL,
  channelAcronym: CHANNEL_ACRONYM,
  country: COUNTRY,
  language: (window && window.app_locale) || DEFAULT_LOCALE,
  siteSection: DEFAULT_SITE_SECTION,
  spa: IS_SPA,
});

export default trackingOptions;
