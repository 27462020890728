/*
 *
 * ResetPassword actions
 *
 */
// @flow

import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './ResetPassword.constants';

const resetPassword = (payload, setErrors) => ({
  type: RESET_PASSWORD,
  payload,
  setErrors,
});
export const resetPasswordSuccess = (data?: { [string]: string }) => ({
  type: RESET_PASSWORD_SUCCESS,
  data,
});
export const resetPasswordFailure = (error?: { [string]: string }) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

export default resetPassword;
