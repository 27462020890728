/*
 *
 * ForgotLoginDetails actions
 *
 */
// @flow

import {
  VERIFY_USERNAME,
  VERIFY_USERNAME_SUCCESS,
  VERIFY_USERNAME_FAILURE,
} from './ForgotLoginDetails.constants';

export const verifyUsername = (formData: Object) => ({ type: VERIFY_USERNAME, formData });
export const verifyUsernameSuccess = (data: Object) => ({ type: VERIFY_USERNAME_SUCCESS, data });
export const verifyUsernameFailure = (error: Object) => ({ type: VERIFY_USERNAME_FAILURE, error });

export default verifyUsername;
