/**
 *
 * RetrieveUserName
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import get from 'lodash/get';
import {
  GridContainer,
  Grid,
  Cell,
  Button,
  TextField,
  Form,
  withField,
  getLabel,
  Notification,
  Spinner,
  Label,
  FormattedTextInput,
  Checkbox,
  Tooltip,
  UnsafeHtml,
} from '@fil-global/eiswurfel';
import { subYears, isWithinRange } from '@fil-global/eiswagen';
import memoizeone from 'memoize-one';
import { CANT_RETRIEVE_USER } from '../../../global/constants';
import postMessage from '../../../utils/postMessage';

import {
  MINYEAR,
  MAXYEAR,
  NINO_REGEX,
  STAFF_NUM_REGEX,
  MEMBER_CODE_REGEX,
  SIR_NAME_REGEX,
  FOR_NAME_REGEX,
  DATE_REGEX,
} from '../../../containers/organisms/RetrieveUserName/RetrieveUserName.constants';
import Popup from '../../molecules/Popup';
import type { Props, PersonalDetailsFormData } from './types';
import { RESEND_LIMIT_REACH, DOB_TEXTFORMAT, NINO_TEXTFORMAT } from './RetrieveUserName.constants';

const TextFieldItem = withField(TextField);
const FormattedTextItem = withField(FormattedTextInput);
const CheckboxItem = withField(Checkbox);

class RetrieveUserName extends PureComponent<Props> {
  static defaultProps = {};

  getInitialValues = () => {
    let initailValues = {
      foreName: '',
      surName: '',
      DOB: '',
      NINO: '',
      staffNumber: '',
      memberCode: '',
      isNinoCheckbox: false,
    };
    const { piDetails } = this.props;
    if (piDetails) {
      initailValues = piDetails;
    }
    return initailValues;
  };

  getValidationSchema = memoizeone((labels) => ({
    foreName: {
      required: getLabel(labels, 'errorMessage.foreName.required'),
      matches: [FOR_NAME_REGEX, getLabel(labels, 'errorMessage.foreName.invalid')],
    },
    surName: {
      required: getLabel(labels, 'errorMessage.surName.required'),
      matches: [SIR_NAME_REGEX, getLabel(labels, 'errorMessage.surName.invalid')],
    },
    DOB: {
      required: getLabel(labels, 'errorMessage.DOB.required', 'DOB'),
    },
    memberCode: {
      required: getLabel(labels, 'errorMessage.memberCode.required'),
      matches: [MEMBER_CODE_REGEX, getLabel(labels, 'errorMessage.memberCode.invalid')],
    },
  }));

  /* Check whether the date in dd - mm - yy and in date range */

  isValidDate = (val) => {
    const [, day, month, year] = val.match(DATE_REGEX) || [];
    if (year) {
      const parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
      const maxDate = subYears(new Date(), MAXYEAR);
      const minDate = subYears(new Date(), MINYEAR);
      const isDateInRange = isWithinRange(parsedDate, minDate, maxDate);
      let isValidDate = false;
      if (parsedDate.getDate() === Number(day)) {
        isValidDate = true;
      }
      return isDateInRange && isValidDate;
    }
    return undefined;
  };

  ninoErrorMsg = (values) => {
    let error = '';
    const { labels } = this.props;
    const nino = values.NINO ? values.NINO.replace(/\s/g, '').toUpperCase() : values.NINO;
    const isValidNi = NINO_REGEX.test(nino);
    if (!values.isNinoCheckbox) {
      if (values.NINO === '') {
        error = getLabel(labels, 'errorMessage.NINO.required');
      }

      if (values.NINO !== '' && !isValidNi) {
        error = getLabel(labels, 'errorMessage.NINO.invalid');
      }
    }
    return error;
  };

  staffNumberErorMessage = (values) => {
    let error = '';
    const { labels } = this.props;
    const isValidStaffNumber = STAFF_NUM_REGEX.test(values.staffNumber);
    if (values.isNinoCheckbox) {
      if (values.staffNumber === '') {
        error = getLabel(labels, 'errorMessage.staffNumber.required');
      }

      if (values.staffNumber !== '' && !isValidStaffNumber) {
        error = getLabel(labels, 'errorMessage.staffNumber.invalid');
      }
    }
    return error;
  };

  validate = (values: PersonalDetailsFormData): ErrorsType => {
    const errors = {};

    const { labels } = this.props;
    const ninoErrorMsg = this.ninoErrorMsg(values);
    const staffNumberErorMessage = this.staffNumberErorMessage(values);

    if (ninoErrorMsg) {
      errors.NINO = ninoErrorMsg;
    }
    if (staffNumberErorMessage) {
      errors.staffNumber = staffNumberErorMessage;
    }

    if (values.DOB !== '' && !this.isValidDate(values.DOB)) {
      errors.DOB = getLabel(labels, 'errorMessage.DOB.invalid');
    }
    return errors;
  };

  onPopupClose = (): void => {
    const { activePopup, closePopup, pingToggle } = this.props;

    if (activePopup.newActivePopup === CANT_RETRIEVE_USER && pingToggle === 'on') {
      if (window.ReactNativeWebView) {
        postMessage('mobile_app_unsuccessful_username_recovery');
      } else {
        setTimeout(() => {
          /* istanbul ignore next */
          window.location = '/newlogin';
        }, 0);
      }
    } else if (window.ReactNativeWebView) {
      postMessage('mobile_app_unsuccessful_username_recovery');
    } else {
      closePopup(activePopup.redirectPage);
    }
  };

  updateFieldValue = (props, e) => {
    if (!get(props, 'values.isNinoCheckbox')) {
      props.setFieldValue('NINO', '');
    } else {
      props.setFieldValue('staffNumber', '');
    }
    props.handleChange(e);
  };

  render(): Node {
    const {
      isFetching,
      serviceError,
      clearError,
      labels,
      retrieveUserName,
      activePopup,
      hasError,
      verifyRequired,
      errorCode,
    } = this.props;

    const errorMessage =
      errorCode === RESEND_LIMIT_REACH
        ? getLabel(labels[activePopup.newActivePopup], 'resendLimitCount')
        : '';

    return (
      <GridContainer variation=" ">
        {activePopup && activePopup.isShowPopup ? (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            closeModal={this.onPopupClose}
            showModal
            setActivePage={verifyRequired}
            errorMessage={errorMessage}
          />
        ) : null}
        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container retrieve-username-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {hasError && (
                    <Cell>
                      <Notification {...serviceError} onClose={clearError} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                    <p className="text-center">{getLabel(labels, 'subtitle')}</p>
                  </Cell>
                  <Cell>
                    <Form
                      initialValues={this.getInitialValues()}
                      validationSchema={this.getValidationSchema(labels)}
                      handleSubmit={retrieveUserName}
                      validate={this.validate}
                      validateOnChange
                      validateOnBlur
                      formRenderFunc={(props) => (
                        <>
                          <div className="reg-tooltip">
                            <Label htmlFor="memberCode">{getLabel(labels, 'memberCode')}</Label>{' '}
                            <Tooltip
                              aria={{ open: 'open', close: 'close' }}
                              title={getLabel(labels, 'helpTextMemberCode.title')}
                              isInline>
                              <UnsafeHtml>{getLabel(labels, 'helpTextMemberCode.text')}</UnsafeHtml>
                            </Tooltip>
                          </div>
                          <TextFieldItem name="memberCode" maxLength="15" id="memberCode" />

                          <TextFieldItem
                            name="foreName"
                            label={getLabel(labels, 'foreName')}
                            maxLength="32"
                            id="foreName"
                          />
                          <TextFieldItem
                            name="surName"
                            label={getLabel(labels, 'surName')}
                            maxLength="32"
                            id="surName"
                          />
                          <div>
                            <Label htmlFor="userdob">{getLabel(labels, 'DOB')}</Label>
                            <FormattedTextItem
                              {...DOB_TEXTFORMAT}
                              type="text"
                              name="DOB"
                              id="userdob"
                            />
                          </div>
                          <div>
                            <div className="reg-tooltip">
                              <Label htmlFor="nino">{getLabel(labels, 'NINO')}</Label>{' '}
                              <Tooltip
                                aria={{ open: 'open', close: 'close' }}
                                title={getLabel(labels, 'helpTextNINO.title')}
                                isInline>
                                <UnsafeHtml>{getLabel(labels, 'helpTextNINO.text')}</UnsafeHtml>
                              </Tooltip>
                            </div>
                            <FormattedTextItem
                              {...NINO_TEXTFORMAT}
                              type="text"
                              disabled={!!get(props, 'values.isNinoCheckbox')}
                              name="NINO"
                              id="nino"
                            />
                          </div>
                          <CheckboxItem
                            name="isNinoCheckbox"
                            label={getLabel(labels, 'isNinoCheckbox')}
                            onChange={(e) => this.updateFieldValue(props, e)}
                            type="checkbox"
                            id="isNinoCheckbox"
                          />

                          <div
                            className={
                              get(props, 'values.isNinoCheckbox') ? 'reg-tooltip' : 'hide'
                            }>
                            <Label htmlFor="nino">{getLabel(labels, 'staffNumber')}</Label>{' '}
                            <Tooltip
                              aria={{ open: 'open', close: 'close' }}
                              title={getLabel(labels, 'helpTextStaffNumber.title')}
                              isInline>
                              <UnsafeHtml>
                                {getLabel(labels, 'helpTextStaffNumber.text')}
                              </UnsafeHtml>
                            </Tooltip>
                            <div>
                              <TextFieldItem name="staffNumber" maxLength="20" id="staffNumber" />
                            </div>
                          </div>
                          <Grid extraClasses="align-center">
                            <Cell extraClasses="text-center">
                              <Button type="submit" extraClasses="retrieve-username-cta expanded">
                                {getLabel(labels, 'cta')}
                              </Button>
                            </Cell>
                          </Grid>
                        </>
                      )}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default RetrieveUserName;
