/*
 *
 * OtpVerification actions
 *
 */
// @flow

import {
  SUBMIT_OTP,
  SUBMIT_OTP_SUCCESS,
  SUBMIT_OTP_FAILURE,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  OTP_NOTIFICATION_MESSAGE,
} from './OtpVerification.constants';

export const submitOtpAction = (otp: number) => ({ type: SUBMIT_OTP, otp });
export const submitOtpActionSuccess = (data: Object) => ({ type: SUBMIT_OTP_SUCCESS, data });
export const submitOtpActionFailure = (error: Object) => ({ type: SUBMIT_OTP_FAILURE, error });

export const resendOtpAction = () => ({ type: RESEND_OTP });
export const resendOtpActionSuccess = (data: Object) => ({ type: RESEND_OTP_SUCCESS, data });
export const resendOtpActionFailure = (error: Object) => ({ type: RESEND_OTP_FAILURE, error });

export const showOtpNotification = (otpResend: string) => ({
  type: OTP_NOTIFICATION_MESSAGE,
  optNotificationKey: otpResend,
});
