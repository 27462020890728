/*
 *
 * OtpSetup reducer
 *
 */

// @flow

import { updateState } from '@fil-global/eiswagen';
import { combineReducers } from 'redux';
import { findIndex, pullAt, replace, trim } from 'lodash';
import get from 'lodash/get';

import OTP_SETUP_CONST from './OtpSetUp.constants';
import { ACTIVE_POPUP } from '../../../global/constants';
import type { CountryObjectType, ReducerActionType, CountryApiResponseType } from './types';

export const parsedCountryResponse = ({ countries }: CountryApiResponseType): CountryObjectType => {
  const filteredArray = countries.filter(
    (country) => country.countryName !== OTP_SETUP_CONST.FIlTERED_COUNTRY
  );
  const factoredArray = filteredArray.map((country) => ({
    value: `${trim(country.countryCode)}-${trim(replace(country.value.toString(), /00\s/, ''))}`,
    countryCode: trim(country.countryCode),
    text: `+${trim(replace(country.text, /00/, ''))} - ${trim(country.countryCode)}`,
  }));

  const defaultCountryCodeIndex = findIndex(factoredArray, [
    OTP_SETUP_CONST.MATCH_PREDICATE,
    OTP_SETUP_CONST.PREDICATE_IDENTITY,
  ]);
  const pulledArray =
    defaultCountryCodeIndex !== -1 ? pullAt(factoredArray, defaultCountryCodeIndex) : [];
  if (pulledArray.length) {
    factoredArray.unshift(pulledArray[0]);
  }
  return factoredArray;
};

export function countryCodesReducer(state?: { [string]: string } = {}, action: ReducerActionType) {
  switch (action.type) {
    case OTP_SETUP_CONST.GET_COUNTRY_CODES:
      return updateState(state, { isFetching: true });
    case OTP_SETUP_CONST.GET_COUNTRY_CODES_SUCCESS:
      return updateState(state, {
        countryCodes: parsedCountryResponse(action.data),
        isFetching: false,
      });
    case OTP_SETUP_CONST.GET_COUNTRY_CODES_FAILURE:
      return updateState(state, { isFetching: false, isError: true });
    default:
      return state;
  }
}

export function validateOtpReducer(state?: { [string]: string } = {}, action: ReducerActionType) {
  switch (action.type) {
    case OTP_SETUP_CONST.VALIDATE_OTP:
      return updateState(state, { isFetching: true });

    case OTP_SETUP_CONST.VALIDATE_OTP_SUCCESS:
      return updateState(state, {
        isFetching: false,
        isEmailVerified: get(action, 'isVerified.isEmailVerified'),
        isMobileNumberVerified: get(action, 'isVerified.isMobileNumberVerified'),
        data: action.data,
        popUpVisible: false,
        isError: false,
        isResend: false,
      });

    case OTP_SETUP_CONST.VALIDATE_OTP_FAILURE:
      return updateState(state, {
        isFetching: false,
        isError: true,
        error: action.err,
      });
    case OTP_SETUP_CONST.RESEND_OTP:
      return updateState(state, { isFetching: true });

    case OTP_SETUP_CONST.RESEND_OTP_SUCCESS:
      return updateState(state, {
        isFetching: false,
        data: action.data,
        isError: false,
        isResend: true,
      });

    case OTP_SETUP_CONST.RESEND_OTP_FAILURE:
      return updateState(state, {
        isFetching: false,
        isError: true,
        error: action.err,
      });

    case ACTIVE_POPUP:
      return updateState(state, {
        popUpVisible: false,
        showPopUpType: null,
        mobileError: null,
        emailError: null,
        isFetching: false,
      });

    default:
      return state;
  }
}

export function verifyInputFieldReducer(
  state?: { [string]: string } = {},
  action: ReducerActionType
) {
  switch (action.type) {
    case OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS:
      return updateState(state, { isFetching: true });
    case OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS_SUCCESS:
      return updateState(state, {
        data: action.data,
        isFetching: false,
        showPopUpType: OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS,
        popUpVisible: true,
        isError: false,
        emailError: null,
      });
    case OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS_FAILURE:
      return updateState(state, {
        isFetching: false,
        emailError: action.err,
        isError: true,
      });
    case OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER:
      return updateState(state, { isFetching: true });
    case OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER_SUCCESS:
      return updateState(state, {
        data: action.data,
        isFetching: false,
        showPopUpType: OTP_SETUP_CONST.MOBILE_NUMBER,
        popUpVisible: true,
        mobileError: null,
        isError: false,
      });
    case OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER_FAILURE:
      return updateState(state, {
        isFetching: false,
        mobileError: action.err,
        isError: true,
      });
    case OTP_SETUP_CONST.CLOSE_OTP_POPUP:
      return updateState(state, { popUpVisible: false, isResend: false });
    default:
      return validateOtpReducer(state, action);
  }
}

export function challenge(state?: { [string]: string } = {}, action: ReducerActionType) {
  switch (action.type) {
    case OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS:
      return updateState(state, { isFetching: true });
    case OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS_SUCCESS:
      return updateState(state, { isFetching: false, challengeToken: action.data });
    case OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS_FAILURE:
      return updateState(state, { isFetching: false, err: action.err });
    default:
      return state;
  }
}

export function authenticateToken(state?: { [string]: string } = {}, action: ReducerActionType) {
  switch (action.type) {
    case OTP_SETUP_CONST.TOKEN_AUTHENTICATE:
      return updateState(state, { isFetching: true });
    case OTP_SETUP_CONST.TOKEN_AUTHENTICATE_SUCCESS:
      return updateState(state, { isFetching: false, isComplete: true });
    case OTP_SETUP_CONST.TOKEN_AUTHENTICATE_FAILURE:
      return updateState(state, { isFetching: false });
    default:
      return state;
  }
}
// $FlowFixMe eslint-disable-line
const otpSetUpReducer = combineReducers({
  countryCodesReducer,
  verifyInputFieldReducer,
  challenge,
  authenticateToken,
});

export default otpSetUpReducer;
