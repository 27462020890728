// @flow
/* eslint-disable no-underscore-dangle */

import { logger } from '@fil-global/eiswagen';
import { TrackingOptions } from './types';

/**
 * Set Tracking Data to window data layer
 * @param {TrackingOptions} trackingOptions
 */
const setDataLayer = ({
  channel,
  pageName,
  country,
  language,
  siteSection,
  channelAcronym,
  spa,
  debug,
  type,
  action,
}: TrackingOptions) => {
  if (typeof window !== 'undefined' && !action) {
    const updatedTracking: Object = {
      channel,
      country,
      language,
      site_section: siteSection,
      spa,
      page_name: `fid${country}: ${channelAcronym}: ${pageName}`,
    };

    const dataLayer = updatedTracking;

    if (debug) {
      logger.info('<!----- Updating Datalayer with config ----!>');
      logger.info({ dataLayer, debug, type, action });
    }
    window._dl = dataLayer;
  }
};

export default setDataLayer;
