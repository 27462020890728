/*
 *
 * ChangeUsernamePassword reducer
 *
 */

// @flow
import { updateState } from '@fil-global/eiswagen';

import {
  CHANGE_USERNAME_PWD,
  CHANGE_USERNAME_PWD_SUCCESS,
  CHANGE_USERNAME_PWD_FAILURE,
  CLEAR_CHANGE_USERNAME_PWD_ERROR,
} from './ChangeUsernamePassword.constants';

export default function ChangeUsernamePassword(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case CHANGE_USERNAME_PWD:
      return updateState(state, { isFetching: true });
    case CHANGE_USERNAME_PWD_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case CHANGE_USERNAME_PWD_FAILURE:
      return updateState(state, { isFetching: false, isError: true, error: action.error });
    case CLEAR_CHANGE_USERNAME_PWD_ERROR:
      return updateState(state, { isError: false });
    default:
      return state;
  }
}
