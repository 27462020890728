import { clientEnhance } from '@fil-global/eiswagen';
import { validateLoggedUser } from '../../templates/LoginPage/LoginPage.actions';
import { submitOtpAction, resendOtpAction, showOtpNotification } from './OtpVerification.actions';
import OtpVerificationComponent from '../../../components/organisms/OtpVerification';
import {
  closeCurrentElements,
  setActivePage,
  setCurrentJourney,
  setUserStatus,
} from '../../../global/actions';
import reducer from './OtpVerification.reducer';
import saga from './OtpVerification.saga';

import {
  selectOtpVerificationLabels,
  selectActivePopup,
  selectOptNotificationKey,
  selectServiceErrors,
  selectIsFetching,
  selectHasServiceError,
} from './OtpVerification.selectors';

export const mapStateToProps = (state) => ({
  isFetching: selectIsFetching(state),
  serviceError: selectServiceErrors(state),
  hasError: selectHasServiceError(state),
  otpVerificationLabels: selectOtpVerificationLabels(state),
  optNotificationKey: selectOptNotificationKey(state),
  activePopup: selectActivePopup(state),
});
export const mapDispatchToProps = {
  submitOtpButton: submitOtpAction,
  resendOtButton: resendOtpAction,
  closeCurrentElement: closeCurrentElements,
  submitAuthenticate: validateLoggedUser,
  setForgotUserPage: setActivePage,
  setCurrentJourney,
  setUserStatus,
  showOtpNotification,
};

export default clientEnhance(OtpVerificationComponent, {
  key: 'OtpVerification',
  reducer,
  saga,
  mapStateToProps,
  mapDispatchToProps,
});
