/**
 *
 * ApplicationLayout
 *
 */

// @flow

import React from 'react';

// Organism Import
import Header from '../../../containers/organisms/Header';
import Footer from '../../../containers/organisms/Footer';
import LoginPage from '../../../containers/templates/LoginPage';

const ApplicationLayout = () => (
  <>
    <Header />
    <LoginPage />
    <Footer />
  </>
);

ApplicationLayout.defaultProps = {};

export default ApplicationLayout;
