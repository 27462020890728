/*
 *
 * AnswerSecurityQuestions reducer
 *
 */

import { updateState } from '@fil-global/eiswagen';
import {
  ANSWER_SECURITY_QUESTION,
  ANSWER_SECURITY_QUESTION_FAILURE,
  ANSWER_SECURITY_QUESTION_SUCCESS,
} from './AnswerSecurityQuestions.constants';

export default function AnswerSecurityQuestionsReducer(state = {}, action) {
  switch (action.type) {
    case ANSWER_SECURITY_QUESTION:
      return updateState(state, { isFetching: true });
    case ANSWER_SECURITY_QUESTION_FAILURE:
      return updateState(state, { isFetching: false });
    case ANSWER_SECURITY_QUESTION_SUCCESS:
      return updateState(state, { isFetching: false });
    default:
      return state;
  }
}
