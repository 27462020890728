/**
 *
 * TermsAndConditions
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import {
  GridContainer,
  Grid,
  Cell,
  Button,
  getLabel,
  UnsafeHtml,
  Notification,
  Spinner,
} from '@fil-global/eiswurfel';

import Popup from '../../molecules/Popup';
import initSession from '../../../utils/session';
import { LOGIN_URL, PAGE_TIMEOUT, SESSION_TIMEOUT } from '../../../global/constants';
import {
  TIME_INTERVAL,
  SHOW_POPUP_TIME,
  SESSION_TIME_INTERVAL,
} from './TermsAndConditions.constants';
import type { Props, State } from './types';

class TermsAndConditions extends PureComponent<Props, State> {
  static defaultProps = {};

  timerId: ?IntervalID;

  inactivityTimer: ?IntervalID;

  constructor(props) {
    super(props);
    this.state = { time: SHOW_POPUP_TIME };
  }

  componentDidMount = () => {
    const {
      setActivePopup,
      showSessionTimeoutPopup,
      setSessionTimerId,
      sessionTimerId,
      LogOut,
    } = this.props;
    this.inactivityTimer = initSession(setActivePopup);

    if (!sessionTimerId) {
      const timerID = setTimeout(() => {
        showSessionTimeoutPopup();
        LogOut();
      }, SESSION_TIME_INTERVAL);
      setSessionTimerId(timerID);
    }
  };

  componentWillUnmount = () => {
    if (this.inactivityTimer) {
      clearInterval(this.inactivityTimer);
    }
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  /**
   * This function sets timer for 15 sec to let user decide about inactivity.
   * It also redirects to login page after 15 secs.
   */
  countDown = () => {
    const { time } = this.state;
    if (time === 1) {
      window.location.assign(LOGIN_URL);
    }

    if (time > 1) {
      this.setState((state) => ({ time: state.time - 1 }));
    }
  };

  closeWithClearTimer = () => {
    const { closeCurrentElement } = this.props;
    this.setState({ time: SHOW_POPUP_TIME });
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
    closeCurrentElement();
  };

  /**
   * This function render page timeout popup after inactivity on 15 mins.
   * It shows timer of 15 sec on popup to user to keep active.
   */

  renderPageTimeoutPopuup = (labels: Object, logout: () => mixed) => {
    if (!this.timerId) {
      this.timerId = setInterval(this.countDown, TIME_INTERVAL);
    }
    const label = { ...labels };
    const { time } = this.state;
    label.content = getLabel(label, 'content', { timer: time });
    return (
      <Popup
        labels={label}
        closeModal={logout}
        showModal={label.showModal}
        secondaryAction={logout}
        primaryAction={this.closeWithClearTimer}
      />
    );
  };

  renderActivePopup = (
    activePopup: Object,
    redirectToLoginPage: () => mixed,
    logout: () => mixed
  ) => {
    const { pageTimeoutLabels, sessionTimeoutLabels } = this.props;
    switch (activePopup.newActivePopup) {
      case SESSION_TIMEOUT:
        return (
          <Popup
            labels={sessionTimeoutLabels}
            showModal={sessionTimeoutLabels.showModal}
            closeModal={redirectToLoginPage}
            primaryAction={redirectToLoginPage}
          />
        );
      case PAGE_TIMEOUT:
        return this.renderPageTimeoutPopuup(pageTimeoutLabels, logout);

      default:
        return null;
    }
  };

  render(): Node {
    const {
      labels,
      saveTnc,
      isError,
      serviceError,
      clearError,
      isFetching,
      activePopup,
      logout,
      redirectToLoginPage,
    } = this.props;
    return (
      <GridContainer variation=" ">
        {activePopup && activePopup.isShowPopup
          ? this.renderActivePopup(activePopup, redirectToLoginPage, logout)
          : null}
        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {isError && (
                    <Cell>
                      <Notification {...serviceError} onClose={clearError} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <Grid extraClasses="align-center">
                      <Cell extraClasses="large-10">
                        <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                        <p className="text-center">{getLabel(labels, 'subtitle')}</p>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell extraClasses="cell-block-y tnc-text-container">
                    <UnsafeHtml wrapper="p">{getLabel(labels, 'content')}</UnsafeHtml>
                  </Cell>
                  <Cell extraClasses="text-center medium-6 large-4">
                    <Button extraClasses="primary-cta expanded" onClick={saveTnc}>
                      {getLabel(labels, 'Accept')}
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default TermsAndConditions;
