import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);
export const selectActivePopup = (state) => get(state, ['global', 'loginData', 'activePopup']);
export const selectHasServiceError = (state) => get(state, ['global', 'loginData', 'serviceError']);
export const selectIsFetching = (state) => get(state, ['answerSecurityQuestions', 'isFetching']);

export const selectErrorCode = (state) =>
  get(state, ['LoginPage', 'verifyRequired', 'error', 'response', 'data', 'code']);
export const selectSecurityQuestion = (state) =>
  get(state, ['LoginPage', 'setSecurityQues', 'securityQuestion']);

export const selectAnswerSecurityQuestionsLabels = createSelector(selectLabels, (labels) => ({
  ...get(labels, ['answerSecurityQuestions']),
  resetPasswordDisabled: get(labels, ['resetPasswordDisabled']),
  cantRetrieveUser: get(labels, ['cantRetrieveUser']),
}));

export const selectServiceErrors = createSelector(selectLabels, (labels) =>
  get(labels, ['serviceErrors'])
);
