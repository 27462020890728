// @flow

import { setTrackerConfig } from '@fil-global/eiswagen';

import {
  FETCH_LABEL,
  ACTIVE_POPUP,
  ACTIVE_PAGE,
  TRIGGER_ANALYTICS_CUSTOM_EVENT,
  TRIGGER_ANALYTICS_PAGE_VIEW_EVENT,
} from '../constants';
// import { TOKEN_AUTHENTICATE_SUCCESS } from '../../containers/templates/LoginPage/LoginPage.constants';
import trackingOptions from './trackerConfig';
import setDataLayer from './setDataLayer';

import OTP_SETUP_CONST from '../../containers/organisms/OtpSetUp/OtpSetUp.constants';

const initializeTrackerConfig = () =>
  setTrackerConfig({
    config: trackingOptions(),
    pageViewActions: [
      FETCH_LABEL,
      ACTIVE_POPUP,
      ACTIVE_PAGE,
      OTP_SETUP_CONST.OPEN_REMEMBER_DEVICE_POPUP,
      OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS_SUCCESS,
      OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER_SUCCESS,
      TRIGGER_ANALYTICS_PAGE_VIEW_EVENT,
    ],
    eventActions: [TRIGGER_ANALYTICS_CUSTOM_EVENT],
    debug: true,
    callback: setDataLayer,
  });

export default initializeTrackerConfig;
