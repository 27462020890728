import { updateState } from '@fil-global/eiswagen';
import { combineReducers } from 'redux';
import {
  SET_LOCALE,
  FETCH_LABEL,
  FETCH_LABEL_SUCCESS,
  FETCH_LABEL_FAILURE,
  PAGE_TRANSITION,
  DEVICE_RESIZE_ACTION,
  ACTIVE_PAGE,
  SERVICE_ERROR,
  CLOSE_ELEMENT,
  SET_TOKEN,
  ACTIVE_POPUP,
  USER_CREDS,
  ARCOT_ID,
  DELETE_USER_CREDS,
  JOURNEY,
  SET_SESSION_TIMER_ID,
  USER_STATUS,
} from './constants';

const globalData = (state = {}, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return updateState(state, { locale: action.data });
    case FETCH_LABEL:
      return updateState(state, { isFetching: true });
    case FETCH_LABEL_SUCCESS:
      return updateState(state, { labels: action.labels, isFetching: false });
    case FETCH_LABEL_FAILURE:
      return updateState(state, { isFetching: false, isError: true });
    case PAGE_TRANSITION:
      return updateState(state, { currentPage: action.page });
    case DEVICE_RESIZE_ACTION:
      return updateState(state, { deviceType: action.data });
    case SERVICE_ERROR:
      return updateState(state, { serviceError: action.serviceError });
    case JOURNEY:
      return updateState(state, { currentJourney: action.currentJourney });
    case CLOSE_ELEMENT:
      return updateState(state, {
        isFetching: false,
        serviceError: '',
        activePage: { isShowPopup: false },
      });
    default:
      return state;
  }
};

const loginData = (state = {}, action) => {
  switch (action.type) {
    case ACTIVE_PAGE:
      return updateState(state, { activePage: action.activePage });
    case ACTIVE_POPUP:
      return updateState(state, { activePopup: action.activePopup });
    case SERVICE_ERROR:
      return updateState(state, { serviceError: action.serviceError });
    case SET_TOKEN:
      return updateState(state, { token: action.token });
    case USER_CREDS:
      return updateState(state, { userdata: action.userdata });
    case ARCOT_ID:
      return updateState(state, { arcotId: action.arcotId });
    case DELETE_USER_CREDS:
      return updateState(state, { userdata: '', arcotId: '' });
    case SET_SESSION_TIMER_ID:
      return updateState(state, { sessionTimerId: action.sessionTimerId });

    case CLOSE_ELEMENT:
      return updateState(state, {
        isFetching: false,
        serviceError: '',
        activePopup: { isShowPopup: false },
      });
    default:
      return state;
  }
};

const userStatus = (state = {}, action) => {
  switch (action.type) {
    case USER_STATUS:
      return updateState(state, { status: action.userStatus });

    default:
      return state;
  }
};

export default combineReducers({ globalData, loginData, userStatus });
