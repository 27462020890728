import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects';
import get from 'lodash/get';
import { logger } from '@fil-global/eiswagen';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  fetchSecurityQuestionSuccess,
  fetchSecurityQuestionFailure,
  submitSecurityQuestionSuccess,
  submitSecurityQuestionFailure,
} from './SetupSecurityQuestions.actions';
import { setActivePage } from '../../../global/actions';
import { TERMS_AND_CONDITIONS_PAGE } from '../../../global/constants';
import {
  FETCH_SECURITY_QUES,
  SUBMIT_SECURITY_QUES,
  TC_ANALYTICS,
} from './SetupSecurityQuestions.constants';
import { proceedToSilentLogin } from '../../templates/LoginPage/LoginPage.actions';
import postMessage from '../../../utils/postMessage';

export function* getAllSecurityQues() {
  try {
    const { data } = yield call(axiosServiceClient.get, apiEndPoints.fetchAllSecurityQuestions);
    yield put(fetchSecurityQuestionSuccess(data));
  } catch (error) {
    logger.info(error);
    yield put(fetchSecurityQuestionFailure(error));
  }
}

export function* submitSecurityQues(formData) {
  try {
    const requestData = {
      Question: formData.values,
    };
    const { data } = yield call(
      axiosServiceClient.post,
      apiEndPoints.saveUserSecurityAnswer,
      requestData
    );
    yield put(submitSecurityQuestionSuccess(data));
    const state = yield select();
    const tncVersion = get(state, 'global.loginData.activePage.TncVersion', null);
    if (tncVersion) {
      const currentPage = {
        isPageChanged: true,
        newActivePage: TERMS_AND_CONDITIONS_PAGE,
        TncVersion: tncVersion,
      };
      postMessage('mobile_app_has_not_agreed_to_the_latest_tcs');
      yield put(setActivePage(currentPage, TC_ANALYTICS));
    } else {
      yield put(proceedToSilentLogin());
    }
  } catch (error) {
    logger.info(error);
    yield put(submitSecurityQuestionFailure(error));
  }
}

export function* fetchAllSecurityQuestions() {
  yield takeLatest(FETCH_SECURITY_QUES, getAllSecurityQues);
}

export function* submitAllSecurityQuestions() {
  yield takeLatest(SUBMIT_SECURITY_QUES, submitSecurityQues);
}

export default function* securityQuestions() {
  yield all([fork(fetchAllSecurityQuestions), fork(submitAllSecurityQuestions)]);
}
