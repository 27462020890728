// @flow
const getURLParameter = (parameter) => {
  let paramValue = '';
  if (typeof window !== 'undefined') {
    const queryString = window.location.search.substring(1);
    const queryTokens = queryString.split('&');
    queryTokens.forEach((item) => {
      const itemTokens = item.split('=');
      const [param, value] = itemTokens;
      if (param === parameter) {
        paramValue = value;
      }
    });
  }
  return paramValue;
};
export default getURLParameter;
