/*
 *
 * OtpVerification constants
 *
 */

export const SUBMIT_OTP = '@FIL/PVLOGIN/SUBMIT_OTP';
export const SUBMIT_OTP_SUCCESS = '@FIL/PVLOGIN/SUBMIT_OTP_SUCCESS';
export const SUBMIT_OTP_FAILURE = '@FIL/PVLOGIN/SUBMIT_OTP_FAILURE';
export const RESEND_OTP = '@FIL/PVLOGIN/RESEND_OTP';
export const RESEND_OTP_SUCCESS = '@FIL/PVLOGIN/RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILURE = '@FIL/PVLOGIN/RESEND_OTP_FAILURE';
export const OTP_NOTIFICATION_MESSAGE = '@FIL/PVLOGIN/OTP_NOTIFICATION_MESSAGE';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const RESEND_FAILURE = 'RESEND_FAILURE';
export const INCORRECT_OTP = 'INCORRECT_OTP';
export const VELOCITY_COUNT_REACHED = 'VELOCITY_COUNT_REACHED';

export const REMEMBER_DEVICE = 'REMEMBER_DEVICE';

export const OTP_FAIL_COUNT = 'IAMAU5700';
export const INVALID_OTP = 'IAMAU5707';
export const OTP_FAIL_COUNT_EXCEED = 'IAMAU5705';
export const USER_VELOCITY_COUNT_REACHED = 'WI0150006';
export const OTP_LENGTH = 6;
export const ANALYTICS_CHOOSE_NEW_PASSWORD = 'choose new password';
export const ANALYTICS_REMEMBER_DEVICE = 'login: otp: remember device';
export const ANALYTICS_USER_NAME = 'enter username';
