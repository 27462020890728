import { createHttpClient, logger } from '@fil-global/eiswagen';
import RiskMinder from '@fil-global/fil-lib-riskminder';
import get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';
import { PROFILING_DOMAIN, PAGE_ID } from '../global/constants';

const SESSION_ID = uuidv4().replace(/-/g, '');

export const profiletmx = () => {
  if (window.thsfiltmx) {
    const tmxSessionId = 'tmxSessionId';
    document.cookie =
      tmxSessionId + '=' + SESSION_ID + ';domain=.fidelity.co.uk;path=/;secure=true';
    thsfiltmx.profile(PROFILING_DOMAIN, process.env.REACT_APP_ORG_ID_TMX, SESSION_ID, PAGE_ID);
  }
};
profiletmx();

const axiosConfig = {};
const axiosClient = createHttpClient(axiosConfig);
const url = window.location;
const apiHost = 'https://' + url.hostname + '/gateway';
console.log(apiHost);
const axiosServiceConfig = {
  host: process.env.REACT_APP_STACK_ROUTING_TOGGLE ? apiHost : process.env.REACT_APP_API_HOST,
  withCredentials: true,
};
export const axiosServiceClient = createHttpClient(axiosServiceConfig);

axiosServiceClient.interceptors.request.use(
  function (config) {
    // All api call with target as PAAS are able to parse requestId header but for IAM apis it is expected to send rid header.
    const url = get(config, 'url');
    const uuid = url && url.indexOf('iam') === -1 ? { requestid: uuidv4() } : {};
    config.headers = {
      ...config.headers,
      mfp: JSON.stringify(RiskMinder.getMFPasJSON()),
      ...uuid,
    };
    return config;
  },
  function (error) {
    console.log(error); //when error sending the request
    return Promise.reject(error);
  }
);

export const axiosClientWithCredentials = createHttpClient({ withCredentials: true });

export default axiosClient;
