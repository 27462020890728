/*
 *
 * AccountSummary reducer
 *
 */

// @flow
import { combineReducers } from 'redux';
import { updateState } from '@fil-global/eiswagen';

import {
  FETCH_SECURITY_QUES,
  FETCH_SECURITY_QUES_SUCCESS,
  FETCH_SECURITY_QUES_FAILURE,
  SUBMIT_SECURITY_QUES,
  SUBMIT_SECURITY_QUES_SUCCESS,
  SUBMIT_SECURITY_QUES_FAILURE,
} from './SetupSecurityQuestions.constants';

export function fetchSecurityQuestion(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case FETCH_SECURITY_QUES:
      return updateState(state, { isFetching: true });
    case FETCH_SECURITY_QUES_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case FETCH_SECURITY_QUES_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function submitSecurityQuestion(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case SUBMIT_SECURITY_QUES:
      return updateState(state, { isFetching: true });
    case SUBMIT_SECURITY_QUES_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case SUBMIT_SECURITY_QUES_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

// $FlowFixMe eslint-disable-line
const userReducer = combineReducers({ fetchSecurityQuestion, submitSecurityQuestion });

export default userReducer;
