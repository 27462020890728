/**
 *
 * LoginComplete
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import {
  Grid,
  ReactLink,
  GridContainer,
  Cell,
  UnsafeHtml,
  getLabel,
  Notification,
} from '@fil-global/eiswurfel';
import postMessage from '../../../utils/postMessage';

import type { Props } from './types';

class LoginComplete extends PureComponent<Props> {
  static defaultProps = {};

  redirectToLogIn = (e) => {
    const { isReset } = this.props;

    if (window.ReactNativeWebView && isReset) {
      e.preventDefault();
      postMessage('mobile_app_successful_password_reset');
    }
  };

  render(): Node {
    const { labels } = this.props;

    if (!labels) {
      return null;
    }
    return (
      <GridContainer variation=" ">
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  <Cell extraClasses="heading">
                    <Grid extraClasses="align-center">
                      <Cell extraClasses="large-10">
                        <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell extraClasses="change-successful">
                    <Notification theme="success" closeIcon={false}>
                      <UnsafeHtml wrapper="p">{getLabel(labels, 'content')}</UnsafeHtml>
                    </Notification>
                  </Cell>
                  <Cell extraClasses="text-center ">
                    <ReactLink
                      onClick={this.redirectToLogIn}
                      id="login-button"
                      {...labels.loginButton}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default LoginComplete;
