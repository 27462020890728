export default {
  labels: '/assets/login/labels.json',
  mockApi: '/pvloginApi',
  challenge: '/wi/iam/v1/challenge',
  userValidate: '/wi/iam/v1/user/validate',
  changeUsername: '/wi/iam/v1/username/change',
  keepUsername: '/wi/iam/v1/user/association',
  expiryCheck: '/wi/iam/v1/expiryCheck',
  authenticate: '/wi/iam/v1/token/authenticate',
  resendOtp: '/wi/iam/v1/otp/resend',
  submitOtp: '/wi/iam/v1/otp/validate',
  retrieveUserName: '/wi/iam/v1/forgot/username',
  retrieveUserNamePostValidate: '/wi/iam/v1/forgot/username/verify',
  createNewUsername: 'wi/iam/v1/register/credential/create',
  validateSecurityQuestions: '/custMgmnt/v1/protected/user/bus/performPostAuthChecks',
  fetchAllSecurityQuestions: '/custMgmnt/v1/common/data/fetchAllSecurityQuestions',
  saveUserSecurityAnswer: '/custMgmnt/v1/protected/user/bus/saveUserSecurityAnswer',
  fetchCountryCodes: '/custMgmnt/v1/user/mobOTPAllowedCountries',
  sendOtp: '/wi/iam/v1/otp/send',
  validateOtp: '/wi/iam/v1/otp/validate',
  fetchTnc: '/assets/termsAndConditions/${version}/labels.json', //eslint-disable-line
  saveTnc: '/custMgmnt/v1/protected/user/data/saveTermsAndConditions',
  resend: '/wi/iam/v1/otp/resend',
  verifyUsername: '/wi/iam/v1/forgot/password/verify',
  resetPassword: '/wi/iam/v1/forgot/password/reset ',
  ValidateSecQ: '/wi/iam/v1/secq',
  forgotSendOtp: '/wi/iam/v1/forgot/otp/send',
  validatePersonelDetails: '/wi/iam/v1/forgot/password/validate/details',
  resetAdminPassword: '/wi/iam/v1/admin/pwd/reset ',
  logout: '/wi/iam/v1/logout',
  logoutPlanviewer: '/planviewer/DoLogout.action',
  encrypt: '/custMgmnt/v1/user/bus/getSecureValue',
  getMemberInfo: '/custMgmnt/v1/jwt/protected/data/fetchUUIDfromUserName',
  preAuthToken: '/custMgmnt/v1/api/auth/jwt/token',
  ssoLogout: '/wi/iam/sso/v1/logout',
  decodeToken: '/custMgmnt/v1/api/auth/jwt/decode',
  performPostAuthCheck:
    'https://ciam-api-dev.fidelity.co.uk/gateway/custMgmnt/v1/protected/user/bus/performPostAuthChecks',
  referenceDetails: '/wpfh-ciam-intg/referenceDetails',
  loginHandler: '/wpfh-ciam-intg/loginHandler',
  authHandler: '/wpfh-ciam-intg/authHandler',
  pickRefDetails: '/wpfh-ciam-ref/pickRefDetails',
  dropRefDetails: '/wpfh-ciam-ref/dropRefDetails',
  fetchRandomSecurityQuestions: '/planviewer/v1/securityQuestions',
  validateRandomSecurityQuestions: '/planviewer/v1/securityQuestions',
};
