import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);
export const selectHasServiceError = (state) => get(state, ['global', 'loginData', 'serviceError']);
export const selectIsFetching = (state) =>
  get(state, ['ForgotLoginDetails', 'ResetPassword', 'isFetching']);
export const selectUserType = (state) => get(state, ['LoginPage', 'user', 'type']);

export const selectResetPasswordLabels = createSelector(
  selectLabels,
  selectUserType,
  (labels, userType) => ({
    ...(userType === 'admin'
      ? get(labels, ['changePasswordAdminLabels'])
      : get(labels, ['resetPasswordLabels'])),
  })
);

export const selectServiceErrors = createSelector(selectLabels, (labels) =>
  get(labels, ['serviceErrors'])
);
