// @flow

const getURLPathParams = () => {
  const pathParamMap = new Map();
  if (typeof window !== 'undefined') {
    const pathString = window.location.href.split('#');
    const elems = pathString[1].split('/');
    const arr = elems.filter((item) => item);
    if (arr.length === 2) {
      pathParamMap.set(arr[0], arr[1]);
    }
  }
  return pathParamMap;
};
export default getURLPathParams;
