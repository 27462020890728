/**
 *
 * SetUserNamePassword
 *
 */

// @flow

import React, { PureComponent } from 'react';
import memoizeone from 'memoize-one';
import uniqBy from 'lodash/uniqBy';
import type { Node } from 'react';
import {
  Form,
  Button,
  SelectBox,
  UnsafeHtml,
  TextField,
  withField,
  getLabel,
  Spinner,
  Notification,
  GridContainer,
  Grid,
  Cell,
} from '@fil-global/eiswurfel';
import {
  ANSWER_REGEX,
  TIME_INTERVAL,
  SHOW_POPUP_TIME,
  SESSION_TIME_INTERVAL,
} from './SetupSecurityQuestions.constants';
import type { Props, State } from './types';
import Popup from '../../molecules/Popup';
import initSession from '../../../utils/session';

import { LOGIN_URL, SESSION_TIMEOUT, PAGE_TIMEOUT } from '../../../global/constants';

const TextFieldItem = withField(TextField);
const SelectFieldItem = withField(SelectBox);

class SetupSecurityQuestions extends PureComponent<Props, State> {
  static defaultProps = {};

  timerId: ?IntervalID;

  inactivityTimer: ?IntervalID;

  constructor(props) {
    super(props);
    this.state = { time: SHOW_POPUP_TIME };
  }

  componentDidMount = () => {
    const { setActivePopup, showSessionTimeoutPopup, setSessionTimerId, LogOut } = this.props;
    this.inactivityTimer = initSession(setActivePopup);
    const sessionTimerId = setTimeout(() => {
      LogOut();
      showSessionTimeoutPopup();
    }, SESSION_TIME_INTERVAL);
    setSessionTimerId(sessionTimerId);
  };

  getOptionList = memoizeone((fetchAllSecurityQues) => {
    const fetchOptions = fetchAllSecurityQues.map((option) => ({
      text: option.Question,
      value: option.Id,
    }));
    return fetchOptions;
  });

  componentWillUnmount = () => {
    if (this.inactivityTimer) {
      clearInterval(this.inactivityTimer);
    }
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  };

  handleSubmit = (values: Object) => {
    const { securityQuestionsLabels, setSecurityQuestions } = this.props;
    const securityQuestions = [];
    for (let i = 0; i < securityQuestionsLabels.questions.length; i += 1) {
      securityQuestions.push({
        Id: values[`question-${i + 1}`],
        Answer: values[`answer-${i + 1}`],
      });
    }
    setSecurityQuestions(securityQuestions);
  };

  getValidationSchema = () => {
    const { securityQuestionsLabels } = this.props;
    const validateSchema = {};
    securityQuestionsLabels.questions.forEach((item, key) => {
      validateSchema[`answer-${key + 1}`] = {
        required: getLabel(securityQuestionsLabels, 'validationErrors.answer.required'),
        matches: [
          ANSWER_REGEX,
          getLabel(securityQuestionsLabels, 'validationErrors.answer.invalid'),
        ],
      };
    });
    return validateSchema;
  };

  validate = (values: Object) => {
    const { securityQuestionsLabels } = this.props;
    let isrequired = false;
    const securityQuestions = [];
    for (let i = 0; i < securityQuestionsLabels.questions.length; i += 1) {
      securityQuestions.push({
        Id: values[`question-${i + 1}`],
        Answer: values[`answer-${i + 1}`].toUpperCase(),
      });
      isrequired = values[`answer-${i + 1}`] === '';
    }
    const errors = {};
    if (
      !isrequired &&
      (uniqBy(securityQuestions, 'Id').length !== securityQuestionsLabels.questions.length ||
        uniqBy(securityQuestions, 'Answer').length !== securityQuestionsLabels.questions.length)
    ) {
      errors['answer-4'] = getLabel(securityQuestionsLabels, 'validationErrors.unique.invalid');
    }
    return errors;
  };

  getInitialValues = () => {
    const { securityQuestionsLabels } = this.props;
    const initialValues = {};
    securityQuestionsLabels.questions.forEach((item, key) => {
      initialValues[`question-${key + 1}`] = '1';
      initialValues[`answer-${key + 1}`] = '';
    });
    return initialValues;
  };

  /**
   * This function sets timer for 15 sec to let user decide about inactivity.
   * It also redirects to login page after 15 secs.
   */
  countDown = () => {
    const { time } = this.state;
    if (time === 1) {
      window.location.assign(LOGIN_URL);
    }

    if (time > 1) {
      this.setState((state) => ({ time: state.time - 1 }));
    }
  };

  closeWithClearTimer = () => {
    const { closeCurrentElement } = this.props;
    this.setState({ time: SHOW_POPUP_TIME });
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
    closeCurrentElement();
  };

  /**
   * This function render page timeout popup after inactivity on 15 mins.
   * It shows timer of 15 sec on popup to user to keep active.
   */
  renderPageTimeoutPopuup = (labels: Object, redirectLogout: () => mixed) => {
    if (!this.timerId) {
      this.timerId = setInterval(this.countDown, TIME_INTERVAL);
    }
    const label = { ...labels };
    const { time } = this.state;
    label.content = getLabel(label, 'content', { timer: time });
    return (
      <Popup
        labels={label}
        closeModal={redirectLogout}
        showModal={label.showModal}
        secondaryAction={redirectLogout}
        primaryAction={this.closeWithClearTimer}
      />
    );
  };

  renderActivePopup = (
    activePopup: Object,
    redirectToLoginPage: () => mixed,
    redirectLogout: () => mixed
  ) => {
    const { pageTimeoutLabels, sessionTimeoutLabels } = this.props;
    switch (activePopup.newActivePopup) {
      case SESSION_TIMEOUT:
        return (
          <Popup
            labels={sessionTimeoutLabels}
            showModal={sessionTimeoutLabels.showModal}
            closeModal={redirectToLoginPage}
            primaryAction={redirectToLoginPage}
          />
        );
      case PAGE_TIMEOUT:
        return this.renderPageTimeoutPopuup(pageTimeoutLabels, redirectLogout);

      default:
        return null;
    }
  };

  render(): Node {
    const {
      securityQuestionsLabels,
      fetchAllSecurityQues,
      isFetchingSecurityQues,
      isfetchingSubmitQues,
      errorLabels,
      serviceError,
      closeCurrentElement,
      activePopup,
      redirectLogout,
      redirectToLoginPage,
    } = this.props;

    if (!securityQuestionsLabels || !fetchAllSecurityQues) {
      return null;
    }
    return (
      <GridContainer variation=" ">
        {activePopup && activePopup.isShowPopup
          ? this.renderActivePopup(activePopup, redirectToLoginPage, redirectLogout)
          : null}
        {isFetchingSecurityQues || isfetchingSubmitQues ? (
          <Spinner isVisible displayType="fixed" size="large" />
        ) : null}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container security-page-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {serviceError && (
                    <Cell>
                      <Notification {...errorLabels} onClose={closeCurrentElement} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(securityQuestionsLabels, 'title')}</h2>
                    <UnsafeHtml extraClasses="text-center">
                      {getLabel(securityQuestionsLabels, 'subTitle')}
                    </UnsafeHtml>
                  </Cell>
                  <Cell>
                    <Form
                      initialValues={this.getInitialValues()}
                      validationSchema={this.getValidationSchema()}
                      validate={this.validate}
                      handleSubmit={this.handleSubmit}
                      id="security-form"
                      formRenderFunc={() => (
                        <>
                          {securityQuestionsLabels.questions.map((question, key) => (
                            <div id={`questions-${key + 1}`} key={`questions-${key + 1}`}>
                              <SelectFieldItem
                                name={`question-${key + 1}`}
                                className={`question-${key + 1}-input`}
                                label={question.ques}
                                id={`question-${key + 1}`}
                                optionList={this.getOptionList(fetchAllSecurityQues)}
                                key={`question-${key + 1}`}
                              />
                              <TextFieldItem
                                name={`answer-${key + 1}`}
                                className={`answer-${key + 1}-input`}
                                label={question.ans}
                                maxLength="45"
                                id={`answer-${key + 1}`}
                                key={`answer-${key + 1}`}
                              />
                            </div>
                          ))}
                          <Grid extraClasses="align-center">
                            <Cell extraClasses="medium-6 large-4">
                              <Button
                                type="submit"
                                variation="expanded"
                                extraClasses="confirm-cta expanded"
                                id="security-button">
                                {getLabel(securityQuestionsLabels, 'confirmButton.children')}
                              </Button>
                            </Cell>
                          </Grid>
                        </>
                      )}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default SetupSecurityQuestions;
