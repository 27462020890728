import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

import FooterComponent from '../../../components/organisms/Footer';

export const Footer = (props) => <FooterComponent {...props} />;

export const mapStateToProps = (state) => ({
  deviceType: get(state, 'global.globalData.deviceType', ''),
  labels: get(state, 'global.globalData.labels.footer', ''),
});

export default connect(mapStateToProps)(Footer);
