/*
 *
 * VerifyOtpPopUp constants
 *
 */

const OTP_SETUP_CONST = Object.freeze({
  RESEND_LIMIT_REACH: 'WI0150006',
  REACH_FAILURE_COUNT: 'IAMAU5700',
  INVALID_OTP: 'IAMAU5707',
  OTP_REGEX: /^[0-9]+$/,
});

export default OTP_SETUP_CONST;
