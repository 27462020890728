/**
 *
 * Header
 *
 */

// @flow

import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { StaticHeader, List, Anchor } from '@fil-global/eiswurfel';

import type { Props } from './types';

const renderStaticHeader = (headerList) =>
  headerList.map((item, key) => (
    <li
      key={`header_link_${key.toString()}`}
      className={classnames(['header-content-list-item'])}
      role="none">
      {!item.isLink && item.linkText}
      {item.isLink && (
        <Anchor target="_blank" href={item.href}>
          {item.linkText}{' '}
        </Anchor>
      )}
    </li>
  ));

export const getLogoConfig = (ismobile) => {
  const logo = {};
  if (ismobile) {
    logo.path = null;
    logo.className = 'in-mobile';
  }
  return logo;
};

const Header = ({ header, deviceType }: Props): Node => (
  <div className="page-header">
    <StaticHeader deviceType={deviceType} logos={getLogoConfig(isMobile)} className="row">
      <List className="header-content-list" isBulleted={false} role="none">
        {header ? renderStaticHeader(header.headerLinks) : ''}
      </List>
    </StaticHeader>
  </div>
);

Header.defaultProps = {};

export default Header;
