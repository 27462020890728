/*
 *
 * AnswerSecurityQuestions reducer
 *
 */

import { updateState } from '@fil-global/eiswagen';
import get from 'lodash/get';
import {
  ANSWER_SECURITY_QUESTION,
  ANSWER_SECURITY_QUESTION_FAILURE,
  ANSWER_SECURITY_QUESTION_SUCCESS,
  PICK_REF_DETAILS,
  PICK_REF_DETAILS_SUCCESS,
  PICK_REF_DETAILS_FAILURE,
} from './AnswerSecurityQuestions.constantsV2';

export default function AnswerSecurityQuestionsReducer(state = {}, action) {
  switch (action.type) {
    case ANSWER_SECURITY_QUESTION:
      return updateState(state, { isFetching: true });
    case ANSWER_SECURITY_QUESTION_FAILURE:
      return updateState(state, { isFetching: false });
    case ANSWER_SECURITY_QUESTION_SUCCESS:
      return updateState(state, { isFetching: false });
    case PICK_REF_DETAILS:
      return updateState(state, { isFetching: true });
    case PICK_REF_DETAILS_FAILURE:
      return updateState(state, { isFetching: false });
    case PICK_REF_DETAILS_SUCCESS:
      return updateState(state, { isFetching: false, resumePath: get(action, 'data.resumePath') });

    default:
      return state;
  }
}
