// @flow

const getWindowVersion = () => {
  let nt;

  if (window.navigator.userAgent.indexOf('Windows NT') !== -1) {
    nt = window.navigator.userAgent.split('NT')[1].split(';')[0].trim();
  }
  switch (nt) {
    case '10.0':
      return 10;
    case '6.2':
      return 8;
    case '6.1':
      return 7;
    case '6.0':
      return 'Windows Vista';
    case '5.1':
      return 'Windows XP';
    case '5.0':
      return 'Windows 2000';
    default:
      return null;
  }
};
export default getWindowVersion;
