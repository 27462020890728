import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);

export const selectActivePopup = (state) => get(state, ['global', 'loginData', 'activePopup']);

export const selectHasServiceError = (state) => get(state, ['global', 'loginData', 'serviceError']);
export const selectErrorCode = (state) =>
  get(state, ['LoginPage', 'verifyRequired', 'error', 'response', 'data', 'code']);

export const selectForgotLoginDetailError = (state) =>
  get(state, ['ForgotLoginDetails', 'verifyUsername', 'error', 'response', 'data', 'code']);

export const selectIsFetching = (state) =>
  get(state, ['ForgotLoginDetails', 'verifyUsername', 'isFetching']);
export const selectUsername = (state) => get(state, ['LoginPage', 'username', 'username']);

export const selectForgotLoginDetailLabels = createSelector(selectLabels, (labels) => ({
  ...get(labels, ['forgotLoginDetailLabels']),
  noUserNameFound: get(labels, ['noUserNameFound']),
  resetPasswordDisabled: get(labels, ['resetPasswordDisabled']),
  verificationRequired: get(labels, ['verificationRequired']),
  invalidCredentials: get(labels, ['invalidCredentials']),
}));

export const selectServiceErrors = createSelector(selectLabels, (labels) =>
  get(labels, ['serviceErrors'])
);
