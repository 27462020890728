/**
 *
 * VerifyOtpPopUp
 *
 */

// @flow

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import type { Node } from 'react';
import {
  TextField,
  Form,
  withField,
  Button,
  Grid,
  Modal,
  Paragraph,
  UnsafeHtml,
  getLabel,
  Cell,
  Icon,
} from '@fil-global/eiswurfel';

import type { Props, formikPropsType } from './types';
import OTP_SETUP_CONST from './VerifyOtpPopUp.constants';

const TextFieldItem = withField(TextField);

class VerifyOtpPopUp extends PureComponent<Props> {
  static defaultProps = {};

  formProps: ?formikPropsType;

  validate = (values: { inputPassCode: string }) => {
    const errors = {};
    const isValidOtp = OTP_SETUP_CONST.OTP_REGEX.test(values.inputPassCode);
    const { otpSetUpLabels } = this.props;
    if (values.inputPassCode && !isValidOtp) {
      errors.inputPassCode = getLabel(otpSetUpLabels, 'verifyOtpPopUp.invalidCode', 'invalidCode');
    }

    return errors;
  };

  hasFailureCountReached = () => {
    const { params } = this.props;

    return (
      get(params, 'isError') && get(params, 'error.code') === OTP_SETUP_CONST.REACH_FAILURE_COUNT
    );
  };

  hasResendOtpLimitReached = () => {
    const { params } = this.props;

    return (
      get(params, 'isError') && get(params, 'error.code') === OTP_SETUP_CONST.RESEND_LIMIT_REACH
    );
  };

  resendOtp = () => {
    const { params } = this.props;
    if (this.formProps && this.formProps.handleReset) {
      this.formProps.handleReset();
    }
    params.handleReSendOTP();
  };

  isError = (props: formikPropsType): boolean => {
    const { params } = this.props;
    const { errors } = props;
    const error =
      get(params, 'isError') &&
      !get(errors, 'inputPassCode') &&
      (get(params, 'error.code') === OTP_SETUP_CONST.RESEND_LIMIT_REACH ||
        get(params, 'error.code') === OTP_SETUP_CONST.REACH_FAILURE_COUNT ||
        get(params, 'error.code') === OTP_SETUP_CONST.INVALID_OTP);

    return error;
  };

  handleError = () => {
    const { params, otpSetUpLabels } = this.props;
    let error;
    switch (get(params, 'error.code')) {
      case OTP_SETUP_CONST.RESEND_LIMIT_REACH:
        error = getLabel(otpSetUpLabels, 'verifyOtpPopUp.resendLimitCount');
        break;
      case OTP_SETUP_CONST.REACH_FAILURE_COUNT:
        error = getLabel(otpSetUpLabels, 'verifyOtpPopUp.reachFailureCount');
        break;
      case OTP_SETUP_CONST.INVALID_OTP:
        error = getLabel(otpSetUpLabels, 'verifyOtpPopUp.invalidCode', 'invalidCode');
        break;
      default:
        break;
    }

    return error;
  };

  formRenderFunc = (props: Object): Node => {
    const { otpSetUpLabels, params } = this.props;
    const { values } = props;
    this.formProps = props;
    const showError = this.isError(props);
    return (
      <>
        <TextFieldItem
          name="inputPassCode"
          label={getLabel(otpSetUpLabels, 'verifyOtpPopUp.inputPassCode', 'inputPassCode')}
          type="text"
          className={classnames({
            'is-invalid-input': showError,
          })}
          maxlength="6"
          id="inputPassCode"
        />

        {showError && (
          <div aria-live="assertive" role="alert" className="field-error form-error is-visible">
            <span className="icon fil-icon fil-icon-error">
              <span className="show-for-sr">error</span>
            </span>
            <Paragraph>{this.handleError()}</Paragraph>
          </div>
        )}

        <Grid extraClasses="grid-margin-x align-center mt-button cta-container">
          <Cell extraClasses="medium-6 text-right">
            <Button
              theme="secondary"
              disabled={this.hasResendOtpLimitReached()}
              onClick={this.resendOtp}>
              {getLabel(otpSetUpLabels, 'verifyOtpPopUp.requestAnotherCode', 'requestAnotherCode')}
            </Button>

            {params.isResend && !get(params, 'isError') && (
              <div className="resend-otp-success">
                <Icon iconType="circle-tick" />
                {getLabel(otpSetUpLabels, 'verifyOtpPopUp.resendOtpSuccess', 'resendOtpSuccess')}
              </div>
            )}
          </Cell>
          <Cell extraClasses="medium-6">
            <Button
              theme="primary"
              type="submit"
              disabled={
                this.hasResendOtpLimitReached() ||
                this.hasFailureCountReached() ||
                get(values, 'inputPassCode').length < 6
              }>
              {getLabel(otpSetUpLabels, 'verifyOtpPopUp.confirm', 'confirm')}
            </Button>
          </Cell>
        </Grid>
      </>
    );
  };

  render() {
    const { params } = this.props;
    return (
      <Modal
        showModal={params.showModal}
        cancellable={params.cancellable}
        {...this.props}
        title={params.title}
        className="popup-wrapper reveal">
        <UnsafeHtml wrapper="div" extraClasses="otppopup-para">
          {getLabel(params, 'description')}
        </UnsafeHtml>
        <Form
          initialValues={{ inputPassCode: '' }}
          handleSubmit={params.handlePopUpSubmit}
          validate={this.validate}
          formRenderFunc={(props) => this.formRenderFunc(props)}
        />
      </Modal>
    );
  }
}

export default VerifyOtpPopUp;
