/*
 *
 * OtpSetUp constants
 *
 */

const OTP_SETUP_CONST = Object.freeze({
  GET_COUNTRY_CODES: '@FIL/OtpPage/GET_COUNTRY_CODES',
  GET_COUNTRY_CODES_SUCCESS: '@FIL/OtpPage/GET_COUNTRY_CODES_SUCCESS',
  GET_COUNTRY_CODES_FAILURE: '@FIL/OtpPage/GET_COUNTRY_CODES_FAILURE',
  GET_OTP_INFO: '@FIL/OtpPage/GET_OTP_INFO',
  GET_OTP_INFO_SUCCESS: '@FIL/OtpPage/GET_OTP_INFO_SUCCESS',
  GET_OTP_INFO_FAILURE: '@FIL/OtpPage/GET_OTP_INFO_FAILURE',
  VERIFY_EMAIL_ADDRESS: '@FIL/OtpPage/VERIFY_EMAIL_ADDRESS',
  VERIFY_EMAIL_ADDRESS_SUCCESS: '@FIL/OtpPage/VERIFY_EMAIL_ADDRESS_SUCCESS',
  VERIFY_EMAIL_ADDRESS_FAILURE: '@FIL/OtpPage/VERIFY_EMAIL_ADDRESS_FAILURE',
  VERIFY_MOBILE_NUMBER: '@FIL/OtpPage/VERIFY_MOBILE_NUMBER',
  VERIFY_MOBILE_NUMBER_SUCCESS: '@FIL/OtpPage/VERIFY_MOBILE_NUMBER_SUCCESS',
  VERIFY_MOBILE_NUMBER_FAILURE: '@FIL/OtpPage/VERIFY_MOBILE_NUMBER_FAILURE',
  VALIDATE_OTP: '@FIL/OtpPage/VALIDATE_OTP',
  VALIDATE_OTP_SUCCESS: '@FIL/OtpPage/VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_FAILURE: '@FIL/OtpPage/VALIDATE_OTP_FAILURE',
  RESEND_OTP: '@FIL/OtpPage/RESEND_OTP',
  RESEND_OTP_SUCCESS: '@FIL/OtpPage/RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: '@FIL/OtpPage/RESEND_OTP_FAILURE',
  FETCH_CHALLENGE_DETAILS: '@FIL/OtpPage/FETCH_CHALLENGE_DETAILS',
  FETCH_CHALLENGE_DETAILS_SUCCESS: '@FIL/OtpPage/FETCH_CHALLENGE_DETAILS_SUCCESS',
  FETCH_CHALLENGE_DETAILS_FAILURE: '@FIL/OtpPage/FETCH_CHALLENGE_DETAILS_FAILURE',
  TOKEN_AUTHENTICATE: '@FIL/OtpPage/TOKEN_AUTHENTICATE',
  TOKEN_AUTHENTICATE_SUCCESS: '@FIL/OtpPage/TOKEN_AUTHENTICATE_SUCCESS',
  TOKEN_AUTHENTICATE_FAILURE: '@FIL/OtpPage/TOKEN_AUTHENTICATE_FAILURE',
  CLOSE_OTP_POPUP: '@FIL/OtpPage/CLOSE_OTP_POPUP',
  OPEN_REMEMBER_DEVICE_POPUP: '@FIL/OtpPage/OPEN_REMEMBER_DEVICE_POPUP',
  COUNTRY_SORTING_ATTR: 'countryName',
  PERSONAL_EMAIL_ADDRESS: 'personalEmailAddress',
  MOBILE_NUMBER: 'mobilePhoneNumber',
  MATCH_PREDICATE: 'countryCode',
  PREDICATE_IDENTITY: 'GB',
  RESTRICT_TYPE_LOW: 'L',
  RESTRICT_TYPE_MEDIUM: 'M',
  RESTRICT_TYPE_HIGH: 'H',
  ORG_NAME: 'FIDELITY-WI-IAM',
  FIlTERED_COUNTRY: 'Unknown',
  SORT_BY: 'ISDNum',
  VERIFY_EMAIL_ANALYTICS: 'login:create otp: verify email',
  VERIFY_MOBILE_ANALYTICS: 'login:create otp: verify mobile',
  REMEMBER_DEVICE_ANALYTICS: 'login:create otp: remember device',
});

export default OTP_SETUP_CONST;
