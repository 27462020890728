/*
 *
 * SetupSecurityQuestions actions
 *
 */
// @flow

import {
  FETCH_SECURITY_QUES,
  FETCH_SECURITY_QUES_SUCCESS,
  FETCH_SECURITY_QUES_FAILURE,
  SUBMIT_SECURITY_QUES,
  SUBMIT_SECURITY_QUES_SUCCESS,
  SUBMIT_SECURITY_QUES_FAILURE,
} from './SetupSecurityQuestions.constants';

export const fetchSecurityQuestion = () => ({ type: FETCH_SECURITY_QUES });
export const fetchSecurityQuestionSuccess = (data: Object) => ({
  type: FETCH_SECURITY_QUES_SUCCESS,
  data,
});
export const fetchSecurityQuestionFailure = (error: Object) => ({
  type: FETCH_SECURITY_QUES_FAILURE,
  error,
});

export const submitSecurityQuestion = (values: Object) => ({ type: SUBMIT_SECURITY_QUES, values });
export const submitSecurityQuestionSuccess = (data: Object) => ({
  type: SUBMIT_SECURITY_QUES_SUCCESS,
  data,
});
export const submitSecurityQuestionFailure = (error: Object) => ({
  type: SUBMIT_SECURITY_QUES_FAILURE,
  error,
});

export const initialActions = [fetchSecurityQuestion];
