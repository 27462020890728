import { updateState } from '@fil-global/eiswagen';

import {
  FETCH_TNC,
  FETCH_TNC_FAILURE,
  FETCH_TNC_SUCCESS,
  SAVE_TNC,
  SAVE_TNC_FAILURE,
  SAVE_TNC_SUCCESS,
  CLEAR_TNC_ERROR,
} from './TermsAndConditions.constants';

export default function TermsAndConditions(state = {}, action) {
  switch (action.type) {
    case FETCH_TNC:
    case SAVE_TNC:
      return updateState(state, { isFetching: true });
    case FETCH_TNC_FAILURE:
    case SAVE_TNC_FAILURE:
      return updateState(state, { isFetching: false, error: true });
    case FETCH_TNC_SUCCESS:
      return updateState(state, { isFetching: false, labels: action.labels });
    case SAVE_TNC_SUCCESS:
      return updateState(state, { isFetching: false });
    case CLEAR_TNC_ERROR:
      return updateState(state, { isError: false });
    default:
      return state;
  }
}
