/**
 *
 * ScrollTop
 *
 */

// @flow

import React from 'react';
import type { Node } from 'react';
import classnames from 'classnames';

import { Button, Icon } from '@fil-global/eiswurfel';

import type { Props } from './types';

export const scrollTop = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0);
    // $FlowFixMe eslint-disable-line
    document.body.focus();
  }
};

const ScrollTop = ({ className }: Props): Node => (
  <Button
    ariaLabel="Scroll Top"
    onClick={scrollTop}
    extraClasses={classnames('scroll-top', className)}>
    <Icon className="white" variant="light" iconType="arrow-u" />
  </Button>
);

ScrollTop.defaultProps = {};

export default ScrollTop;
