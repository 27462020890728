/**
 *
 * PasswordConditions
 *
 */

// @flow

import React, { PureComponent } from 'react';

import memoizeone from 'memoize-one';

import { List, Label, Icon, getLabel } from '@fil-global/eiswurfel';
import classnames from 'classnames';
import type { Props, StrengthProps } from './types';

class PasswordConditions extends PureComponent<Props> {
  measureStrength = memoizeone(({ password, regExps }: StrengthProps) => {
    let score = 0;
    if (!password || password.length === 0) {
      score = 0;
    } else if (regExps) {
      regExps.forEach((regex) => {
        if (regex.test(password)) {
          score += 1;
        }
      });
    }

    return score;
  });

  render() {
    const { password, regExps, minCharacter, label } = this.props;
    const score = this.measureStrength({ password, regExps });

    return (
      <div className="passwordConditionsWrapper">
        <Label htmlFor="password-conditions">{getLabel(label, 'title')}</Label>
        <hr />

        <List isBulleted={false} className="passwordConditions">
          <li>
            <Icon
              variant="light"
              iconType="tick"
              className={classnames({ success: password && password.length >= minCharacter })}
            />
            {getLabel(label, 'charRange')}
          </li>
          <li>
            <Icon variant="light" iconType="tick" className={classnames({ success: score >= 3 })} />
            {getLabel(label, 'helpTextTitle')}
          </li>
          <List isBulleted={false}>
            <li>
              <Icon
                variant="light"
                iconType="tick"
                className={classnames({ success: /[a-z]/.test(password) })}
              />
              {getLabel(label, 'helpTextLevel.LEVEL_1')}
            </li>
            <li>
              <Icon
                variant="light"
                iconType="tick"
                className={classnames({ success: /[0-9]/.test(password) })}
              />
              {getLabel(label, 'helpTextLevel.LEVEL_2')}
            </li>

            <li>
              <Icon
                variant="light"
                iconType="tick"
                className={classnames({ success: /[A-Z]/.test(password) })}
              />
              {getLabel(label, 'helpTextLevel.LEVEL_3')}
            </li>
            <li>
              <Icon
                variant="light"
                iconType="tick"
                className={classnames({
                  success:
                    /[!£$*=[\]{}@#?]/.test(password) && !/[^A-Za-z0-9!£$*=[\]{}@#?]/.test(password),
                })}
              />
              {getLabel(label, 'helpTextLevel.LEVEL_4')}
            </li>
          </List>
        </List>
      </div>
    );
  }
}

export default PasswordConditions;
