// @flow

export const RETRIEVE_USER_NAME: '@FIL/PVLOGIN/RETRIEVE_USER_NAME' =
  '@FIL/PVLOGIN/RETRIEVE_USER_NAME';

export const RETRIEVE_USER_NAME_FAILURE: '@FIL/PVLOGIN/RETRIEVE_USER_NAME_FAILURE' =
  '@FIL/PVLOGIN/RETRIEVE_USER_NAME_FAILURE';

export const RETRIEVE_USER_NAME_SUCCESS: '@FIL/PVLOGIN/RETRIEVE_USER_NAME_SUCCESS' =
  '@FIL/PVLOGIN/RETRIEVE_USER_NAME_SUCCESS';
export const SET_PI_DETAILS: '@FIL/PVLOGIN/RETRIEVE_SET_PI_DETAILS' =
  '@FIL/PVLOGIN/RETRIEVE_SET_PI_DETAILS';

export const MINYEAR = 120;
export const MAXYEAR = 16;
export const NINO_REGEX = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D\s]?$/;
export const STAFF_NUM_REGEX = /^[a-zA-Z0-9()&$&#163;@.’”#_\-~,+:` ]*$/;
export const FOR_NAME_REGEX = /^[A-Za-z0-9-'\s*.]+$/;
export const SIR_NAME_REGEX = /^[A-Za-z0-9-'\s*.]+$/;
export const MEMBER_CODE_REGEX = /^[a-z0-9]+$/i;
export const DATE_REGEX = /^(0?[1-9]|[12]\d|3[01])-(0?[1-9]|1[0-2])-([12]\d{3})$/;

export const ANALYTICS_UNABLE_TO_FIND = 'personal details: details not found';
export const ANALYTICS_UNABLE_TO_RETRIEVE = 'personal details: unable to retrieve';
export const ANALYTICS_VERIFICATION_REQUIRED = 'personal details: verification required';
export const ANALYTICS_CONFIRMATION = 'confirmation';
export const ANALYTICS_OTP = 'otp';
