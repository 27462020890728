import React from 'react';
import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';
import reducer from './LoginPage.reducer';
import saga from './LoginPage.saga';
import { closeCurrentElements } from '../../../global/actions';
import { redirectToLoginPage } from './LoginPage.actions';
import LoginPageComponent from '../../../components/templates/LoginPage';

export const LoginPage = (props) => <LoginPageComponent {...props} />;

export const mapStateToProps = (state) => ({
  errorLabels: get(state, 'global.globalData.labels.serviceErrors', ''),
  activePage: get(state, 'global.loginData.activePage', ''),
  currentJourney: get(state, 'global.globalData.currentJourney'),
  labels: get(state, 'global.globalData.labels', {}),
  activePopup: get(state, 'global.loginData.activePopup'),
});

export const mapDispatchToProps = {
  closeCurrentElement: closeCurrentElements,
  redirectToLoginPage,
};

export default clientEnhance(LoginPage, {
  key: 'LoginPage',
  reducer,
  saga,
  mapStateToProps,
  mapDispatchToProps,
});
