// @flow
import isEmpty from 'lodash/isEmpty';
import { getDeviceType, getCookie } from '@fil-global/eiswagen';

import type { localeType } from './types';

import {
  SET_LOCALE,
  FETCH_LABEL,
  PAGE_TRANSITION,
  DEVICE_RESIZE_ACTION,
  JOURNEY,
  LOGIN_JOURNEY,
  ACTIVE_PAGE,
  ACTIVE_POPUP,
  LOGIN_PAGE,
  SERVICE_ERROR,
  CLOSE_ELEMENT,
  SET_TOKEN,
  USER_CREDS,
  DELETE_USER_CREDS,
  ARCOT_ID,
  PERSONAL_DETAILS_PAGE_ANALYTICS,
  LOG_OUT,
  SET_SESSION_TIMER_ID,
  TRIGGER_ANALYTICS_CUSTOM_EVENT,
  USER_STATUS,
  TRIGGER_ANALYTICS_PAGE_VIEW_EVENT,
} from './constants';
import { LOCALE_COOKIE, DEFAULT_LOCALE } from '../constants';

export const fetchLabelsAction = () => ({
  type: FETCH_LABEL,
  data: { pageName: PERSONAL_DETAILS_PAGE_ANALYTICS },
});

export const pageTransitionAction = ({ match }) => ({
  type: PAGE_TRANSITION,
  page: match ? match.path : '',
});

export const onDeviceResize = ({ deviceType }) => ({
  type: DEVICE_RESIZE_ACTION,
  data: deviceType || getDeviceType(),
});

export const setSelectedLocaleAction = () => ({
  type: SET_LOCALE,
  data: isEmpty(getCookie(LOCALE_COOKIE)) ? DEFAULT_LOCALE : getCookie(LOCALE_COOKIE),
});

export const updateSelectedLocaleAction = (locale: localeType) => ({
  type: SET_LOCALE,
  data: locale,
});

export const setCurrentJourney = (journeyType, pageName, siteSection, channelAcronym) => ({
  type: JOURNEY,
  currentJourney: journeyType || LOGIN_JOURNEY,
  data: {
    pageName,
    ...(siteSection && { siteSection }),
    ...(channelAcronym && { channelAcronym }),
  },
});

export const setActivePage = (activePage, pageName, siteSection, channelAcronym) => ({
  type: ACTIVE_PAGE,
  activePage: activePage || LOGIN_PAGE, // TODO : fix this one is object other is string
  data: {
    pageName,
    ...(siteSection && { siteSection }),
    ...(channelAcronym && { channelAcronym }),
  },
});

export const setActivePopup = (activePopup, pageName, siteSection, channelAcronym) => ({
  type: ACTIVE_POPUP,
  activePopup: activePopup || '',
  data: {
    pageName,
    ...(siteSection && { siteSection }),
    ...(channelAcronym && { channelAcronym }),
  },
});

export const showServiceError = (serviceError: string): { type: string, serviceError: string } => ({
  type: SERVICE_ERROR,
  serviceError: serviceError || '',
});
export const closeCurrentElements = () => ({
  type: CLOSE_ELEMENT,
});

export const setToken = (authenticatedToken) => ({
  type: SET_TOKEN,
  token: authenticatedToken,
});

export const setUserCreds = (userCreds) => ({
  type: USER_CREDS,
  userdata: userCreds,
});

export const setArcotId = (arcotData) => ({
  type: ARCOT_ID,
  arcotId: arcotData,
});

export const resetUserCreds = () => ({
  type: DELETE_USER_CREDS,
  userdata: '',
  arcotId: '',
});

export const setSessionTimerId = (id) => ({
  type: SET_SESSION_TIMER_ID,
  sessionTimerId: id,
});

export const LogOut = () => ({
  type: LOG_OUT,
});

export const triggerAnalyticsCustomEvent = (data: Object) => ({
  type: TRIGGER_ANALYTICS_CUSTOM_EVENT,
  data,
});
export const triggerAnalyticsPageView = (data: Object) => ({
  type: TRIGGER_ANALYTICS_PAGE_VIEW_EVENT,
  data,
});

export const setUserStatus = (status) => ({
  type: USER_STATUS,
  userStatus: status || '',
});

/**
 * !Important: setSelectedLocaleAction should be executed before fetchLabelsAction.
 */
const globalActions = [setSelectedLocaleAction, fetchLabelsAction, onDeviceResize, LogOut];
const globalPageTransitionActions = [pageTransitionAction];

export { globalPageTransitionActions };
export default globalActions;
