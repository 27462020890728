/*
 *
 * AccountSummary reducer
 *
 */

// @flow
import { combineReducers } from 'redux';
import { updateState } from '@fil-global/eiswagen';

import {
  VALIDATE_LOGGED_USER,
  VALIDATE_LOGGED_USER_FAILURE,
  MIGRATED_USER_OPTION,
  FETCH_CHALLENGE_DETAILS,
  FETCH_CHALLENGE_DETAILS_SUCCESS,
  FETCH_CHALLENGE_DETAILS_FAILURE,
  ARCOT_ID_DETAILS,
  ARCOT_ID_DETAILS_SUCCESS,
  ARCOT_ID_DETAILS_FAILURE,
  TOKEN_AUTHENTICATE,
  TOKEN_AUTHENTICATE_SUCCESS,
  TOKEN_AUTHENTICATE_FAILURE,
  INVALID_USER,
  SET_USER_SECURITY_QUES,
  VERIFY_REQUIRED,
  VERIFY_REQUIRED_SUCCESS,
  VERIFY_REQUIRED_FAILURE,
  KEEP_USER_NAME,
  KEEP_USER_NAME_SUCCESS,
  KEEP_USER_NAME_FAILURE,
  EXPIRY_CHECK,
  EXPIRY_CHECK_SUCCESS,
  EXPIRY_CHECK_FAILURE,
  SET_USER_TYPE,
  REDIRECT_LOGOUT,
  REDIRECT_LOGOUT_SUCCESS,
  REDIRECT_LOGOUT_FAILURE,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_FAILURE,
  GET_MEMBER_INFO_SUCCESS,
  SET_DEVICE_REG,
  UNSUPPORTED_BROWSER,
  LOADER,
} from './LoginPage.constants';

import username from '../../organisms/RetrieveUserName/RetrieveUserName.reducer';

export function challenge(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case FETCH_CHALLENGE_DETAILS:
      return updateState(state, { isFetching: true });
    case FETCH_CHALLENGE_DETAILS_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case FETCH_CHALLENGE_DETAILS_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}
export function arcotId(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case ARCOT_ID_DETAILS:
      return updateState(state, { isFetching: true });
    case ARCOT_ID_DETAILS_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case ARCOT_ID_DETAILS_FAILURE:
      return updateState(state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}
export function authenticateToken(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case TOKEN_AUTHENTICATE:
      return updateState(state, { isFetching: true });
    case TOKEN_AUTHENTICATE_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case TOKEN_AUTHENTICATE_FAILURE:
      return updateState(state, {
        isFetching: false,
        error: action.error,
      });
    default:
      return state;
  }
}

export function InvalidUserError(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case INVALID_USER:
      return updateState(state, { isFetching: false, invalidUserError: action.error });
    default:
      return state;
  }
}

export function validateLoggedUser(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case VALIDATE_LOGGED_USER:
      return updateState(state, { isFetching: true });
    case VALIDATE_LOGGED_USER_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    case MIGRATED_USER_OPTION:
      return updateState(state, { isFetching: false });
    default:
      return state;
  }
}

export function setSecurityQues(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case SET_USER_SECURITY_QUES:
      return updateState(state, { securityQuestion: action.securityQuestion });
    default:
      return state;
  }
}

export function verifyRequired(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case VERIFY_REQUIRED:
      return updateState(state, { isFetching: true });
    case VERIFY_REQUIRED_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data, error: {} });
    case VERIFY_REQUIRED_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function keepUsername(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case KEEP_USER_NAME:
      return updateState(state, { isFetching: true });
    case KEEP_USER_NAME_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case KEEP_USER_NAME_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function expireCheck(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case EXPIRY_CHECK:
      return updateState(state, { isFetching: true });
    case EXPIRY_CHECK_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case EXPIRY_CHECK_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function redirectLogout(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case REDIRECT_LOGOUT:
      return updateState(state, { isFetching: true });
    case REDIRECT_LOGOUT_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case REDIRECT_LOGOUT_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function user(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case SET_USER_TYPE:
      return updateState(state, { type: action.user });
    default:
      return state;
  }
}

export function memberInfo(state?: { [string]: string } = {}, action: ReducerActionType) {
  switch (action.type) {
    case GET_MEMBER_INFO:
      return updateState(state, { isFetching: true });
    case GET_MEMBER_INFO_SUCCESS:
      return updateState(state, { isFetching: false, migrationStatus: action.data });
    case GET_MEMBER_INFO_FAILURE:
      return updateState(state, { isFetching: false });
    default:
      return state;
  }
}

export function isDeviceReg(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case SET_DEVICE_REG:
      return updateState(state, { isReg: action.isDeviceReg });
    default:
      return state;
  }
}

export function unSupportedBrowser(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case UNSUPPORTED_BROWSER:
      return updateState(state, { isOpened: action.isOpened });
    default:
      return state;
  }
}

export function loader(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case LOADER:
      return updateState(state, { isFetching: action.active, isHideView: action.hide });
    default:
      return state;
  }
}

// $FlowFixMe eslint-disable-line
const userReducer = combineReducers({
  challenge,
  arcotId,
  authenticateToken,
  InvalidUserError,
  validateLoggedUser,
  username,
  setSecurityQues,
  verifyRequired,
  keepUsername,
  user,
  expireCheck,
  redirectLogout,
  memberInfo,
  isDeviceReg,
  unSupportedBrowser,
  loader,
});

export default userReducer;
