import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';
import {
  validateLoggedUser,
  changeUserName,
  keepUserName,
  expireCheck,
  unSupportedBrowser,
  showInvalidUserError,
} from '../../templates/LoginPage/LoginPage.actions';
import {
  selectLabels,
  selectLoginFormLabels,
  selectFetching,
  selectActivePopup,
} from './LoginForm.selectors';

import {
  closeCurrentElements,
  setActivePage,
  setCurrentJourney,
  setActivePopup,
  setUserStatus,
} from '../../../global/actions';
import LoginFormComponent from '../../../components/organisms/LoginForm';

const pingToggle = process.env.REACT_APP_PING_TOGGLE;

export const mapStateToProps = (state) => ({
  isFetching: selectFetching(state),
  serviceError: get(state, 'global.globalData.serviceError', ''),
  errorLabels: get(state, 'global.globalData.labels.serviceErrors', ''),
  loginFormLabels: selectLoginFormLabels(state),
  labels: selectLabels(state),
  activePopup: selectActivePopup(state),
  InvalidUserError: get(state, 'LoginPage.InvalidUserError.invalidUserError.code', ''),
  scheduledMaintenanceLabels: get(state, 'global.globalData.labels.scheduledMaintenance', {}),
  window7NotificationLabels: get(state, 'global.globalData.labels.window7Notification', ''),
  username: get(state, 'LoginPage.username.username'),
  isNonMigrated: get(state, 'LoginPage.username.isNonMigrated'),
  isUnSupportedBrowserOpened: get(state, 'LoginPage.unSupportedBrowser.isOpened'),
  isHideView: get(state, 'LoginPage.loader.isHideView'),
  pingToggle,
});
export const mapDispatchToProps = {
  setCurrentJourney,
  setForgotUserPage: setActivePage,
  getLoggedUser: validateLoggedUser,
  closeCurrentElement: closeCurrentElements,
  changeButton: setActivePage,
  changePage: setActivePage,
  changeUserName,
  keepUserName,
  expireCheck,
  setActivePopup,
  setUserStatus,
  unSupportedBrowser,
  showInvalidUserError,
};

export default clientEnhance(LoginFormComponent, {
  key: 'loginForm',
  mapStateToProps,
  mapDispatchToProps,
});
