import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);
export const selectUserType = (state) => get(state, ['LoginPage', 'user', 'type']);

export const selectResetPassworCopletedLabels = createSelector(
  selectLabels,
  selectUserType,
  (labels, userType) => ({
    ...(userType === 'admin'
      ? get(labels, ['changePasswordAdminLabels', 'changePasswordCompleteLabels'])
      : get(labels, ['resetPasswordLabels', 'resetPasswordCompleteLabels'])),
  })
);
