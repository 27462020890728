import 'custom-event-polyfill';
import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';

import {
  initialiseClientGlobalSagas,
  initialiseGlobalReducer,
  setApplicationBootStrapActions,
  setGlobalPageTransitionActions,
} from '@fil-global/eiswagen';

import initializeTrackerConfig from './global/AnalyticsTracking';

import App from './App';

import applicationBootStrapActions, { globalPageTransitionActions } from './global/actions';
import globalReducers from './global/reducers';
import globalSagas from './global/sagas';

initialiseGlobalReducer(globalReducers); // Reducer which has to be initalised at the bootstrap level;
initialiseClientGlobalSagas(globalSagas); // All sagas that will be attached to store at bootstrap level
setApplicationBootStrapActions(applicationBootStrapActions); // All actions which needs to be triggered only once at application bootstrap
setGlobalPageTransitionActions(globalPageTransitionActions); // All actions which needs to be triggered at every page transition automatically
initializeTrackerConfig(); // Initialize tracker with pre-defined config

ReactDOM.render(
  <Router>
    <App isSecureEnabled />
  </Router>,
  document.getElementById('root')
);
