import LoginForm from '../../../containers/organisms/LoginForm';
import OtpVerification from '../../../containers/organisms/OtpVerification';
import ChangeUsernamePassword from '../../../containers/organisms/ChangeUsernamePassword';
import LoginComplete from '../../../containers/organisms/LoginComplete';
import SetupSecurityQuestions from '../../../containers/organisms/SetupSecurityQuestions';
import OtpSetUp from '../../../containers/organisms/OtpSetUp';
import TermsAndConditions from '../../../containers/organisms/TermsAndConditions';
import ForgotLoginDetails from '../../../containers/organisms/ForgotLoginDetails';
import ShowUserName from '../../../containers/organisms/ShowUserName';
import RetrieveUserName from '../../../containers/organisms/RetrieveUserName';
import AnswerSecurityQuestions from '../../../containers/organisms/AnswerSecurityQuestions';
import AnswerSecurityQuestionsv2 from '../../../containers/organisms/AnswerSecurityQuestionsV2';
import ResetPassword from '../../../containers/organisms/ResetPassword';
import ResetPasswordComplete from '../../../containers/organisms/ResetPasswordComplete';

import {
  LOGIN_PAGE,
  OTP_VERIFY,
  CHANGE_USER,
  LOGIN_COMPLETE,
  SETUP_SECURITY_QUESTIONS,
  SETUP_OTP,
  TERMS_AND_CONDITIONS_PAGE,
  SHOW_USERNAME_PAGE,
  RETRIEVE_USERNAME_PAGE,
  FORGOT_LOGIN_DETAILS,
  OTP_SECURITY_QA,
  OTP_SECURITY_QA_V2,
  RESET_PWD_PAGE,
  RESET_COMPLETE_PAGE,
} from '../../../global/constants';

const renderComponent = {
  [LOGIN_PAGE]: LoginForm,
  [OTP_VERIFY]: OtpVerification,
  [CHANGE_USER]: ChangeUsernamePassword,
  [LOGIN_COMPLETE]: LoginComplete,
  [SETUP_SECURITY_QUESTIONS]: SetupSecurityQuestions,
  [SETUP_OTP]: OtpSetUp,
  [TERMS_AND_CONDITIONS_PAGE]: TermsAndConditions,
  [FORGOT_LOGIN_DETAILS]: ForgotLoginDetails,
  [SHOW_USERNAME_PAGE]: ShowUserName,
  [RETRIEVE_USERNAME_PAGE]: RetrieveUserName,
  [OTP_SECURITY_QA]: AnswerSecurityQuestions,
  [OTP_SECURITY_QA_V2]: AnswerSecurityQuestionsv2,
  [RESET_PWD_PAGE]: ResetPassword,
  [RESET_COMPLETE_PAGE]: ResetPasswordComplete,
};

export default renderComponent;
