/**
 *
 * SetUserNamePassword
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import {
  Button,
  TextField,
  Form,
  withField,
  PasswordStrengthMeter,
  Spinner,
  Notification,
  GridContainer,
  Grid,
  Cell,
  getLabel,
  UnsafeHtml,
  Label,
} from '@fil-global/eiswurfel';
import get from 'lodash/get';
import memoize from 'memoize-one';

import type { State, Props, UsernamePasswordFormData, ErrorsType } from './types';
import {
  USER_NAME_REGEX,
  PASSWORD_REGEX,
  USER_NAME_MIN_LEN,
  GOOD_ENOUGH,
  PASSWORD_STRENGTH_REGEX,
} from './ChangeUsernamePassword.constants';
import PasswordConditions from '../../molecules/PasswordConditions';

const TextFieldItem = withField(TextField);

class ChangeUsernamePassword extends PureComponent<Props, State> {
  initialValues = {
    username: '',
    password: '',
    renterPassword: '',
    currentPassword: '',
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = { isPwdStrong: false };
  }

  setPwdStrength = (score: number) => {
    const isStrong = score >= GOOD_ENOUGH;
    const { isPwdStrong } = this.state;
    if (isPwdStrong !== isStrong) {
      this.setState({ isPwdStrong: isStrong });
    }
  };

  getValidationSchema = memoize((labels) => ({
    username: {
      required: getLabel(labels, 'validationErrors.username.required', 'username'),
      matches: [USER_NAME_REGEX, getLabel(labels, 'validationErrors.username.invalid')],
      min: [USER_NAME_MIN_LEN, getLabel(labels, 'validationErrors.username.short')],
    },

    currentPassword: {
      required: getLabel(labels, 'validationErrors.currentPassword.required'),
      matches: [PASSWORD_REGEX, getLabel(labels, 'validationErrors.currentPassword.invalid')],
    },

    password: {
      required: getLabel(labels, 'validationErrors.password.required'),
      matches: [PASSWORD_REGEX, getLabel(labels, 'validationErrors.password.invalid')],
    },

    renterPassword: {
      required: getLabel(labels, 'validationErrors.reenterpassword.required'),
      matches: [PASSWORD_REGEX, getLabel(labels, 'validationErrors.reenterpassword.invalid')],
    },
  }));

  validate = (values: UsernamePasswordFormData): ErrorsType => {
    const errors = {};
    const { labels } = this.props;
    if (values.password && values.renterPassword && values.password !== values.renterPassword) {
      errors.renterPassword = getLabel(labels, 'validationErrors.reenterpassword.match');
    }
    if (values.password && values.currentPassword && values.password === values.currentPassword) {
      errors.password = getLabel(labels, 'validationErrors.currentPassword.match');
    }

    return errors;
  };

  render(): Node {
    const {
      labels,
      isFetching,
      serviceError,
      clearError,
      changeUsernamePwd,
      hasError,
      userType,
    } = this.props;
    const { isPwdStrong } = this.state;
    const passwordConditionsLevel = getLabel(labels, 'passwordConditionsLevel');

    let isAdmin = false;
    if (userType === 'admin') {
      isAdmin = true;
    }
    if (!labels) {
      return null;
    }
    return (
      <GridContainer variation=" ">
        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container change-username-pwd-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {hasError && (
                    <Cell>
                      <Notification {...serviceError} onClose={clearError} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <Grid extraClasses="align-center">
                      <Cell extraClasses="large-10">
                        <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell>
                    <UnsafeHtml wrapper="p">{getLabel(labels, 'content')}</UnsafeHtml>
                  </Cell>
                  <Cell>
                    <Form
                      initialValues={this.initialValues}
                      handleSubmit={changeUsernamePwd}
                      validate={this.validate}
                      validationSchema={this.getValidationSchema(labels)}
                      formRenderFunc={(props) => {
                        const { values } = props;
                        const { password } = values;
                        const passwordStrengthMeterLabel = get(labels, 'passwordStrength');
                        return (
                          <>
                            <TextFieldItem
                              name="username"
                              className="new-username-input"
                              label={labels.newUsername.title}
                              maxLength={isAdmin ? '32' : '45'}
                              id="new-username-input"
                            />
                            {!get(props, 'errors.newUsername') && (
                              <p className="help-text">
                                {get(labels, 'newUsername.helpText', 'helpText')}
                              </p>
                            )}

                            <TextFieldItem
                              name="currentPassword"
                              label={labels.currentPassword}
                              type="password"
                              id="current-password-input"
                              maxLength="25"
                            />

                            <Grid>
                              <Cell extraClasses="small-4">
                                <Label htmlFor="password-input">
                                  {getLabel(labels, 'newPassword.title')}
                                </Label>
                              </Cell>
                              <Cell extraClasses="small-8">
                                <PasswordStrengthMeter
                                  password={password}
                                  onChange={this.setPwdStrength}
                                  label={passwordStrengthMeterLabel}
                                  regExps={PASSWORD_STRENGTH_REGEX}
                                />
                              </Cell>
                            </Grid>

                            <TextFieldItem
                              name="password"
                              className="password-input"
                              type="password"
                              id="password-input"
                              maxLength="25"
                            />

                            <div className="helpTextPrevPassword">
                              <span className="icon fil-icon fil-icon-info" />
                              <p>{getLabel(labels, 'newPassword.helpTextPrevPassword')}</p>
                            </div>
                            <PasswordConditions
                              password={password}
                              label={passwordConditionsLevel}
                              regExps={PASSWORD_STRENGTH_REGEX}
                              minCharacter="8"
                            />

                            <TextFieldItem
                              name="renterPassword"
                              label={labels.rePassword.title}
                              type="password"
                              id="reenter-password-input"
                              maxLength="25"
                            />

                            <Grid extraClasses="align-center">
                              <Cell extraClasses="text-center medium-6 large-4">
                                <Button
                                  type="submit"
                                  extraClasses="primary-cta expanded"
                                  disabled={isPwdStrong ? '' : 'disabled'}>
                                  {get(labels, 'Confirm', 'Confirm')}
                                </Button>
                              </Cell>
                            </Grid>
                          </>
                        );
                      }}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default ChangeUsernamePassword;
