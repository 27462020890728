// @flow

import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';

import {
  getCountryCodesFailure,
  getCountryCodesSuccess,
  verifyEmailAddressSuccess,
  verifyEmailAddressFailure,
  validateOtpSuccess,
  validateOtpFailure,
  verifyMobileNumberSuccess,
  verifyMobileNumberFailure,
  reSendOtpActionSuccess,
  reSendOtpActionFailure,
} from './OtpSetUp.actions';
import { setArcotId, setActivePopup } from '../../../global/actions';

import { axiosServiceClient } from '../../../config/axios-client';
import OTP_SETUP_CONST from './OtpSetUp.constants';
import apiEndPoints from '../../../constants/api/services';
import {
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_POPUP,
  TOKEN_EXPIRED_ANALYTICS,
} from '../../templates/LoginPage/LoginPage.constants';

import type { VerifyActionReturnType, ValidateOtpType, ResendType } from './types';

export function* getCountryCodes(): Saga<void> {
  try {
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.fetchCountryCodes, {
      RestrictedType: [
        { RestrictedTypeValue: OTP_SETUP_CONST.RESTRICT_TYPE_LOW },
        { RestrictedTypeValue: OTP_SETUP_CONST.RESTRICT_TYPE_MEDIUM },
        { RestrictedTypeValue: OTP_SETUP_CONST.RESTRICT_TYPE_HIGH },
      ],
      sortBy: OTP_SETUP_CONST.SORT_BY,
    });
    yield put(getCountryCodesSuccess(data));
  } catch (error) {
    yield put(getCountryCodesFailure());
  }
}

export function* reSendOtP(action: ResendType): Saga<void> {
  const { payload } = action;
  try {
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.resend, payload);
    yield put(reSendOtpActionSuccess(data));
  } catch (e) {
    const error = get(e, 'response.data');
    const code = get(e, 'response.data.code');
    if (code === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    }
    yield put(reSendOtpActionFailure(error));
  }
}

export function* sendOtp(action: VerifyActionReturnType): Saga<void> {
  try {
    const { data } = yield call(
      axiosServiceClient.post,
      apiEndPoints.sendOtp,
      action.verifyActionPayLoad
    );
    if (action.type !== OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER) {
      data.pageName = OTP_SETUP_CONST.VERIFY_EMAIL_ANALYTICS;
      yield put(verifyEmailAddressSuccess(data));
    } else {
      data.pageName = OTP_SETUP_CONST.VERIFY_MOBILE_ANALYTICS;
      yield put(verifyMobileNumberSuccess(data));
    }
  } catch (error) {
    const code = get(error, 'response.data.code');
    if (code === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    } else if (action.type !== OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER) {
      yield put(verifyEmailAddressFailure(code));
    } else {
      yield put(verifyMobileNumberFailure(code));
    }
  }
}

export function* validateOtp(action: ValidateOtpType): Saga<void> {
  const state = yield select();
  const isEmailVerified = get(state, 'otpSetUp.verifyInputFieldReducer.isEmailVerified');
  const isMobileNumberVerified = get(
    state,
    'otpSetUp.verifyInputFieldReducer.isMobileNumberVerified'
  );

  const isVerified = {
    isMobileNumberVerified,
    isEmailVerified,
  };
  if (action.channel === OTP_SETUP_CONST.MOBILE_NUMBER) {
    isVerified.isMobileNumberVerified = true;
  } else {
    isVerified.isEmailVerified = true;
  }

  try {
    const { data } = yield call(
      axiosServiceClient.post,
      apiEndPoints.validateOtp,
      action.validateOtpActionPayLoad
    );
    yield put(validateOtpSuccess(data, isVerified));
    /* istanbul ignore next */
    yield put(setArcotId(data.arcotId));
  } catch (e) {
    const error = get(e, 'response.data');
    const code = get(e, 'response.data.code');
    yield put(validateOtpFailure(error));
    if (code === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    }
  }
}

export default function* otpSetUpSaga(): Saga<void> {
  yield all([
    takeLatest(OTP_SETUP_CONST.GET_COUNTRY_CODES, getCountryCodes),
    takeLatest(OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS, sendOtp),
    takeLatest(OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER, sendOtp),
    takeLatest(OTP_SETUP_CONST.VALIDATE_OTP, validateOtp),
    takeLatest(OTP_SETUP_CONST.RESEND_OTP, reSendOtP),
  ]);
}
