/**
 *
 * Footer
 *
 */

// @flow

import React from 'react';

import type { Props } from './types';

import FooterCopyright from '../../molecules/FooterCopyright';

const Footer = ({ labels, ...otherProps }: Props) => {
  const { base } = labels || {};

  return (
    <footer id="site-footer" className="footer">
      <FooterCopyright data={base} {...otherProps} />
    </footer>
  );
};

export default Footer;
