export const RESEND_LIMIT_REACH = 'WI0150006';
export default RESEND_LIMIT_REACH;
export const DOB_TEXTFORMAT = {
  mask: [/[0-9]/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  guide: false,
  keepCharPositions: false,
};
export const NINO_TEXTFORMAT = {
  mask: [
    /^[a-zA-Z]+$/,
    /^[a-zA-Z]+$/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /^[a-zA-Z]+$/,
  ],
  guide: false,
  keepCharPositions: false,
};
