/** * * AnswerSecurityQuestions * */

// @flow
import type { Dispatch } from 'redux';

import { clientEnhance } from '@fil-global/eiswagen';

import reducer from './AnswerSecurityQuestionsV2.reducer';
import saga from './AnswerSecurityQuestionsV2.saga';

import {
  selectAnswerSecurityQuestionsLabels,
  selectActivePopup,
  selectServiceErrors,
  selectIsFetching,
  selectHasServiceError,
  selectSecurityQuestion,
  selectErrorCode,
} from './AnswerSecurityQuestionsV2.selectors';
import answerSecurityQuestions, { initialActions } from './AnswerSecurityQuestionsV2.actions';
import { closeCurrentElements, setActivePage } from '../../../global/actions';
import AnswerSecurityQuestions from '../../../components/organisms/AnswerSecurityQuestionsV2';
import type { State, Labels, DispatchProps } from './types';

const pingToggle = process.env.REACT_APP_PING_TOGGLE;

export const mapStateToProps = (state: State): { labels: Labels } => ({
  securityQuestions: selectSecurityQuestion(state) || [],
  labels: selectAnswerSecurityQuestionsLabels(state),
  activePopup: selectActivePopup(state),
  serviceError: selectServiceErrors(state),
  isFetching: selectIsFetching(state),
  hasError: selectHasServiceError(state),
  errorCode: selectErrorCode(state),
  pingToggle,
});

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  answerSecurityQuestions: (payload) => dispatch(answerSecurityQuestions(payload)),
  closePopup: (newActivePage: string) => {
    dispatch(setActivePage({ isPageChanged: true, newActivePage }));
    dispatch(closeCurrentElements());
  },
});

export default clientEnhance(AnswerSecurityQuestions, {
  key: 'answerSecurityQuestionsV2',
  saga,
  reducer,
  initialActions,
  mapStateToProps,
  mapDispatchToProps,
});
