// @flow
const emailMask = (email) => {
  let maskedEmail = '';
  if (email) {
    const split = email.split('@');
    const emailAddress = `${
      split[0].substr(0, 1) +
      new Array(split[0].length - 2).fill('x').join('') +
      split[0].charAt(split[0].length - 1)
    }`;

    const splitdomain = split[1].split('.');
    const domain = `${
      splitdomain[0].substr(0, 1) +
      new Array(splitdomain[0].length - 2).fill('x').join('') +
      splitdomain[0].charAt(splitdomain[0].length - 1)
    }.${split[1].split('.').slice(1).join('.')}`;
    maskedEmail = `${emailAddress}@${domain}`;
  }
  return maskedEmail;
};
export default emailMask;
