/** * * ShowUserName * */

// @flow

import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import ShowUserName from '../../../components/organisms/ShowUserName';
import { setPIDetails } from '../RetrieveUserName/RetrieveUserName.actions';
import { selectLabels, selectUserName, selectUserStatus } from './ShowUserName.selectors';
import {
  setActivePage,
  setCurrentJourney,
  setActivePopup,
  setToken,
} from '../../../global/actions';
import {
  FORGOT_LOGIN_DETAILS,
  LOGIN_PAGE,
  RESET_PASSWORD_JOURNEY,
  RESET_PWD_PAGE,
  STATUS_M,
} from '../../../global/constants';
import { showOtpNotification } from '../OtpVerification/OtpVerification.actions';
import type { State, Props, DispatchProps } from './types';

export const mapStateToProps = (state: State): Props => ({
  labels: selectLabels(state),
  username: selectUserName(state),
  userStatus: selectUserStatus(state),
});

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => {
  const resetPasswordPage = {
    isPageChanged: true,
    newActivePage: RESET_PWD_PAGE,
  };
  const forgotLoginDetailsdPage = {
    isPageChanged: true,
    newActivePage: FORGOT_LOGIN_DETAILS,
  };

  const LoginPage = {
    isPageChanged: true,
    newActivePage: LOGIN_PAGE,
  };
  const activePopup = {};
  return {
    resetPassword: (userStatus) => {
      dispatch(setPIDetails(''));
      dispatch(setCurrentJourney(RESET_PASSWORD_JOURNEY));
      dispatch(showOtpNotification(''));
      if (userStatus === STATUS_M) {
        dispatch(setActivePage(resetPasswordPage));
      } else {
        dispatch(setActivePage(forgotLoginDetailsdPage));
      }
    },
    logIn: () => {
      dispatch(setToken(''));
      dispatch(setCurrentJourney());
      dispatch(setActivePage(LoginPage));
      dispatch(setActivePopup(activePopup));
      dispatch(setPIDetails(''));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowUserName);
