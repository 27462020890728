export const SET_LOCALE = '@FIL/APP/SET_LOCALE';
export const FETCH_LABEL = '@FIL/APP/FETCH_LABEL';
export const FETCH_LABEL_SUCCESS = '@FIL/APP/FETCH_LABEL_SUCCESS';
export const FETCH_LABEL_FAILURE = '@FIL/APP/FETCH_LABEL_FAILURE';
export const PAGE_TRANSITION = '@FIL/APP/PAGE_TRANSITION';
export const DEVICE_CONFIGURATIONS = '@FIL/APP/DEVICE_CONFIGURATIONS';
export const DEVICE_RESIZE_ACTION = '@FIL/APP/DEVICE_RESIZE';
export const ACTIVE_PAGE = '@FIL/APP/ACTIVE_PAGE';
export const JOURNEY = '@FIL/APP/JOURNEY';
export const ACTIVE_POPUP = '@FIL/APP/ACTIVE_POPUP';
export const SET_TOKEN = '@FIL/APP/TOKEN';
export const USER_CREDS = '@FIL/APP/USER_CREDS';
export const DELETE_USER_CREDS = '@FIL/APP/DELETE_USER_CREDS';
export const ARCOT_ID = '@FIL/APP/ARCOT_ID';
export const SET_SESSION_TIMER_ID = '@FIL/APP/SET_SESSION_TIMER_ID';
export const PERSONAL_DETAILS_PAGE_ANALYTICS = 'login';
export const LOG_OUT = '@FIL/APP/LOG_OUT';
export const USER_STATUS = '@FIL/APP/USER_STATUS';
export const LOGIN_JOURNEY = 'LOGIN';
export const RESET_PASSWORD_JOURNEY = 'RESET_PASSWORD';
export const RETRIEVE_USERNAME_JOURNEY = 'RETRIEVE_USERNAME';
export const LOGIN_PAGE = 'LOGIN_PAGE';
export const OTP_VERIFY = 'OTP_VERIFY';
export const SETUP_OTP = 'SETUP_OTP';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const CLOSE_ELEMENT = 'CLOSE_ELEMENT';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const CHANGE_USER = 'CHANGE_USER';
export const SETUP_SECURITY_QUESTIONS = 'SETUP_SECURITY_QUESTIONS';
export const ACCOUNT_DISABLED = 'accountDisabled';
export const ACCOUNT_LOCKED = 'accountLocked';
export const ACCOUNT_WEAK_PASSWORD = 'accountWeakPassword';
export const MIGRATED_USER = 'migratedUser';
export const REMEMBER_DEVICE = 'REMEMBER_DEVICE';
export const FORGOT_LOGIN_DETAILS = 'FORGOT_LOGIN_DETAILS';
export const TERMS_AND_CONDITIONS_PAGE = 'TERMS_AND_CONDITIONS_PAGE';
export const SHOW_USERNAME_PAGE = 'SHOW_USERNAME_PAGE';
export const RETRIEVE_USERNAME_PAGE = 'RETRIEVE_USERNAME_PAGE';
export const OTP_SECURITY_QA = 'OTP_SECURITY_QA';
export const OTP_SECURITY_QA_V2 = 'OTP_SECURITY_QA_V2';
export const NO_USER_DETAILS = 'noUserDetails';
export const CANT_RETRIEVE_USER = 'cantRetrieveUser'; // TODO: check why it is camelcase
export const ACCOUNT_LOCKED_CODE = 'IAMAU5700';
export const ARCOTID_NOT_ACTIVE = 'IAMAU5705';
export const DEVICE_NOT_ASSOCIATED_CODE = 'WI0600007';
export const RESET_ARCOTID_CODE = 'IAMAU5706';
export const EMAIL_NOT_VERIFY_CODE = 'WI0600013';
export const ACCOUNT_DISABLED_CODE = '5705';
export const VALIDATE_SECURITY_QUESTIONS = 'CUST002';
export const REDIRECTION_URL = '/planviewer/ria/DeepLogin.action';
export const REDIRECTION_DEEP_LINK_URL = '/planviewer/deeplink';
export const LOGIN_URL = '/newlogin/';
export const NO_USER_DETAILS_CODE = 'WI0350006';
export const CANT_RETRIEVE_USER_CODE = 'WI0600013';
export const RESET_PWD_PAGE = 'RESET_PAGE';
export const USER_OTP_VERIFY_CODE = 'WI0600118';
export const USER_OTP_VERIFY_CODE_STATUS_L = 'WI0600129';
export const USER_VERIFICATION_CODE = 'WI060117';
export const VERIFICATION_REQUIRED = 'verificationRequired';
export const RESET_COMPLETE_PAGE = 'RESET_COMPLETE_PAGE';
export const PAGE_TIMEOUT = 'pageTimeout';
export const SESSION_TIMEOUT = 'sessionTimeout';

export const ADMIN_CHANGE_PASSWORD_CODE = 'WI0600121';
export const ADMIN_TEMP_PASSWORD_CODE = 'WI0600124';
export const MIGRATED_ADMIN_PASSWORD_CODE = 'WI0600125';
export const TEMP_USERNAME_PASSWORD = 'tempUsernamePassword';
export const CHANGE_TEMP_PASSWORD = 'changeTempPassword';
export const EXPIRED_PASSWORD = 'expiredPassword';
export const SOON_EXPIRED_PASSWORD = 'soonExpiredPassword';
export const INVALID_CREDENTIALS = 'invalidCredentials';
export const STEP_BY_STEP_GUIDE = 'stepByStepGuide';
export const TRIGGER_ANALYTICS_CUSTOM_EVENT = 'triggerAnalyticsCustomEvent';
export const STATUS_M = 'M';
export const STATUS_L = 'L';
export const STATUS_M_CODE = 'WI0600127';
export const STATUS_L_CODE = 'WI0600128';
export const TRIGGER_ANALYTICS_PAGE_VIEW_EVENT = '@FIL/APP/triggerAnalyticsPageViewEvent';
export const STATUS_D_CODE = 'WI0600130';
export const ANALYTICS_CHANGE_PASSWORD_HINT = 'change password hint';
export const UNSUPPORTED_BROWSER_WARNING = 'unSupportedBrowserPopup';
export const PROFILING_DOMAIN = 'tm.fidelity.co.uk';
export const PAGE_ID = '1';
export const THEOS_PV_CHANNEL = 'PV';
