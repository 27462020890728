/*
 *
 * LoginForm actions
 *
 */
// @flow

import {
  VALIDATE_LOGGED_USER,
  VALIDATE_LOGGED_USER_SUCCESS,
  VALIDATE_LOGGED_USER_FAILURE,
  CHANGE_USER_NAME,
  KEEP_USER_NAME,
  KEEP_USER_NAME_SUCCESS,
  KEEP_USER_NAME_FAILURE,
  FETCH_CHALLENGE_DETAILS,
  FETCH_CHALLENGE_DETAILS_SUCCESS,
  FETCH_CHALLENGE_DETAILS_FAILURE,
  ARCOT_ID_DETAILS,
  ARCOT_ID_DETAILS_SUCCESS,
  ARCOT_ID_DETAILS_FAILURE,
  TOKEN_AUTHENTICATE,
  TOKEN_AUTHENTICATE_SUCCESS,
  TOKEN_AUTHENTICATE_FAILURE,
  VALIDATE_SECURITY_QUESTIONS_SUCCESS,
  VALIDATE_SECURITY_QUESTIONS_FAILURE,
  INVALID_USER,
  PROCEED_TO_SILENT_LOGIN,
  SET_USER_SECURITY_QUES,
  VERIFY_REQUIRED,
  VERIFY_REQUIRED_SUCCESS,
  VERIFY_REQUIRED_FAILURE,
  MIGRATED_USER_OPTION,
  EXPIRY_CHECK,
  EXPIRY_CHECK_SUCCESS,
  EXPIRY_CHECK_FAILURE,
  SET_USER_TYPE,
  REDIRECT_LOGOUT,
  REDIRECT_LOGOUT_SUCCESS,
  REDIRECT_LOGOUT_FAILURE,
  REDIRECT_TO_LOGIN_PAGE,
  GET_MEMBER_INFO,
  GET_MEMBER_INFO_FAILURE,
  GET_MEMBER_INFO_SUCCESS,
  GET_PREAUTH_TOKEN,
  GET_PREAUTH_TOKEN_FAILURE,
  GET_PREAUTH_TOKEN_SUCCESS,
  SET_DEVICE_REG,
  UNSUPPORTED_BROWSER,
  LOADER,
} from './LoginPage.constants';

export const validateLoggedUser = ({
  formData,
  isArcot,
}: { formData: Object, isArcot: boolean } = {}) => ({
  type: VALIDATE_LOGGED_USER,
  data: { formData, isArcot },
});
export const validateLoggedUserSuccess = () => ({ type: VALIDATE_LOGGED_USER_SUCCESS });
export const validateLoggedUserFailure = (error: Object) => ({
  type: VALIDATE_LOGGED_USER_FAILURE,
  error,
});

export const fetchChallengeDetails = () => ({ type: FETCH_CHALLENGE_DETAILS });
export const fetchChallengeSuccess = (data: Object) => ({
  type: FETCH_CHALLENGE_DETAILS_SUCCESS,
  data,
});
export const fetchChallengeFailure = (error: Object) => ({
  type: FETCH_CHALLENGE_DETAILS_FAILURE,
  error,
});

export const getArcotId = () => ({ type: ARCOT_ID_DETAILS });
export const getArcotIdSuccess = (data: Object) => ({ type: ARCOT_ID_DETAILS_SUCCESS, data });
export const getArcotIdFailure = (error: Object) => ({ type: ARCOT_ID_DETAILS_FAILURE, error });

export const tokenAuthenticate = () => ({ type: TOKEN_AUTHENTICATE });
export const tokenAuthenticateSuccess = (data: Object) => ({
  type: TOKEN_AUTHENTICATE_SUCCESS,
  data,
});
export const tokenAuthenticateFailure = (error: Object) => ({
  type: TOKEN_AUTHENTICATE_FAILURE,
  error,
});

export const validateSecurityQuestionsSuccess = (data: Object) => ({
  type: VALIDATE_SECURITY_QUESTIONS_SUCCESS,
  data,
});
export const validateSecurityQuestionsFailure = (error: Object) => ({
  type: VALIDATE_SECURITY_QUESTIONS_FAILURE,
  error,
});

export const showInvalidUserError = (error: Object) => ({ type: INVALID_USER, error });

export const proceedToSilentLogin = () => ({ type: PROCEED_TO_SILENT_LOGIN });

export const setSecurityQuestionToStore = (securityQuestionData: [{ [string]: string }]) => ({
  type: SET_USER_SECURITY_QUES,
  securityQuestion: securityQuestionData,
});

export const verifyRequired = (formData: Object) => ({ type: VERIFY_REQUIRED, formData });
export const verifyRequiredSuccess = (data: Object) => ({ type: VERIFY_REQUIRED_SUCCESS, data });
export const verifyRequiredFailure = (error: Object) => ({ type: VERIFY_REQUIRED_FAILURE, error });
export const changeUserName = (token: string) => ({
  type: CHANGE_USER_NAME,
  token,
});

export const keepUserName = (token: string) => ({ type: KEEP_USER_NAME, token });
export const keepUserNameSuccess = (data: Object) => ({ type: KEEP_USER_NAME_SUCCESS, data });
export const keepUserNameFailure = (error: Object) => ({
  type: KEEP_USER_NAME_FAILURE,
  error,
});

export const expireCheck = (token: string) => ({ type: EXPIRY_CHECK, token });
export const expireCheckSuccess = (data: Object) => ({ type: EXPIRY_CHECK_SUCCESS, data });
export const expireCheckFailure = (error: Object) => ({
  type: EXPIRY_CHECK_FAILURE,
  error,
});

export const redirectLogout = () => ({ type: REDIRECT_LOGOUT });
export const redirectLogoutSuccess = (data: Object) => ({ type: REDIRECT_LOGOUT_SUCCESS, data });
export const redirectLogoutFailure = (error: Object) => ({ type: REDIRECT_LOGOUT_FAILURE, error });

export const setUserType = (user: string) => ({ type: SET_USER_TYPE, user });

export const migratedUserOption = () => ({ type: MIGRATED_USER_OPTION });

export const redirectToLoginPage = () => ({ type: REDIRECT_TO_LOGIN_PAGE });

export const getMemberStatusAction = (param): DefaultActionType => ({
  type: GET_MEMBER_INFO,
  param,
});
export const getMemberSuccess = (data: FetchOtpType): FetchOtpReturnType => ({
  type: GET_MEMBER_INFO_SUCCESS,
  data,
});
export const getMemberFailure = (): DefaultErrorActionType => ({
  type: GET_MEMBER_INFO_FAILURE,
});
export const getPreAuthToken = (): DefaultActionType => ({
  type: GET_PREAUTH_TOKEN,
});
export const getPreAuthTokeSuccess = (data: FetchOtpType): FetchOtpReturnType => ({
  type: GET_PREAUTH_TOKEN_SUCCESS,
  data,
});
export const getPreAuthFailure = (err: ?string): DefaultErrorActionType => ({
  type: GET_PREAUTH_TOKEN_FAILURE,
  err,
});

export const setDeviceReg = (isDeviceReg) => ({
  type: SET_DEVICE_REG,
  isDeviceReg,
});

export const unSupportedBrowser = (isOpened) => ({
  type: UNSUPPORTED_BROWSER,
  isOpened,
});
export const loader = (active, hide) => ({
  type: LOADER,
  active,
  hide,
});

export default validateLoggedUser;
