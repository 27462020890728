/**
 *
 * LoginForm
 *
 */

// @flow

import React, { PureComponent } from 'react';
import memoizeone from 'memoize-one';
import type { Node } from 'react';
import {
  Form,
  TextField,
  withField,
  Button,
  getLabel,
  GridContainer,
  Grid,
  Cell,
  Spinner,
} from '@fil-global/eiswurfel';
import get from 'lodash/get';
import type { Props } from './types';
import { RETRIEVE_USERNAME_PAGE, RETRIEVE_USERNAME_JOURNEY } from '../../../global/constants';
import {
  USER_NAME_REGEX,
  ANALYTICS_PERSONAL_DETAILS,
  RESEND_LIMIT_REACH,
} from './ForgotLoginDetails.constants';
import Popup from '../../molecules/Popup';
import postMessage from '../../../utils/postMessage';

import {
  CHANNEL_ACRONYM_RETRIEVE,
  SITE_SECTION_RETRIEVE,
} from '../../../global/AnalyticsTracking/constants';
import { NO_USER } from '../../../containers/organisms/ForgotLoginDetails/ForgotLoginDetails.constants';

const TextFieldItem = withField(TextField);

class ForgotLoginDetails extends PureComponent<Props> {
  validateSchema = memoizeone((labels) => {
    const schema = {
      username: {
        required: get(labels, 'validationErrors.username.isRequired', 'isRequired'),
      },
    };
    return schema;
  });

  getInitialValues = () => {
    const { username } = this.props;
    const initialValues = {
      username: '',
    };
    if (username) {
      initialValues.username = username;
    }
    return initialValues;
  };

  componentWillUnmount = () => {
    window.scrollTo(0, 0);
  };

  onPopupClose = (): void => {
    const { activePopup, closeCurrentElement } = this.props;
    if (window.ReactNativeWebView && activePopup.newActivePopup === 'resetPasswordDisabled') {
      postMessage('mobile_app_unsuccessful_password_reset');
    } else {
      closeCurrentElement();
    }
  };

  handleSubmit = (values: Object) => {
    const { verifyUser, setActivePopup } = this.props;
    const isValiUserName = USER_NAME_REGEX.test(values.username);
    if (!isValiUserName) {
      setActivePopup({ isShowPopup: true, newActivePopup: NO_USER });
    } else {
      verifyUser({ formData: values });
    }
  };

  redirectToRetrieve = () => {
    const { setCurrentJourney, setActivePage, setUserStatus } = this.props;
    setCurrentJourney(RETRIEVE_USERNAME_JOURNEY);
    setUserStatus('');
    setActivePage(
      {
        isPageChanged: true,
        newActivePage: RETRIEVE_USERNAME_PAGE,
      },
      ANALYTICS_PERSONAL_DETAILS,
      SITE_SECTION_RETRIEVE,
      CHANNEL_ACRONYM_RETRIEVE
    );
  };

  renderOtpNotifications = (): void => {
    const { labels, forgotLoginDetailError } = this.props;
    switch (forgotLoginDetailError) {
      case RESEND_LIMIT_REACH:
        return (
          <div className="notification error">
            <span className="icon fil-icon fil-icon-error " />
            <p>{getLabel(labels, 'resendLimitCount')}</p>
          </div>
        );

      default:
        return null;
    }
  };

  render(): Node {
    /* eslint-disable */
    const {
      labels,
      activePopup,
      verifyRequired,
      isFetching,
      errorCode,
      username,
      pingToggle,
    } = this.props;
    /* eslint-enable */

    const errorMessage =
      errorCode === RESEND_LIMIT_REACH
        ? getLabel(labels[activePopup.newActivePopup], 'resendLimitCount')
        : '';

    if (!labels) {
      return null;
    }
    return (
      <>
        {activePopup && activePopup.isShowPopup ? (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            closeModal={this.onPopupClose}
            showModal
            setActivePage={verifyRequired}
            errorMessage={errorMessage}
          />
        ) : null}

        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}

        <GridContainer variation=" ">
          <Grid margin="grid-margin-x align-center">
            <Cell extraClasses="medium-8 large-6 content-container forgot-container">
              <Grid margin="grid-padding-x align-center">
                <Cell extraClasses="medium-10">
                  <Grid margin="grid-margin-x align-center">
                    <Cell extraClasses="heading">
                      <h2 className="text-center"> {getLabel(labels, 'title')}</h2>
                    </Cell>
                    <Cell extraClasses="cell-block-y">
                      <div className="text-left">
                        {labels.instructions.map((instruction, key) => (
                          <p id={`instruction-${key.toString()}`} key={`${key.toString()}`}>
                            {instruction}
                          </p>
                        ))}
                      </div>
                    </Cell>
                    <Cell extraClasses="cell-block-y">
                      <Form
                        initialValues={this.getInitialValues()}
                        validationSchema={this.validateSchema(labels)}
                        handleSubmit={this.handleSubmit}
                        id="forgot-details-form"
                        formRenderFunc={(props) => (
                          <>
                            <TextFieldItem
                              name="username"
                              type="text"
                              className="username-input"
                              label={getLabel(labels, 'username')}
                              maxLength="45"
                              id="username"
                              disabled={username}
                            />

                            {pingToggle !== 'on' && (
                              <Button
                                id="forgot-username"
                                theme="link"
                                onClick={this.redirectToRetrieve}
                                extraClasses="forgot-username-link  fil-icon fil-icon-arrow-r-light">
                                {labels.forgotUsername}
                              </Button>
                            )}
                            <Button
                              type="submit"
                              id="confirm-username"
                              extraClasses="block float-center primary-cta expanded"
                              disabled={!(props.values.username.length > 0)}
                              theme="primary">
                              {getLabel(labels, 'confirm')}
                            </Button>

                            {this.renderOtpNotifications()}
                          </>
                        )}
                      />
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </GridContainer>
      </>
    );
  }
}
ForgotLoginDetails.defaultProps = {};

export default ForgotLoginDetails;
