// @flow
import {
  RETRIEVE_USER_NAME,
  RETRIEVE_USER_NAME_SUCCESS,
  RETRIEVE_USER_NAME_FAILURE,
  SET_PI_DETAILS,
} from './RetrieveUserName.constants';

export const retrieveUserName = <T>(
  formData: T,
  postValidation: boolean = false
): { type: typeof RETRIEVE_USER_NAME, formData: T } => ({
  type: RETRIEVE_USER_NAME,
  formData,
  postValidation,
});

export const retrieveUserNameSuccess = <T>(
  username: T,
  isNonMigrated: boolean
): { type: typeof RETRIEVE_USER_NAME_SUCCESS, username: T, isNonMigrated: boolean } => ({
  type: RETRIEVE_USER_NAME_SUCCESS,
  username,
  isNonMigrated,
});

export const retrieveUserNameFailure = (): { type: typeof RETRIEVE_USER_NAME_FAILURE } => ({
  type: RETRIEVE_USER_NAME_FAILURE,
});

export const setPIDetails = (data) => ({
  type: SET_PI_DETAILS,
  piDetails: data || '',
});
