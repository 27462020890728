import { connect } from 'react-redux';
import get from 'lodash/get';

import HeaderComponent from '../../../components/organisms/Header';

export const mapStateToProps = (state) => ({
  deviceType: get(state, 'global.globalData.deviceType', ''),
  header: get(state, 'global.globalData.labels.header', ''),
});

export default connect(mapStateToProps)(HeaderComponent);
