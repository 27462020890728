/** * * TermsAndConditions * */

// @flow

import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';

import TermsAndConditions from '../../../components/organisms/TermsAndConditions';
import saga from './TermsAndConditions.saga';
import reducer from './TermsAndConditions.reducer';
import initialActions, { saveTnc, clearError } from './TermsAndConditions.actions';
import { redirectLogout, redirectToLoginPage } from '../../templates/LoginPage/LoginPage.actions';
import {
  closeCurrentElements,
  setActivePopup,
  setSessionTimerId,
  LogOut,
} from '../../../global/actions';
import { PAGE_TIMEOUT, SESSION_TIMEOUT } from '../../../global/constants';

import type { State, Labels } from './types';

export const mapStateToProps = (state: State): { labels: Labels } => ({
  activePopup: get(state, 'global.loginData.activePopup'),
  labels: get(state, 'tnc.labels', {}),
  pageTimeoutLabels: get(state, 'global.globalData.labels.pageTimeout', {}),
  isError: get(state, 'tnc.isError', false),
  serviceError: get(state, 'global.globalData.labels.serviceErrors', ''),
  isFetching: get(state, 'tnc.isFetching', false),
  sessionTimerId: get(state, 'global.globalData.sessionTimerId'),
  sessionTimeoutLabels: get(state, 'global.globalData.labels.sessionTimeout', {}),
});

export const mapDispatchToProps = {
  saveTnc,
  clearError,
  setActivePopup: setActivePopup.bind(null, { isShowPopup: true, newActivePopup: PAGE_TIMEOUT }),
  logout: redirectLogout,
  closeCurrentElement: closeCurrentElements,
  showSessionTimeoutPopup: setActivePopup.bind(null, {
    isShowPopup: true,
    newActivePopup: SESSION_TIMEOUT,
  }),
  setSessionTimerId,
  redirectToLoginPage,
  LogOut,
};

export default clientEnhance(TermsAndConditions, {
  key: 'tnc',
  mapStateToProps,
  mapDispatchToProps,
  saga,
  initialActions,
  reducer,
});
