// @flow
import { takeLatest, call, put, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  ANSWER_SECURITY_QUESTION,
  RESET_USERNAME_DISABLE,
  NO_SECQ_AVAILABLE,
  SECQ_AVAILABLE,
  SEND_OTP,
  ANALYTICS_CHOOSE_NEW_PASSWORD,
} from './AnswerSecurityQuestions.constants';
import {
  SERVICE_ERROR,
  OTP_VERIFY,
  RESET_PWD_PAGE,
  LOGIN_PAGE,
  RETRIEVE_USERNAME_JOURNEY,
  RESET_PASSWORD_JOURNEY,
  RETRIEVE_USERNAME_PAGE,
  STATUS_L,
} from '../../../global/constants';
import {
  showServiceError,
  setActivePopup,
  setToken,
  setActivePage,
  setUserStatus,
} from '../../../global/actions';

import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';

import {
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_POPUP,
  TOKEN_EXPIRED_ANALYTICS,
} from '../../templates/LoginPage/LoginPage.constants';

import {
  setSecurityQuestionToStore,
  verifyRequired,
} from '../../templates/LoginPage/LoginPage.actions';

import { retrieveUserName } from '../RetrieveUserName/RetrieveUserName.actions';

import {
  answerSecurityQuestionsFailure,
  answerSecurityQuestionsSuccess,
} from './AnswerSecurityQuestions.actions';
import type { Action, Error } from './types';

export function* handleError(error: Error): Generator<any, any, any> {
  const data = (error && error.data) || {};
  let currentPopup = {};
  let store;
  let journey;
  switch (data.code) {
    case TOKEN_EXPIRED:
      currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
      break;
    case SECQ_AVAILABLE:
      yield put(setToken(error.data.token));
      yield put(setSecurityQuestionToStore(data.secq));
      break;
    case NO_SECQ_AVAILABLE:
      store = yield select();
      journey = get(store, 'global.globalData.currentJourney');
      if (journey === RESET_PASSWORD_JOURNEY) {
        yield put(setUserStatus(STATUS_L));
        yield put(setToken(error.data.token));
        yield put(
          setActivePage({
            isPageChanged: true,
            newActivePage: RETRIEVE_USERNAME_PAGE,
          })
        );
      } else {
        currentPopup = {
          isShowPopup: true,
          newActivePopup: RESET_USERNAME_DISABLE,
          redirectPage: LOGIN_PAGE,
        };
        yield put(setActivePopup(currentPopup));
      }

      break;
    default:
      yield put(showServiceError(SERVICE_ERROR));
      break;
  }
}

export function* answerSecurityQuestions(action: Action): Saga<void> {
  try {
    const values = action.payload;
    const store = yield select();
    const token = get(store, 'global.loginData.token');
    const securityQuestions = Object.keys(values).map((key) => ({
      Id: Number(key.replace('ans-', '').trim()),
      answer: values[key],
    }));
    const payload = { securityQuestionArray: securityQuestions, token };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.ValidateSecQ, payload);
    const { code } = data;
    const journey = get(store, 'global.globalData.currentJourney');
    yield put(setToken(data.token));
    if (journey === RESET_PASSWORD_JOURNEY) {
      if (code === SEND_OTP) {
        yield put(
          verifyRequired({
            isPageChanged: true,
            newActivePage: OTP_VERIFY,
          })
        );
      } else {
        yield put(
          setActivePage(
            {
              isPageChanged: true,
              newActivePage: RESET_PWD_PAGE,
            },
            ANALYTICS_CHOOSE_NEW_PASSWORD,
            SITE_SECTION_RESET,
            CHANNEL_ACRONYM_RESET
          )
        );
      }
    } else if (journey === RETRIEVE_USERNAME_JOURNEY) {
      yield put(retrieveUserName(null, true));
      yield put(answerSecurityQuestionsSuccess());
    }
  } catch (e) {
    yield put(answerSecurityQuestionsFailure());
    yield call(handleError, e.response);
  }
}

export default function* answerSecurityQuestionsSaga(): Saga<void> {
  yield takeLatest(ANSWER_SECURITY_QUESTION, answerSecurityQuestions);
}
