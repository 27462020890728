/*
 *
 * ChangeUsernamePassword actions
 *
 */
// @flow

import {
  CHANGE_USERNAME_PWD,
  CHANGE_USERNAME_PWD_SUCCESS,
  CHANGE_USERNAME_PWD_FAILURE,
  CLEAR_CHANGE_USERNAME_PWD_ERROR,
} from './ChangeUsernamePassword.constants';

export const changeUsernamePwd = (formData: Object, formikBag: Object = {}) => ({
  type: CHANGE_USERNAME_PWD,
  formData,
  setErrors: formikBag.setErrors,
});
export const changeUsernamePwdSuccess = (data: Object) => ({
  type: CHANGE_USERNAME_PWD_SUCCESS,
  data,
});
export const changeUsernamePwdFailure = (error: Object) => ({
  type: CHANGE_USERNAME_PWD_FAILURE,
  error,
});
export const clearError = { type: CLEAR_CHANGE_USERNAME_PWD_ERROR };

export default changeUsernamePwd;
