/** * * RetrieveUserName * */

// @flow

import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import RetrieveUserName from '../../../components/organisms/RetrieveUserName';
import {
  selectRetrieveUserNameLabels,
  selectActivePopup,
  selectServiceErrors,
  selectIsFetching,
  selectHasServiceError,
  selectErrorCode,
  selectPIDetails,
} from './RetrieveUserName.selectors';
import { verifyRequired } from '../../templates/LoginPage/LoginPage.actions';
import { retrieveUserName } from './RetrieveUserName.actions';
import { closeCurrentElements, setActivePage } from '../../../global/actions';
import type { State, Labels, DispatchProps, Action } from './types';

const pingToggle = process.env.REACT_APP_PING_TOGGLE;

export const mapStateToProps = (state: State): { labels: Labels } => ({
  labels: selectRetrieveUserNameLabels(state),
  activePopup: selectActivePopup(state),
  serviceError: selectServiceErrors(state),
  isFetching: selectIsFetching(state),
  hasError: selectHasServiceError(state),
  errorCode: selectErrorCode(state),
  piDetails: selectPIDetails(state),
  pingToggle,
});

export const mapDispatchToProps = (dispatch: Dispatch<any>): DispatchProps => ({
  retrieveUserName: (formData: Action) => dispatch(retrieveUserName(formData)),
  closePopup: (newActivePage: string) => {
    if (newActivePage) {
      dispatch(setActivePage({ isPageChanged: true, newActivePage }));
    }
    dispatch(closeCurrentElements());
  },
  verifyRequired: (formData: Action) => dispatch(verifyRequired(formData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RetrieveUserName);
