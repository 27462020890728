/**
 *
 * ApplicationLayout
 *
 */

// @flow

import React, { PureComponent } from 'react';

import Layout from '../Layout';
import renderComponent from './LoginPage.config';
import LoginForm from '../../../containers/organisms/LoginForm';
import Popup from '../../molecules/Popup';
import { TOKEN_EXPIRED_POPUP } from '../../../containers/templates/LoginPage/LoginPage.constants';
import type { Props } from './types';

class LoginPage extends PureComponent<Props> {
  render() {
    const { activePage, activePopup, labels, redirectToLoginPage } = this.props;
    const Component = renderComponent[activePage.newActivePage] || LoginForm;
    const showTokenTimeoutPopup = !!(
      activePopup &&
      activePopup.isShowPopup &&
      activePopup.newActivePopup === TOKEN_EXPIRED_POPUP
    );
    return (
      <Layout className="content-wrapper">
        {showTokenTimeoutPopup && (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            primaryAction={redirectToLoginPage}
            closeModal={redirectToLoginPage}
            showModal
          />
        )}
        <Component />
      </Layout>
    );
  }
}

export default LoginPage;
