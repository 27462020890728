/*
 *
 * ResetPassword reducer
 *
 */

import { updateState } from '@fil-global/eiswagen';
import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './ResetPassword.constants';

export default function ResetPasswordReducer(state = {}, action) {
  switch (action.type) {
    case RESET_PASSWORD:
      return updateState(state, { isFetching: true });
    case RESET_PASSWORD_SUCCESS:
      return updateState(state, { isFetching: false });
    case RESET_PASSWORD_FAILURE:
      return updateState(state, { isFetching: false });
    default:
      return state;
  }
}
