/**
 *
 * OtpSetUp
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import memoizeone from 'memoize-one';

import {
  Button,
  TextField,
  Form,
  withField,
  SelectBox,
  Label,
  Paragraph,
  GridContainer,
  Grid,
  Icon,
  getLabel,
  Spinner,
  Cell,
  Notification,
} from '@fil-global/eiswurfel';

import type { Props, NotificationPropType, FormikTypes, State, SendOtpPayloadType } from './types';
import OTP_SETUP_CONST from './OtpSetUp.constants';
import VerifyOtpPopUp from '../../molecules/VerifyOtpPopUp';
import RememberDevicePopup from '../../molecules/RememberDevicePopup';
import emailMask from '../../../utils/emailMask';

const TextFieldItem = withField(TextField);
const SelectFieldItem = withField(SelectBox);

export default class OtpSetUp extends PureComponent<Props, State> {
  static defaultProps = {};

  countryCodes = '';

  formInitialValues = memoizeone((countryCodes) => {
    const { prepopulatedMobile, mobileCountry, migrationStatus } = this.props;
    let { prepopulatedEmail } = this.props;
    // $FlowFixMe eslint-disable-line
    let defaultcountryCodes = countryCodes[0].value;
    if (mobileCountry) {
      const prePouplatedISDCode = countryCodes.filter(
        (country) => country.countryCode === mobileCountry
      );
      if (prePouplatedISDCode) {
        defaultcountryCodes = prePouplatedISDCode[0].value;
      }
    }

    if (prepopulatedEmail) {
      prepopulatedEmail =
        migrationStatus === OTP_SETUP_CONST.STATUS_L
          ? emailMask(prepopulatedEmail.trim())
          : prepopulatedEmail.trim();
    }
    const formInitialValues = {
      [OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS]: prepopulatedEmail,
      [OTP_SETUP_CONST.MOBILE_NUMBER]: prepopulatedMobile,
      [OTP_SETUP_CONST.COUNTRY_CODE]: defaultcountryCodes,
    };

    return formInitialValues;
  });

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  /**
   * Executes verify action for a email/mobile input on click of verify button.
   * @param {string} value -  entered input value.
   * @param {string} fieldType.
   * @param {function} fieldType - action to execute.
   * @return {void}.
   */
  verifyActionHandler = (
    value: string,
    fieldType: string,
    verifyAction?: (SendOtpPayloadType) => void
  ): void => {
    const { setToken, prepopulatedEmail, migrationStatus } = this.props;
    const verifyActionPayLoad: SendOtpPayloadType = {
      token: setToken,
      channel: this.getChannel(fieldType),
      target: value,
      journey: 'login',
    };

    if (
      migrationStatus === OTP_SETUP_CONST.STATUS_L &&
      prepopulatedEmail &&
      prepopulatedEmail.trim() &&
      this.getChannel(fieldType) === 'email'
    ) {
      verifyActionPayLoad.target = prepopulatedEmail.trim();
    }

    if (fieldType === OTP_SETUP_CONST.MOBILE_NUMBER && this.countryCodes) {
      const [countryID, countryISD] = this.countryCodes.split('-');
      verifyActionPayLoad.country = countryISD;
      verifyActionPayLoad.target = value.replace(OTP_SETUP_CONST.DISCARD_LEADING_ZERO_REGEX, '');
      verifyActionPayLoad.countryCode = countryID;
    }

    if (verifyAction) {
      verifyAction(verifyActionPayLoad);
    }
  };

  /**
   * Returns channel type for respective field type for verify action payload.
   * @param {string} fieldType - input field type.
   * @return {string}. channel type for respective field type.
   */
  getChannel = (fieldType: string): string => {
    let channelType = '';
    switch (fieldType) {
      case OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS:
        channelType = OTP_SETUP_CONST.CHANNEL_EMAIL;
        break;
      case OTP_SETUP_CONST.MOBILE_NUMBER:
        channelType = OTP_SETUP_CONST.CHANNEL_MOBILE;
        break;
      default:
        break;
    }
    return channelType;
  };

  /**
   * Returns field error message if any.
   * @param {string} value - entered input value.
   * @param {string} fieldType - input field type.
   * @return {string}. error message for appropriate field type.
   */
  validateFieldValues = (value: string, fieldType: string): string => {
    const { otpSetUpLabels, verifyEmailAction, verifyMobileAction } = this.props;
    const isValidMobileNumber = OTP_SETUP_CONST.MOBILE_NUMBER_REGEX.test(value);
    const isEmailValid = OTP_SETUP_CONST.EMAIL_ADDRESS_FRMK.test(value);
    let verifyAction;
    let error = '';

    switch (fieldType) {
      case OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS:
        if (!value) {
          error = getLabel(otpSetUpLabels, `errorMessages.${fieldType}.required`);
        } else if (!isEmailValid) {
          error = getLabel(otpSetUpLabels, `errorMessages.${fieldType}.invalid`);
        }
        verifyAction = verifyEmailAction;
        break;
      case OTP_SETUP_CONST.MOBILE_NUMBER:
        if (!isValidMobileNumber) {
          error = getLabel(otpSetUpLabels, `errorMessages.${fieldType}.invalid`);
        }
        verifyAction = verifyMobileAction;
        break;
      default:
    }
    if (!error) {
      this.verifyActionHandler(value, fieldType, verifyAction);
    }
    return error;
  };

  validateMobileField = (value: string) =>
    this.validateFieldValues(value, OTP_SETUP_CONST.MOBILE_NUMBER);

  validateEmailField = (value: string) =>
    this.validateFieldValues(value, OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS);

  /**
   * Returns service errors returned by mobile/email verification service
   */
  handelError = (props: NotificationPropType, fieldType: string): string => {
    let result = '';
    const { otpSetUpLabels } = this.props;
    const prefix = fieldType === OTP_SETUP_CONST.MOBILE_NUMBER ? 'mobile' : 'email';
    const platformError = `${prefix}Error`;
    const platformServiceError = `${prefix}ServiceError`;
    const error = this.props[platformError]; //eslint-disable-line

    if (error && !props.errors[fieldType]) {
      result = getLabel(otpSetUpLabels, platformError);
    }

    if (error === OTP_SETUP_CONST.MOBILE_SERVICE_FAILED && !props.errors[fieldType]) {
      result = getLabel(otpSetUpLabels, platformServiceError);
    }

    if (error === OTP_SETUP_CONST.VELOCITY_COUNT_REACHED && !props.errors[fieldType]) {
      result = getLabel(otpSetUpLabels, 'verifyOtpPopUp.resendLimitCount');
    }

    return result;
  };

  /**
   * Returns various notification messages for appropriate field types.
   * @param {Object} props - formik props.
   * @param {string} fieldType - input field type.
   * @return {string}. notification message for appropriate field type.
   */
  handleNotifications = (props: NotificationPropType, fieldType: string): string => {
    const { isEmailVerified, isMobileNumberVerified, otpSetUpLabels } = this.props;
    let result = '';
    if (isEmailVerified && props.values[fieldType]) {
      result = getLabel(otpSetUpLabels, `${fieldType}Success`);
    } else if (!props.values[fieldType] && !props.errors[fieldType]) {
      result = getLabel(otpSetUpLabels, `${fieldType}Help`);
    } else if (props.errors[fieldType]) {
      result = '';
    } else {
      result = getLabel(otpSetUpLabels, `${fieldType}Warning`);
    }

    if (isMobileNumberVerified && props.values[fieldType]) {
      result = getLabel(otpSetUpLabels, `${fieldType}Success`);
    }

    result = this.handelError(props, fieldType) || result;

    return result;
  };

  showWarning = (props: NotificationPropType, fieldType: string): boolean =>
    props.values[fieldType] && !props.errors[fieldType];

  isEmailMask = () => {
    let isMasking = false;
    const { migrationStatus, prepopulatedEmail } = this.props;
    if (
      prepopulatedEmail &&
      prepopulatedEmail.trim() &&
      migrationStatus === OTP_SETUP_CONST.STATUS_L
    ) {
      isMasking = true;
    }

    return isMasking;
  };

  renderEmailVerifySuccessMessage = () => {
    const { otpSetUpLabels, isEmailVerified } = this.props;
    return (
      <>
        {isEmailVerified && (
          <>
            <Icon iconType="circle-tick" />
            <span
              className={classnames('success-message', {
                'hide-for-large': isEmailVerified,
              })}>
              {getLabel(otpSetUpLabels, 'emailVerified')}
            </span>
          </>
        )}
      </>
    );
  };

  renderEmailField = (props: FormikTypes) => {
    const { otpSetUpLabels, isEmailVerified, emailError } = this.props;
    return (
      <Grid>
        <div className="large-8 grid-c">
          <TextFieldItem
            disabled={isEmailVerified || this.isEmailMask()}
            name={OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS}
            label={getLabel(otpSetUpLabels, `${OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS}`)}
            validate={this.validateEmailField}
            className={classnames({
              'is-valid-input': isEmailVerified,
              'is-invalid-input': emailError,
            })}
            id={OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS}
          />
          <div className={classnames({ 'field-error': emailError })}>
            <Paragraph extraClasses={classnames('pos-rel', { 'p-notify': !emailError })}>
              {!isEmailVerified &&
              this.showWarning(props, OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS) ? (
                <Icon
                  iconType={emailError ? 'error' : 'warning-square'}
                  className={classnames('icon-align pos-rel', {
                    'notify-warning': !emailError,
                  })}
                />
              ) : null}
              <span
                className={
                  !isEmailVerified &&
                  !emailError &&
                  this.showWarning(props, OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS)
                    ? 'notify-warning'
                    : null
                }>
                {this.handleNotifications(props, OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS)}
              </span>
            </Paragraph>
          </div>
        </div>
        <div
          className={classnames('large-3 grid-c email-verify', {
            verified: isEmailVerified,
          })}>
          {!isEmailVerified && (
            <Button
              theme="secondary"
              extraClasses="verfiy-btn expanded"
              onClick={() => props.validateField(OTP_SETUP_CONST.PERSONAL_EMAIL_ADDRESS)}
              disabled={!props.values.personalEmailAddress || this.hasVelocityReached()}>
              {getLabel(otpSetUpLabels, 'verify')}
            </Button>
          )}

          {this.renderEmailVerifySuccessMessage()}
        </div>
      </Grid>
    );
  };

  /**
   * Checks Whether OTP Velocity has reached or not
   */
  hasVelocityReached = () => {
    const { mobileError, emailError, error } = this.props;
    return (
      mobileError === OTP_SETUP_CONST.VELOCITY_COUNT_REACHED ||
      emailError === OTP_SETUP_CONST.VELOCITY_COUNT_REACHED ||
      get(error, 'code') === OTP_SETUP_CONST.VELOCITY_COUNT_REACHED
    );
  };

  /**
   * Checks whehter confirm button is disabled or not
   */
  isConfirmBtnDisabled = () => {
    const { isEmailVerified } = this.props;
    return !isEmailVerified || this.hasVelocityReached();
  };

  renderMobileField = (props: FormikTypes) => {
    const { otpSetUpLabels, countryCodes, isMobileNumberVerified, mobileError } = this.props;
    const countryCode = get(props, 'values.countryCode');
    this.countryCodes = countryCode;
    const verifyDisabled = !props.values.mobilePhoneNumber || this.hasVelocityReached();
    return (
      <Grid extraClasses="align-center">
        <div className="grid-c large-8">
          <div className="grid-x align-justify">
            <div className="small-5">
              {countryCodes && (
                <SelectFieldItem
                  className={classnames({
                    'is-valid-input': isMobileNumberVerified,
                  })}
                  disabled={isMobileNumberVerified}
                  name="countryCode"
                  id="countryCode"
                  optionList={countryCodes}
                  value={props.values.countryCode}
                />
              )}
            </div>
            <div className="mw-mbl small-6">
              <TextFieldItem
                className={classnames({
                  'is-valid-input': isMobileNumberVerified,
                  'is-invalid-input': mobileError,
                })}
                disabled={isMobileNumberVerified}
                name={OTP_SETUP_CONST.MOBILE_NUMBER}
                id={OTP_SETUP_CONST.MOBILE_NUMBER}
                validate={this.validateMobileField}
              />
            </div>
          </div>
          <div className={classnames({ 'field-error': mobileError })}>
            <Paragraph extraClasses={classnames('pos-rel', { 'p-notify': !mobileError })}>
              {!isMobileNumberVerified && this.showWarning(props, OTP_SETUP_CONST.MOBILE_NUMBER) ? (
                <Icon
                  iconType={mobileError ? 'error' : 'warning-square'}
                  className={classnames('icon-align pos-rel', {
                    'notify-warning': !mobileError,
                  })}
                />
              ) : null}
              <span
                className={classnames({
                  'notify-warning':
                    !isMobileNumberVerified &&
                    !mobileError &&
                    this.showWarning(props, OTP_SETUP_CONST.MOBILE_NUMBER),
                })}>
                {this.handleNotifications(props, OTP_SETUP_CONST.MOBILE_NUMBER)}
              </span>
            </Paragraph>
          </div>
        </div>
        <div
          className={classnames('grid-c large-3 mobile-verify', {
            verified: isMobileNumberVerified,
          })}>
          {!isMobileNumberVerified && (
            <Button
              theme="secondary"
              extraClasses="verfiy-btn expanded"
              disabled={verifyDisabled}
              onClick={() => props.validateField(OTP_SETUP_CONST.MOBILE_NUMBER)}>
              {getLabel(otpSetUpLabels, 'verify')}
            </Button>
          )}

          {isMobileNumberVerified && (
            <>
              <Icon iconType="circle-tick" />
              <span
                className={classnames('success-message', {
                  'hide-for-large': isMobileNumberVerified,
                })}>
                {getLabel(otpSetUpLabels, 'mobileVerified')}
              </span>
            </>
          )}
        </div>
      </Grid>
    );
  };

  /**
   * Returns form node.
   * @param {Object} props - formik props.
   * @return {Node}.
   */
  formRenderOtpSetup = (props: FormikTypes): Node => {
    const { otpSetUpLabels, isEmailVerified } = this.props;
    return (
      <GridContainer extraClasses="full otp-setup-container">
        {this.renderEmailField(props)}
        {isEmailVerified ? (
          <>
            <Label htmlFor="mobileNumberSection" className="grid-c">
              {getLabel(otpSetUpLabels, `${OTP_SETUP_CONST.MOBILE_NUMBER}`)}
            </Label>
            {this.renderMobileField(props)}
          </>
        ) : null}
        <Cell extraClasses="large-4 align-center">
          <Button
            theme="primary"
            onClick={this.openRenderRememberDevicePopup}
            extraClasses="primary-cta expanded"
            disabled={this.isConfirmBtnDisabled()}>
            {getLabel(otpSetUpLabels, 'confirm')}
          </Button>
        </Cell>
      </GridContainer>
    );
  };

  /**
   * Handles Otp verify popup close.
   * @return {void}.
   */
  closeModal = (): void => {
    const { handleVerifyPopUp } = this.props;
    handleVerifyPopUp();
  };

  handlePopUpSubmit = (values: { [string]: string }) => {
    const { validateOtpAction, showPopUpType, error, isError } = this.props;
    let { otpToken } = this.props;
    if (isError) {
      otpToken = get(error, 'token');
    }
    const { inputPassCode } = values;
    const validateOtpActionPayLoad = {
      token: otpToken,
      otp: inputPassCode.toString(),
    };
    validateOtpAction(validateOtpActionPayLoad, showPopUpType);
  };

  reSendOTP = (): void => {
    const { reSendOtpAction, error, isError } = this.props;
    let { otpToken } = this.props;
    if (isError && get(error, 'token')) {
      otpToken = get(error, 'token');
    }

    const resendOtpActionPayLoad = {
      token: otpToken,
    };
    reSendOtpAction(resendOtpActionPayLoad);
  };

  renderVerifyPopUp = (): Node => {
    const { popUpVisible, showPopUpType, otpSetUpLabels, error, isError, isResend } = this.props;
    const popUpParams = {
      title: getLabel(otpSetUpLabels, `verifyOtpPopUp.${showPopUpType}Title`),
      description: getLabel(otpSetUpLabels, `verifyOtpPopUp.${showPopUpType}Desc`),
      showModal: popUpVisible,
      error,
      isError,
      isResend,
      cancellable: {
        closeButtonHandler: this.closeModal,
      },
      handlePopUpSubmit: this.handlePopUpSubmit,
      handleReSendOTP: this.reSendOTP,
    };

    return <VerifyOtpPopUp {...this.props} params={popUpParams} />;
  };

  openRenderRememberDevicePopup = (): void => {
    const { openRememberDevicePopUpAction, isDeviceReg, submitAuthenticate } = this.props;
    if (isDeviceReg) {
      submitAuthenticate({ isArcot: true });
    } else {
      this.setState({ showModal: true });
      openRememberDevicePopUpAction();
    }
  };

  renderRememberDevicePopup = (): Node => {
    const { rememberDeviceLabels, closeCurrentElement, submitAuthenticate } = this.props;
    const cancellable = {
      closeButtonHandler: closeCurrentElement,
    };
    return (
      <RememberDevicePopup
        {...this.props}
        labels={rememberDeviceLabels}
        showModal
        closeModal={closeCurrentElement}
        submitAuthenticate={submitAuthenticate}
        cancellable={cancellable}
      />
    );
  };

  render(): Node {
    const {
      popUpVisible,
      isGetCountryCodeCalling,
      isFetching,
      countryCodes,
      isAuthenticateCalling,
      otpSetUpLabels,
      migrationStatus,
    } = this.props;
    const { showModal } = this.state;

    return (
      <GridContainer variation=" ">
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container">
            <Grid margin="grid-padding-x align-center">
              {this.hasVelocityReached() && (
                <Cell>
                  <Notification theme="error">
                    {getLabel(otpSetUpLabels, 'errorMessages.velocityReached')}
                  </Notification>
                </Cell>
              )}
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(otpSetUpLabels, 'title')}</h2>
                    <p className="text-center">{getLabel(otpSetUpLabels, 'subTitle')}</p>
                  </Cell>
                  <Cell>
                    <>
                      {(isAuthenticateCalling || isGetCountryCodeCalling || isFetching) && (
                        <Spinner isVisible displayType="fixed" size="large" />
                      )}

                      {countryCodes && migrationStatus && (
                        <div>
                          {popUpVisible && this.renderVerifyPopUp()}
                          {showModal && this.renderRememberDevicePopup()}
                          <Form
                            initialValues={this.formInitialValues(countryCodes)}
                            validateOnChange={false}
                            validateOnBlur={false}
                            formRenderFunc={(props) => this.formRenderOtpSetup(props)}
                          />
                        </div>
                      )}
                    </>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}
