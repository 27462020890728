/*
 *
 * AccountSummary reducer
 *
 */

// @flow
import { combineReducers } from 'redux';
import { updateState } from '@fil-global/eiswagen';

import {
  SUBMIT_OTP,
  SUBMIT_OTP_SUCCESS,
  SUBMIT_OTP_FAILURE,
  RESEND_OTP,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  OTP_NOTIFICATION_MESSAGE,
} from './OtpVerification.constants';

export function submitOtp(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case SUBMIT_OTP:
      return updateState(state, { isFetching: true });
    case SUBMIT_OTP_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case SUBMIT_OTP_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}
export function resendOtp(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case RESEND_OTP:
      return updateState(state, { isFetching: true });
    case RESEND_OTP_SUCCESS:
      return updateState(state, { isFetching: false, data: action.data });
    case RESEND_OTP_FAILURE:
      return updateState(state, { isFetching: false, error: action.error });
    default:
      return state;
  }
}

export function showOtpMessages(state?: { [string]: string } = {}, action: Object) {
  switch (action.type) {
    case OTP_NOTIFICATION_MESSAGE:
      return updateState(state, {
        isFetching: true,
        optNotificationKey: action.optNotificationKey,
      });
    case RESEND_OTP:
      return updateState(state, { optNotificationKey: '' });
    default:
      return state;
  }
}

// $FlowFixMe eslint-disable-line
const userReducer = combineReducers({ submitOtp, resendOtp, showOtpMessages });

export default userReducer;
