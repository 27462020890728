export const VALIDATE_LOGGED_USER = '@FIL/PVLOGIN/VALIDATE_LOGGED_USER';
export const VALIDATE_LOGGED_USER_SUCCESS = '@FIL/PVLOGIN/VALIDATE_LOGGED_USER_SUCCESS';
export const VALIDATE_LOGGED_USER_FAILURE = '@FIL/PVLOGIN/VALIDATE_LOGGED_USER_FAILURE';
export const CHANGE_USER_NAME = '@FIL/PVLOGIN/CHANGE_USER_NAME';

export const FETCH_CHALLENGE_DETAILS = '@FIL/PVLOGIN/FETCH_CHALLENGE_DETAILS';
export const FETCH_CHALLENGE_DETAILS_SUCCESS = '@FIL/PVLOGIN/FETCH_CHALLENGE_DETAILS_SUCCESS';
export const FETCH_CHALLENGE_DETAILS_FAILURE = '@FIL/PVLOGIN/FETCH_CHALLENGE_DETAILS_FAILURE';
export const ARCOT_ID_DETAILS = '@FIL/PVLOGIN/VALIDATE_USER_DETAILS';
export const ARCOT_ID_DETAILS_SUCCESS = '@FIL/PVLOGIN/VALIDATE_USER_DETAILS_SUCCESS';
export const ARCOT_ID_DETAILS_FAILURE = '@FIL/PVLOGIN/VALIDATE_USER_DETAILS_FAILURE';
export const TOKEN_AUTHENTICATE = '@FIL/PVLOGIN/TOKEN_AUTHENTICATE';
export const TOKEN_AUTHENTICATE_SUCCESS = '@FIL/PVLOGIN/TOKEN_AUTHENTICATE_SUCCESS';
export const TOKEN_AUTHENTICATE_FAILURE = '@FIL/PVLOGIN/TOKEN_AUTHENTICATE_FAILURE';
export const PROCEED_TO_SILENT_LOGIN = '@FIL/PVLOGIN/PROCEED_TO_SILENT_LOGIN';
export const MIGRATED_USER_OPTION = '@FIL/PVLOGIN/MIGRATED_USER_OPTION';
export const VALIDATE_SECURITY_QUESTIONS_SUCCESS =
  '@FIL/PVLOGIN/VALIDATE_SECURITY_QUESTIONS_SUCCESS';
export const VALIDATE_SECURITY_QUESTIONS_FAILURE =
  '@FIL/PVLOGIN/VALIDATE_SECURITY_QUESTIONS_FAILURE';
export const INVALID_USER = '@FIL/PVLOGIN/INVALID_CREDS';
export const SET_DEVICE_REG = '@FIL/PVLOGIN/SET_DEVICE_REG';
export const UNSUPPORTED_BROWSER = '@FIL/PVLOGIN/UNSUPPORTED_BROWSER';
export const LOADER = '@FIL/PVLOGIN/LOADER';

export const SET_USER_SECURITY_QUES = '@FIL/APP/SET_USER_SECURITY_QUES';
export const SET_USER_TYPE = '@FIL/APP/SET_USER_TYPE';

export const VERIFY_REQUIRED = '@FIL/PVLOGIN/VERIFY_REQUIRED';
export const VERIFY_REQUIRED_SUCCESS = '@FIL/PVLOGIN/VERIFY_REQUIRED_SUCCESS';
export const VERIFY_REQUIRED_FAILURE = '@FIL/PVLOGIN/VERIFY_REQUIRED_FAILURE';

export const KEEP_USER_NAME = '@FIL/PVLOGIN/KEEP_USER_NAME';
export const KEEP_USER_NAME_SUCCESS = '@FIL/PVLOGIN/KEEP_USER_NAME_SUCCESS';
export const KEEP_USER_NAME_FAILURE = '@FIL/PVLOGIN/KEEP_USER_NAME_FAILURE';

export const EXPIRY_CHECK = '@FIL/PVLOGIN/EXPIRY_CHECK';
export const EXPIRY_CHECK_SUCCESS = '@FIL/PVLOGIN/EXPIRY_CHECK_SUCCESS';
export const EXPIRY_CHECK_FAILURE = '@FIL/PVLOGIN/EXPIRY_CHECK_FAILURE';

export const REDIRECT_LOGOUT = '@FIL/PVLOGIN/REDIRECT_LOGOUT';
export const REDIRECT_LOGOUT_SUCCESS = '@FIL/PVLOGIN/REDIRECT_LOGOUT_SUCCESS';
export const REDIRECT_LOGOUT_FAILURE = '@FIL/PVLOGIN/REDIRECT_LOGOUT_FAILURE';
export const REDIRECT_TO_LOGIN_PAGE = '@FIL/PVLOGIN/REDIRECT_TO_LOGIN_PAGE';

export const GET_MEMBER_INFO = '@FIL/OtpPage/GET_MEMBER_INFO';
export const GET_MEMBER_INFO_SUCCESS = '@FIL/OtpPage/GET_MEMBER_INFO_SUCCESS';
export const GET_MEMBER_INFO_FAILURE = '@FIL/OtpPage/GET_MEMBER_INFO_FAILURE';
export const GET_PREAUTH_TOKEN = '@FIL/OtpPage/GET_PREAUTH_TOKEN';
export const GET_PREAUTH_TOKEN_SUCCESS = '@FIL/OtpPage/GET_PREAUTH_TOKEN_SUCCESS';
export const GET_PREAUTH_TOKEN_FAILURE = '@FIL/OtpPage/GET_PREAUTH_TOKEN_FAILURE';

export const INVALID_CREDS = 'IAMAU5707';
export const DISABLED_CREDS = 'WI0600012';
export const NO_USER = 'WI0350002';
export const DO_NOT_MEET_SECURITY_STANDARD = 'WI0350011';
export const ORG_NAME = 'FIDELITY-WI-IAM';
export const ACCOUNT_LOCKED_ANALYTICS = 'login:account locked';
export const ACCOUNT_DISABLED_ANALYTICS = 'login:account disabled';
export const CHANGE_USER_NAME_POPUP_ANALYTICS = 'login:change username hint';
export const CHANGE_USER_NAME_ANALYTICS = 'login:change username & password';
export const CREATE_OTP_ANALYTICS = 'login:create otp';
export const LOGIN_OTP_ANALYTICS = 'login:otp';
export const CSQ_ANALYTICS = 'login:create security questions';
export const TC_ANALYTICS = 'login:terms & conditions';
export const ADMIN_USER = 'admin';
export const MEMBER_USER = 'member';
export const CSQA_ANALYTICS = 'security questions';
export const OTP_ANALYTICS = 'otp';
export const LOGIN_ANALYTICS_ACTION = 'wi.login';
export const ACCOUNT_WEAK_PASSWORD_ANALYTICS = 'login: account weak password';

export const TOKEN_EXPIRED = 'WI0300003';
export const TOKEN_EXPIRED_ANALYTICS = 'token expired';
export const TOKEN_EXPIRED_POPUP = 'tokenExpiredPopup';
export const RESEND_LIMIT_REACH = 'WI0150006';

export const MOBILE_DISABLED_CODE = 'CUST007';
export const MOBILE_DISABLED_USER = 'mobileRestrictedPopup';
export const MOBILE_DISABLED_USER_ANALYTICS = 'login:mobile: disabled user';
export const OTP_VERIFY_ANALYTICS_ACTION = 'wi.otp.verify';
export const GOOD_ENOUGH = 4;
export const PASSWORD_STRENGTH_REGEX = [/[A-Z]/, /[a-z]/, /[0-9]/, /[!£$*=[\]{}@#?-]/];
export const MIN_CHARACTER = 8;
export const ANALYTICS_USER_NAME = 'enter username';
export const RECOVERY_ANALYTICS_EVENT = 'wi.recovery';
export const MOBILE_CHANNEL = 'M';
export const WEB_CHANNEL = 'W';
export const GHP_URL =
  'https://cgsfgs-euw1.wsdp-dit.aws-nonprod.fmrcloud.com/wiglobal/globalnav/navigation/homepage';
