/**
 *
 * OtpSetUp
 *
 */

// @flow
import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';

import { validateLoggedUser } from '../../templates/LoginPage/LoginPage.actions';
import { closeCurrentElements } from '../../../global/actions';
import {
  getCountryCodesAction,
  initialActions,
  getOtpInfoAction,
  verifyEmailAddressAction,
  verifyMobileNumberAction,
  closeOtpVerifyPopUp,
  validateOtpAction,
  tokenAuthenticate,
  reSendOtpAction,
  openRememberDevicePopUpAction,
} from './OtpSetUp.actions';
import reducer from './OtpSetUp.reducer';
import saga from './OtpSetUp.saga';
import OtpSetup from '../../../components/organisms/OtpSetUp';
import type { State } from './types';

export const mapStateToProps = (state: State) => ({
  setToken: get(state, 'global.loginData.token', ''),
  otpSetUpLabels: get(state, 'global.globalData.labels.otpSetUpLabels', ''),
  countryCodes: get(state, 'otpSetUp.countryCodesReducer.countryCodes'),
  isGetCountryCodeCalling: get(state, 'otpSetUp.countryCodesReducer.isFetching'),
  popUpVisible: get(state, 'otpSetUp.verifyInputFieldReducer.popUpVisible'),
  prepopulatedEmail:
    get(state, 'LoginPage.validateLoggedUser.error.response.data.emailAddressVerified', '') ||
    get(state, 'LoginPage.keepUsername.error.response.data.emailAddressVerified', ''),
  prepopulatedMobile:
    get(state, 'LoginPage.validateLoggedUser.error.response.data.mobileNoVerified', '') ||
    get(state, 'LoginPage.keepUsername.error.response.data.mobileNoVerified', ''),
  mobileCountry:
    get(state, 'LoginPage.validateLoggedUser.error.response.data.mobileCountry', '') ||
    get(state, 'LoginPage.keepUsername.error.response.data.mobileCountry', ''),
  showPopUpType: get(state, 'otpSetUp.verifyInputFieldReducer.showPopUpType'),
  isMobileNumberVerified:
    get(state, 'otpSetUp.verifyInputFieldReducer.isMobileNumberVerified') ||
    get(state, 'LoginPage.validateLoggedUser.error.response.data.mv'),
  isEmailVerified: get(state, 'otpSetUp.verifyInputFieldReducer.isEmailVerified'),
  otpToken: get(state, 'otpSetUp.verifyInputFieldReducer.data.token'),
  isFetching:
    get(state, 'otpSetUp.verifyInputFieldReducer.isFetching') ||
    get(state, 'LoginPage.validateLoggedUser.isFetching') ||
    get(state, 'LoginPage.memberInfo.isFetching') ||
    get(state, 'LoginPage.keepUsername.isFetching'),
  isComplete: get(state, 'otpSetUp.authenticateToken.isComplete'),
  rememberDeviceLabels: get(state, 'global.globalData.labels.rememberDevicePopup', ''),
  error: get(state, 'otpSetUp.verifyInputFieldReducer.error'),
  isError: get(state, 'otpSetUp.verifyInputFieldReducer.isError'),
  mobileError: get(state, 'otpSetUp.verifyInputFieldReducer.mobileError'),
  emailError: get(state, 'otpSetUp.verifyInputFieldReducer.emailError'),
  isResend: get(state, 'otpSetUp.verifyInputFieldReducer.isResend'),
  isAuthenticateCalling: get(state, 'otpSetUp.authenticateToken.isFetching'),
  migrationStatus: get(state, 'LoginPage.memberInfo.migrationStatus'),
  isDeviceReg: get(state, 'LoginPage.isDeviceReg.isReg'),
});

export const mapDispatchToProps = {
  getCountryCodes: getCountryCodesAction,
  getOtpInfo: getOtpInfoAction,
  verifyMobileAction: verifyMobileNumberAction,
  verifyEmailAction: verifyEmailAddressAction,
  handleVerifyPopUp: closeOtpVerifyPopUp,
  closeCurrentElement: closeCurrentElements,
  validateOtpAction,
  tokenAuthenticate,
  submitAuthenticate: validateLoggedUser,
  reSendOtpAction,
  openRememberDevicePopUpAction,
};

export default clientEnhance(OtpSetup, {
  key: 'otpSetUp',
  saga,
  reducer,
  initialActions,
  mapStateToProps,
  mapDispatchToProps,
});
