/*
 *
 * ResetPassword constants
 *
 */

// @flow

export const RESET_PASSWORD = '@FIL/PVLOGIN/RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = '@FIL/PVLOGIN/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = '@FIL/PVLOGIN/RESET_PASSWORD_FAILURE';
export const ANALYTICS_RESET_PASSWORD_CONFIRM = 'confirmation';
export const PASWORD_ALREADY_TAKEN = 'WI0600009';
export const WRONG_CURRENT_PASSWORD = 'IAMAU5707';
