// @flow
import { takeLatest, call, put, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';

import {
  RESET_PASSWORD,
  ANALYTICS_RESET_PASSWORD_CONFIRM,
  PASWORD_ALREADY_TAKEN,
  WRONG_CURRENT_PASSWORD,
} from './ResetPassword.constants';

import { SERVICE_ERROR, RESET_COMPLETE_PAGE } from '../../../global/constants';
import { showServiceError, setToken, setActivePage } from '../../../global/actions';
import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';

import { resetPasswordSuccess, resetPasswordFailure } from './ResetPassword.actions';
import encrypt from '../../../utils/passwordEncryption';
import type { Action } from './types';
import { ADMIN_USER } from '../../templates/LoginPage/LoginPage.constants';

export function* resetPassword(action: Action): Saga<void> {
  try {
    const password = get(action, 'payload.password', '').trim();
    const store = yield select();
    const token = get(store, 'global.loginData.token');
    const userType = get(store, 'LoginPage.user.type');
    let url = apiEndPoints.resetPassword;
    let payload;
    if (userType === ADMIN_USER) {
      const currentPassword = get(action, 'payload.currentPassword', '').trim();
      const [adminPassword, currentPasswordEncrypted] = yield call(encrypt, [
        password,
        currentPassword,
      ]);
      payload = {
        token,
        adminPassword,
        currentPassword: currentPasswordEncrypted,
      };
      url = apiEndPoints.resetAdminPassword;
    } else {
      const [newpassword] = yield call(encrypt, [password]);
      payload = { token, newpassword };
    }
    const { data } = yield call(axiosServiceClient.post, url, payload);
    const currentPage = {
      isPageChanged: true,
      newActivePage: RESET_COMPLETE_PAGE,
    };
    yield put(setToken(data.token));
    yield put(resetPasswordSuccess());

    yield put(
      setActivePage(
        currentPage,
        ANALYTICS_RESET_PASSWORD_CONFIRM,
        SITE_SECTION_RESET,
        CHANNEL_ACRONYM_RESET
      )
    );
  } catch (e) {
    yield put(resetPasswordFailure());
    const store = yield select();
    const passwordAlreadyTaken = get(
      store,
      'global.globalData.labels.resetPasswordLabels.validationErrors.password.passwordAlreadyTaken'
    );
    const wrongCurrentPassword = get(
      store,
      'global.globalData.labels.resetPasswordLabels.validationErrors.password.wrongCurrentPassword'
    );
    if (e.response.data.code === PASWORD_ALREADY_TAKEN) {
      action.setErrors({
        password: passwordAlreadyTaken,
      });
    } else if (e.response.data.code === WRONG_CURRENT_PASSWORD) {
      action.setErrors({
        currentPassword: wrongCurrentPassword,
      });
    } else {
      yield put(showServiceError(SERVICE_ERROR));
    }
  }
}

export default function* resetPasswordSaga(): Saga<void> {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
