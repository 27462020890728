/*
 *
 * AnswerSecurityQuestions constants
 *
 */

// @flow

export const ANSWER_SECURITY_QUESTION = '@FIL/PVLOGIN/ANSWER_SECURITY_QUESTION';
export const ANSWER_SECURITY_QUESTION_SUCCESS = '@FIL/PVLOGIN/ANSWER_SECURITY_QUESTION_SUCCESS';
export const ANSWER_SECURITY_QUESTION_FAILURE = '@FIL/PVLOGIN/ANSWER_SECURITY_QUESTION_FAILURE';
export const RESET_PASSWORD_DISABLE = 'resetPasswordDisabled';
export const RESET_USERNAME_DISABLE = 'cantRetrieveUser';
export const SECQ_AVAILABLE = 'WI0600120';
export const NO_SECQ_AVAILABLE = 'WI0600116';
export const SEND_OTP = 'WI0600123';
export const ANALYTICS_CHOOSE_NEW_PASSWORD = 'choose new password';
