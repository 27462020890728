import { browserName, browserVersion, isMobile } from 'react-device-detect';

export const checkifUnsupportedBrowser = (clientDevice, supportedBrowserList) => {
  let isUnSupported = false;
  supportedBrowserList.forEach((element) => {
    if (
      element.browserType === clientDevice.browserType &&
      element.browserVersion > clientDevice.browserVersion &&
      element.deviceType === clientDevice.deviceType
    ) {
      isUnSupported = true;
    }
  });
  return isUnSupported;
};
const isUnBrowserSupportedVersion = (supportedBrowserList) => {
  const clientDevice = {
    browserType: browserName,
    browserVersion: parseInt(browserVersion, 10),
    deviceType: isMobile ? 'Mobile' : 'Browser',
  };
  return checkifUnsupportedBrowser(clientDevice, supportedBrowserList);
};

export default isUnBrowserSupportedVersion;
