import { all, call, put, takeLatest } from 'redux-saga/effects';
import { logger, removeLocalStorage, getLocalStorage } from '@fil-global/eiswagen';
import RiskMinder from '@fil-global/fil-lib-riskminder';
import {
  setTheosHeaders,
  deleteAllTheosData,
  getTheosCommonHeaders,
  setTheosSidCookie,
  setTheosFidCookie,
} from '@fil-ukdeploy/theos-headers-ria';
import get from 'lodash/get';
import Sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import axiosClient, {
  axiosServiceClient,
  axiosClientWithCredentials,
} from '../config/axios-client';
import {
  FETCH_LABEL,
  FETCH_LABEL_SUCCESS,
  FETCH_LABEL_FAILURE,
  LOG_OUT,
  RESET_PASSWORD_JOURNEY,
  JOURNEY,
  ACTIVE_PAGE,
  USER_STATUS,
  FORGOT_LOGIN_DETAILS,
  RETRIEVE_USERNAME_JOURNEY,
  RETRIEVE_USERNAME_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  SERVICE_ERROR,
  THEOS_PV_CHANNEL,
  SETUP_SECURITY_QUESTIONS,
  OTP_SECURITY_QA_V2,
} from './constants';
import constants from '../constants/api/services';
import {
  RETRIEVE_USER_NAME,
  SET_PI_DETAILS,
} from '../containers/organisms/RetrieveUserName/RetrieveUserName.constants';
import {
  ANALYTICS_USER_NAME,
  LOADER,
  WEB_CHANNEL,
} from '../containers/templates/LoginPage/LoginPage.constants';

import { proceedToSilentLogin } from '../containers/templates/LoginPage/LoginPage.actions';
import { CHANNEL_ACRONYM_RESET, SITE_SECTION_RESET } from './AnalyticsTracking/constants';
import { retrieveUserNameSuccess } from '../containers/organisms/RetrieveUserName/RetrieveUserName.actions';
import getURLPathParams from '../utils/getURLPathParams/getURLPathParams';
import getURLParameter from '../utils/getURLParameter';
import postMessage from '../utils/postMessage';
import { showServiceError } from './actions';

export function clearLocalStorage() {
  removeLocalStorage('local-storage-user-status');
}

export function* redirectToResetPage() {
  yield put({
    type: JOURNEY,
    currentJourney: RESET_PASSWORD_JOURNEY,
    data: {
      pageName: ANALYTICS_USER_NAME,
      siteSection: SITE_SECTION_RESET,
      channelAcronym: CHANNEL_ACRONYM_RESET,
    },
  });

  yield put({
    type: USER_STATUS,
    userStatus: '',
  });

  yield put({
    type: ACTIVE_PAGE,
    activePage: {
      isPageChanged: true,
      newActivePage: FORGOT_LOGIN_DETAILS,
    },
    data: {
      pageName: ANALYTICS_USER_NAME,
      siteSection: SITE_SECTION_RESET,
      channelAcronym: CHANNEL_ACRONYM_RESET,
    },
  });
}

export function* redirectToShowUserNamePage() {
  try {
    if (getLocalStorage('preAuthToken')) {
      const mfp = JSON.stringify(RiskMinder.getMFPasJSON());
      const tokenID = Base64.stringify(Sha256(mfp));
      const { token } = getLocalStorage('preAuthToken');
      removeLocalStorage('preAuthToken');
      const config = {
        headers: { token, tokenID },
      };
      if (token) {
        yield put({
          type: JOURNEY,
          currentJourney: RETRIEVE_USERNAME_JOURNEY,
        });

        yield put({
          type: USER_STATUS,
          userStatus: '',
        });
        yield put({
          type: LOADER,
          active: true,
        });
        const { data } = yield call(axiosServiceClient.post, constants.decodeToken, {}, config);
        delete data.tokenID;
        yield put({ type: SET_PI_DETAILS, piDetails: data });
        yield put({
          type: ACTIVE_PAGE,
          activePage: {
            isPageChanged: true,
            newActivePage: RETRIEVE_USERNAME_PAGE,
          },
        });
        yield put({
          type: RETRIEVE_USER_NAME,
          formData: data,
        });
      }
    } else {
      yield put({
        type: JOURNEY,
        currentJourney: RETRIEVE_USERNAME_JOURNEY,
      });

      yield put({
        type: USER_STATUS,
        userStatus: '',
      });

      yield put({
        type: ACTIVE_PAGE,
        activePage: {
          isPageChanged: true,
          newActivePage: RETRIEVE_USERNAME_PAGE,
        },
      });
    }
  } catch (e) {
    yield put({
      type: LOADER,
      active: false,
    });
    logger.warn('Failed to redirect show username: ', e);
  }
}

export function* redirectToActivePage(data = {}) {
  if (data && !data.securityQuestionsAnswered) {
    postMessage('mobile_app_has_not_agreed_to_the_latest_tcs');

    yield put({
      type: ACTIVE_PAGE,
      activePage: {
        isPageChanged: true,
        newActivePage: SETUP_SECURITY_QUESTIONS,
        TncVersion: data.TncVersion,
      },
    });
  } else if (data && data.TncVersion) {
    postMessage('mobile_app_has_not_agreed_to_the_latest_tcs');
    yield put({
      type: ACTIVE_PAGE,
      activePage: {
        isPageChanged: true,
        newActivePage: TERMS_AND_CONDITIONS_PAGE,
        TncVersion: data.TncVersion,
      },
    });
  } else {
    // security questions are already set and TNC not required

    postMessage('mobile_app_has_agreed_to_the_latest_tcs');
    postMessage('mobile_app_has_set_up_security_questions');

    yield put(proceedToSilentLogin());
  }
}

export function* redirectToAnswerSecurityQuestions() {
  yield put({
    type: ACTIVE_PAGE,
    activePage: {
      isPageChanged: true,
      newActivePage: OTP_SECURITY_QA_V2,
    },
  });
}

export function* doPostAuthChecks(retryApplicable, retry) {
  retry = retry || 0; //eslint-disable-line
  try {
    const payLoad = {
      loginChannel: WEB_CHANNEL,
    };

    yield put({
      type: LOADER,
      active: true,
      hide: true,
    });
    const { data } = yield call(
      axiosServiceClient.post,
      constants.validateSecurityQuestions,
      payLoad,
      {
        timeout: 3000,
      }
    );
    yield call(redirectToActivePage, data);
  } catch (error) {
    const { message } = error || {};
    if (
      (message === 'Network Error' || message.includes('timeout')) &&
      retryApplicable &&
      retry < 2
    ) {
      yield call(doPostAuthChecks, true, retry + 1);
      logger.warn('Failed to redirect tnc & SQ: ', error);
    } else {
      window.location = '/newlogin';
      yield put({
        type: LOADER,
        active: false,
      });
      logger.warn('Failed to redirect tnc & SQ: ', error);
    }
  }
}

export const setSidFidCookie = (data) => {
  let allTheosHeaders = '';
  try {
    allTheosHeaders = getTheosCommonHeaders({
      channel: THEOS_PV_CHANNEL,
      domain: '.fidelity.co.uk',
    });
  } catch (e) {
    logger.warn('setTheosHeaders: ', e);
  }
  const theosSid = get(allTheosHeaders, 'sessionId');
  setTheosSidCookie({
    channel: THEOS_PV_CHANNEL,
    sid: `${theosSid}_${get(data, 'tid')}`,
    domain: '.fidelity.co.uk',
  });
  setTheosFidCookie({
    channel: THEOS_PV_CHANNEL,
    fid: get(data, 'fid'),
    domain: '.fidelity.co.uk',
  });
  logger.info('Successfully set Sid & Fid: ');
};

export function* retrieveMigratedUserName() {
  try {
    yield put({
      type: LOADER,
      active: true,
    });

    const param = {
      REF: getURLParameter('REF'),
    };
    window.history.pushState({}, '', '/newlogin/#/');

    const { data } = yield call(axiosServiceClient.get, constants.referenceDetails, {
      baseURL: process.env.REACT_APP_PINGONE_API_HOST,
      params: param,
    });
    setSidFidCookie(data);

    yield put(retrieveUserNameSuccess(get(data, 'username'), true));

    yield put({
      type: LOADER,
      active: false,
    });
  } catch (e) {
    yield put(showServiceError(SERVICE_ERROR));
    yield put({
      type: LOADER,
      active: false,
      hide: true,
    });
    logger.warn('Failed to fetch username: ', e);
  }
}

export const setTheosHeader = (deleteTheosData, setHeader) => {
  try {
    deleteTheosData({ channel: THEOS_PV_CHANNEL });
    deleteTheosData({ channel: THEOS_PV_CHANNEL, domain: '.fidelity.co.uk' });

    setHeader({ channel: THEOS_PV_CHANNEL, domain: '.fidelity.co.uk' });
  } catch (e) {
    logger.warn('setTheosHeaders: ', e);
  }
};

export function* loginHandlerInvoke(targetVal = '/newlogin/#/targetPage/tnc', error) {
  try {
    const targetToRedirect =
      targetVal.split('$').length === 3 ? targetVal.split('$')[2] : targetVal;
    yield put({
      type: LOADER,
      active: true,
      hide: true,
    });

    const param = {
      journey: 'Login',
      channel: 'WI',
      ...(error && { error }),
    };
    const { data } = yield call(axiosServiceClient.get, constants.loginHandler, {
      baseURL: process.env.REACT_APP_PINGONE_API_HOST,
      params: param,
      headers: {
        Target: targetToRedirect,
      },
    });
    window.location = get(data, 'pingLoginUrl');
    logger.info('Successfully to fetch ping URL: ');
  } catch (e) {
    yield put(showServiceError(SERVICE_ERROR));
    yield put({
      type: LOADER,
      active: false,
      hide: true,
    });
    logger.warn('Failed to fetch ping URL: ', e);
  }
}

export function* authHandlerInvoke(authCodeVal, stateVal) {
  try {
    yield put({
      type: LOADER,
      active: true,
      hide: true,
    });
    const param = {
      code: authCodeVal,
      state: stateVal,
    };
    const { data } = yield call(axiosServiceClient.get, constants.authHandler, {
      baseURL: process.env.REACT_APP_PINGONE_API_HOST,
      params: param,
    });

    const urlTarget = encodeURIComponent(get(data, 'target'));
    window.location = `${process.env.REACT_APP_PINGONE_SESSION_API_HOST}/gateway/session/v1/ping?TARGET=${urlTarget}`;
    logger.info('Successfully to fetch  URL AUT: ');
  } catch (e) {
    const statusCode = get(e, 'response.data.statusCode');
    const errorMessage = get(e, 'response.data.errorMessage');
    const status = get(e, 'response.status');

    if (
      (status === 500 && statusCode === 500 && errorMessage === 'state expired') ||
      errorMessage === 'state missing'
    ) {
      yield call(loginHandlerInvoke);
    } else {
      yield put(showServiceError(SERVICE_ERROR));
      yield put({
        type: LOADER,
        active: false,
        hide: true,
      });
      logger.warn('Exception  in authHandler: ', e);
    }
  }
}

export function* redirectToPathUrl() {
  try {
    const redirectToPath = getURLPathParams();

    if (redirectToPath.size > 0) {
      if (redirectToPath.get('targetPage') === 'reset') {
        yield call(redirectToResetPage);
      } else if (redirectToPath.get('targetPage') === 'retrieveUserName') {
        yield call(redirectToShowUserNamePage);
      } else if (redirectToPath.get('targetPage') === 'tnc') {
        yield call(doPostAuthChecks, true);
      }
    } else if (getURLParameter('wi_target') === 'verifySecuirtyQuestions') {
      yield call(redirectToAnswerSecurityQuestions);
    }
  } catch (e) {
    logger.warn('Failed to redirect: ', e);
  }
}

export function* redirectTopPing() {
  try {
    const authCode = getURLParameter('code');
    const state = getURLParameter('state');
    let target = getURLParameter('TARGET');
    if (!target) {
      target = '/newlogin/#/targetPage/tnc';
    }
    const routingLogic = {
      loginHandler: !authCode && !getURLParameter('REF'),
      authHandler: authCode && state && !getURLParameter('error') && !getURLParameter('REF'),
    };

    if (routingLogic.loginHandler) {
      setTheosHeader(deleteAllTheosData, setTheosHeaders);
      yield call(loginHandlerInvoke, target, getURLParameter('error') || null);
    } else if (routingLogic.authHandler) {
      yield call(authHandlerInvoke, authCode, state);
    }
  } catch (e) {
    yield put(showServiceError(SERVICE_ERROR));
    logger.warn('Failed to redirect to ping: ', e);
  }
}

export function* fetchLabels() {
  try {
    const redirectToPath = getURLPathParams();
    const pingToggle = process.env.REACT_APP_PING_TOGGLE;

    if (
      pingToggle !== 'on' ||
      getURLParameter('REF') ||
      redirectToPath.get('targetPage') === 'tnc' ||
      redirectToPath.get('targetPage') === 'retrieveUserName' ||
      getURLParameter('wi_target') === 'verifySecuirtyQuestions' ||
      redirectToPath.get('targetPage') === 'reset'
    ) {
      if (getURLParameter('REF') && getURLParameter('wi_target') !== 'verifySecuirtyQuestions') {
        setTheosHeaders({ channel: THEOS_PV_CHANNEL, domain: '.fidelity.co.uk' });
        yield call(retrieveMigratedUserName);
      } else {
        setTheosHeader(deleteAllTheosData, setTheosHeaders);
      }
      const { data } = yield call(axiosClient.get, constants.labels, {
        baseURL: process.env.REACT_APP_CONTENT_HOST,
      });
      yield put({ type: FETCH_LABEL_SUCCESS, labels: data });

      yield call(redirectToPathUrl);
    } else {
      const { data } = yield call(axiosClient.get, constants.labels, {
        baseURL: process.env.REACT_APP_CONTENT_HOST,
      });
      yield put({ type: FETCH_LABEL_SUCCESS, labels: data });
      yield call(redirectTopPing);
    }
  } catch (err) {
    yield put({ type: FETCH_LABEL_FAILURE });
  }
}

export function* logOut() {
  try {
    const redirectToPath = getURLPathParams();
    if (redirectToPath.get('targetPage') !== 'tnc') {
      clearLocalStorage();
      yield all([
        call(axiosServiceClient.get, constants.logout),
        call(axiosClient.post, constants.logoutPlanviewer),
        call(axiosClientWithCredentials.get, process.env.REACT_APP_FMR_LOGOUT),
      ]);
    }
  } catch (e) {
    logger.warn('Failed to logout: ', e);
  }
}

export default function* globalSaga() {
  yield all([takeLatest(FETCH_LABEL, fetchLabels), takeLatest(LOG_OUT, logOut)]);
}
