/*
 *
 * OtpSetUp constants
 *
 */

const OTP_SETUP_CONST = Object.freeze({
  MOBILE_NUMBER_REGEX: /^[0-9]{8,15}$|^$/,
  EMAIL_ADDRESS_FRMK: /^[A-Z0-9'._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  DISCARD_LEADING_ZERO_REGEX: /^0+/,
  PERSONAL_EMAIL_ADDRESS: 'personalEmailAddress',
  MOBILE_NUMBER: 'mobilePhoneNumber',
  COUNTRY_CODE: 'countryCode',
  CHANNEL_EMAIL: 'email',
  CHANNEL_MOBILE: 'mobile',
  DEFAULT_COUNTRY_CODE: 'GB',
  ORG_NAME: 'FIDELITY-WI-IAM',
  MOBILE_SERVICE_FAILED: 'WI0550002',
  VELOCITY_COUNT_REACHED: 'WI0150006',
  STATUS_L: 'L',
});

export default OTP_SETUP_CONST;
