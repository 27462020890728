/** * * ResetPassword * */

// @flow

import { clientEnhance } from '@fil-global/eiswagen';

import saga from './ResetPassword.saga';
import {
  selectResetPasswordLabels,
  selectServiceErrors,
  selectIsFetching,
  selectHasServiceError,
  selectUserType,
} from './ResetPassword.selectors';
import resetPassword from './ResetPassword.actions';
import ResetPassword from '../../../components/organisms/ResetPassword';

export const mapStateToProps = (state: { [string]: string }) => ({
  labels: selectResetPasswordLabels(state),
  serviceError: selectServiceErrors(state),
  isFetching: selectIsFetching(state),
  hasError: selectHasServiceError(state),
  userType: selectUserType(state),
});

export const mapDispatchToProps = (dispatch: any) => ({
  resetPassword: (payload: { [string]: string }, setErrors: () => void) =>
    dispatch(resetPassword(payload, setErrors)),
});

export default clientEnhance(ResetPassword, {
  key: 'resetPassword',
  saga,
  mapStateToProps,
  mapDispatchToProps,
});
