/**
 *
 * AnswerSecurityQuestions
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import memoizeone from 'memoize-one';
import uniq from 'lodash/uniq';

import {
  GridContainer,
  Grid,
  Cell,
  Button,
  TextField,
  Form,
  withField,
  getLabel,
  Notification,
  Spinner,
  UnsafeHtml,
} from '@fil-global/eiswurfel';
import Popup from '../../molecules/Popup';

import type { Props } from './types';

import { ANSWER_REGEX, RESEND_LIMIT_REACH } from './AnswerSecurityQuestionsV2.constants';

const TextFieldItem = withField(TextField);
class AnswerSecurityQuestions extends PureComponent<Props> {
  static defaultProps = {};

  onPopupClose = (): void => {
    setTimeout(() => {
      /* istanbul ignore next */
      window.location = '/newlogin';
    }, 0);
  };

  getInitialValues = memoizeone((securityQuestions) => {
    const initialValues = {};
    securityQuestions.map((question) => {
      initialValues[`ans-${question.Id}`] = '';
      return null;
    });
    return initialValues;
  });

  validate = (values: { [string]: string }) => {
    const { labels } = this.props;
    const keys = Object.keys(values);
    const errors = {};

    /* eslint-disable */

    const isFirstAnsValid = ANSWER_REGEX.test(values[keys[0]]);
    const isSecondAnsValid = ANSWER_REGEX.test(values[keys[1]]);

    if (!values[keys[0]]) {
      errors[keys[0]] = getLabel(labels, 'errorMessage.required');
    }

    if (!values[keys[1]]) {
      errors[keys[1]] = getLabel(labels, 'errorMessage.required');
    }

    if (values[keys[0]] && !isFirstAnsValid) {
      errors[keys[0]] = getLabel(labels, 'errorMessage.invalid');
    }

    if (values[keys[1]] && !isSecondAnsValid) {
      errors[keys[1]] = getLabel(labels, 'errorMessage.invalid');
    }

    if (
      values[keys[0]] &&
      values[keys[1]] &&
      Object.values(values).length !== uniq(Object.values(values)).length
    ) {
      errors[keys[1]] = getLabel(labels, 'errorMessage.unique.invalid');
    }

    /* eslint-enable */
    return errors;
  };

  renderOtpNotifications = (): void => {
    const { labels, errorCode } = this.props;
    switch (errorCode) {
      case RESEND_LIMIT_REACH:
        return (
          <div className="notification error">
            <span className="icon fil-icon fil-icon-error " />
            <p>{getLabel(labels, 'resendLimitCount')}</p>
          </div>
        );

      default:
        return null;
    }
  };

  render(): Node {
    const {
      isFetching,
      serviceError,
      clearError,
      labels,
      activePopup,
      hasError,
      securityQuestions,
      answerSecurityQuestions,
    } = this.props;

    if (isFetching) {
      return <Spinner isVisible displayType="fixed" size="large" />;
    }

    return (
      <GridContainer variation=" ">
        {activePopup && activePopup.isShowPopup ? (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            closeModal={this.onPopupClose}
            showModal
          />
        ) : null}

        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {hasError && (
                    <Cell>
                      <Notification {...serviceError} onClose={clearError} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                    <p className="text-center">
                      <UnsafeHtml>{getLabel(labels, 'subtitle')}</UnsafeHtml>
                    </p>
                  </Cell>
                  <Cell>
                    <Form
                      handleSubmit={answerSecurityQuestions}
                      initialValues={this.getInitialValues(securityQuestions)}
                      formWrapperProps={{ enableReinitialize: true }}
                      validate={this.validate}
                      validateOnChange={false}
                      validateOnBlur={false}
                      formRenderFunc={() => (
                        <>
                          {securityQuestions.map((question, key) => (
                            <div id={`questions-${key + 1}`} key={`questions-${key + 1}`}>
                              <TextFieldItem
                                name={`ans-${question.Id}`}
                                id={`ans-${question.Id}`}
                                label={question.Question}
                                maxLength="45"
                              />
                            </div>
                          ))}

                          {securityQuestions.length > 0 && (
                            <div className="text-center">
                              <Button type="submit" extraClasses="primary-cta expanded">
                                {getLabel(labels, 'cta')}
                              </Button>
                              {this.renderOtpNotifications()}
                            </div>
                          )}
                        </>
                      )}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default AnswerSecurityQuestions;
