/*
 *
 * OtpSetup actions
 *
 */
// @flow

import OTP_SETUP_CONST from './OtpSetUp.constants';
import { getMemberStatusAction } from '../../templates/LoginPage/LoginPage.actions';
import type {
  DefaultActionType,
  DefaultErrorActionType,
  CountryObjectType,
  FetchOtpType,
  SendOtpPayloadType,
  DialCodesReturnType,
  FetchOtpReturnType,
  VerifyActionReturnType,
  validatePayloadType,
  SendOtpSucess,
  AuthenticatePayloadType,
  validateOtpData,
  IsVerifiedTYpe,
} from './types';

export const getOtpInfoAction = (): DefaultActionType => ({ type: OTP_SETUP_CONST.GET_OTP_INFO });
export const getOtpInfoSuccess = (data: FetchOtpType): FetchOtpReturnType => ({
  type: OTP_SETUP_CONST.GET_OTP_INFO_SUCCESS,
  data,
});
export const getOtpInfoFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.GET_OTP_INFO_FAILURE,
  err,
});

export const getCountryCodesAction = (): DefaultActionType => ({
  type: OTP_SETUP_CONST.GET_COUNTRY_CODES,
});
export const getCountryCodesSuccess = (data: CountryObjectType): DialCodesReturnType => ({
  type: OTP_SETUP_CONST.GET_COUNTRY_CODES_SUCCESS,
  data,
});
export const getCountryCodesFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.GET_COUNTRY_CODES_FAILURE,
  err,
});

export const verifyEmailAddressAction = (
  verifyActionPayLoad: SendOtpPayloadType
): VerifyActionReturnType => ({
  type: OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS,
  verifyActionPayLoad,
});
export const verifyEmailAddressSuccess = (data: SendOtpSucess) => ({
  type: OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS_SUCCESS,
  data,
});
export const verifyEmailAddressFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.VERIFY_EMAIL_ADDRESS_FAILURE,
  err,
});

export const verifyMobileNumberAction = (
  verifyActionPayLoad: SendOtpPayloadType
): VerifyActionReturnType => ({
  type: OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER,
  verifyActionPayLoad,
});
export const verifyMobileNumberSuccess = (data: SendOtpSucess) => ({
  type: OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER_SUCCESS,
  data,
});
export const verifyMobileNumberFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.VERIFY_MOBILE_NUMBER_FAILURE,
  err,
});

export const closeOtpVerifyPopUp = (): DefaultActionType => ({
  type: OTP_SETUP_CONST.CLOSE_OTP_POPUP,
});

export const validateOtpAction = (
  validateOtpActionPayLoad: validatePayloadType,
  channel: string
) => ({
  type: OTP_SETUP_CONST.VALIDATE_OTP,
  validateOtpActionPayLoad,
  channel,
});

export const validateOtpSuccess = (data: validateOtpData, isVerified: IsVerifiedTYpe) => ({
  type: OTP_SETUP_CONST.VALIDATE_OTP_SUCCESS,
  data,
  isVerified,
});

export const validateOtpFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.VALIDATE_OTP_FAILURE,
  err,
});

export const fetchChallengeDetails = () => ({ type: OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS });
export const fetchChallengeSuccess = (data: { [string]: string }) => ({
  type: OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS_SUCCESS,
  data,
});
export const fetchChallengeFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.FETCH_CHALLENGE_DETAILS_FAILURE,
  err,
});

export const tokenAuthenticate = (payload: AuthenticatePayloadType) => ({
  type: OTP_SETUP_CONST.TOKEN_AUTHENTICATE,
  payload,
});
export const tokenAuthenticateSuccess = (data: { [string]: string }) => ({
  type: OTP_SETUP_CONST.TOKEN_AUTHENTICATE_SUCCESS,
  data,
});
export const tokenAuthenticateFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.TOKEN_AUTHENTICATE_FAILURE,
  err,
});

export const reSendOtpAction = (payload: { token: string }) => ({
  type: OTP_SETUP_CONST.RESEND_OTP,
  payload,
});
export const reSendOtpActionSuccess = (data: { [string]: string }) => ({
  type: OTP_SETUP_CONST.RESEND_OTP_SUCCESS,
  data,
});

export const reSendOtpActionFailure = (err: ?string): DefaultErrorActionType => ({
  type: OTP_SETUP_CONST.RESEND_OTP_FAILURE,
  err,
});
export const openRememberDevicePopUpAction = (): DefaultActionType => ({
  type: OTP_SETUP_CONST.OPEN_REMEMBER_DEVICE_POPUP,
  data: { pageName: OTP_SETUP_CONST.REMEMBER_DEVICE_ANALYTICS },
});

export const initialActions = [
  getOtpInfoAction,
  getCountryCodesAction,
  getMemberStatusAction(null),
];
