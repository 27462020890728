import { clientEnhance } from '@fil-global/eiswagen';

import { selectResetPassworCopletedLabels } from './ResetPasswordComplete.selectors';

import ResetPasswordCompleteComponent from '../../../components/organisms/LoginComplete';

export const mapStateToProps = (state) => ({
  labels: selectResetPassworCopletedLabels(state),
  isReset: true,
});
export const mapDispatchToProps = {};

export default clientEnhance(ResetPasswordCompleteComponent, {
  key: 'ResetPasswordComplete',
  mapStateToProps,
  mapDispatchToProps,
});
