import { clientEnhance } from '@fil-global/eiswagen';
import get from 'lodash/get';
import reducer from './ChangeUsernamePassword.reducer';
import saga from './ChangeUsernamePassword.saga';
import { changeUsernamePwd, clearError } from './ChangeUsernamePassword.actions';
import ChangeUsernamePasswordComponent from '../../../components/organisms/ChangeUsernamePassword';

export const mapStateToProps = (state) => ({
  isFetching: get(state, 'ChangeUsernamePassword.isFetching'),
  serviceError: get(state, 'global.globalData.labels.serviceErrors', ''),
  labels: get(state, 'global.globalData.labels.changeUsernamePasswordLabels', ''),
  hasError: get(state, ['global', 'loginData', 'serviceError']),
  userType: get(state, ['LoginPage', 'user', 'type']),
});

export const mapDispatchToProps = {
  changeUsernamePwd,
  clearError,
};

export default clientEnhance(ChangeUsernamePasswordComponent, {
  key: 'ChangeUsernamePassword',
  reducer,
  saga,
  mapStateToProps,
  mapDispatchToProps,
});
