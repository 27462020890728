import { clientEnhance } from '@fil-global/eiswagen';
import { verifyUsername } from './ForgotLoginDetails.actions';
import ForgotLoginDetailsComponent from '../../../components/organisms/ForgotLoginDetails';
import {
  closeCurrentElements,
  setActivePage,
  setCurrentJourney,
  setUserStatus,
  setActivePopup,
} from '../../../global/actions';
import { verifyRequired } from '../../templates/LoginPage/LoginPage.actions';

import {
  selectForgotLoginDetailLabels,
  selectActivePopup,
  selectIsFetching,
  selectErrorCode,
  selectForgotLoginDetailError,
  selectUsername,
} from './ForgotLoginDetails.selectors';
import reducer from './ForgotLoginDetails.reducer';
import saga from './ForgotLoginDetails.saga';

const pingToggle = process.env.REACT_APP_PING_TOGGLE;
export const mapStateToProps = (state) => ({
  labels: selectForgotLoginDetailLabels(state),
  activePopup: selectActivePopup(state),
  isFetching: selectIsFetching(state),
  errorCode: selectErrorCode(state),
  forgotLoginDetailError: selectForgotLoginDetailError(state),
  username: selectUsername(state),
  pingToggle,
});
export const mapDispatchToProps = {
  verifyUser: verifyUsername,
  closeCurrentElement: closeCurrentElements,
  verifyRequired,
  setCurrentJourney,
  setActivePage,
  setUserStatus,
  setActivePopup,
};

export default clientEnhance(ForgotLoginDetailsComponent, {
  key: 'ForgotLoginDetails',
  reducer,
  saga,
  mapStateToProps,
  mapDispatchToProps,
});
