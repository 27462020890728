/**
 *
 * ResetPassword
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import memoizeone from 'memoize-one';
import get from 'lodash/get';

import {
  GridContainer,
  Grid,
  Cell,
  Button,
  TextField,
  Form,
  withField,
  getLabel,
  Notification,
  Spinner,
  PasswordStrengthMeter,
  Label,
} from '@fil-global/eiswurfel';

import type { Props, Error, Data, State } from './types';

import { PASSWORD_REGEX, GOOD_ENOUGH, PASSWORD_STRENGTH_REGEX } from './ResetPassword.constants';

import { ADMIN_USER } from '../../../containers/templates/LoginPage/LoginPage.constants';
import PasswordConditions from '../../molecules/PasswordConditions';

const TextFieldItem = withField(TextField);
class ResetPassword extends PureComponent<Props, State> {
  static defaultProps = {};

  initialValues = {
    password: '',
    renterPassword: '',
    currentPassword: '',
  };

  constructor(props) {
    super(props);
    this.state = { isSPwdStrong: false };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  componentWillUnmount = () => {
    window.scrollTo(0, 0);
  };

  setPwdStrength = (score: number) => {
    const isStrong = score >= GOOD_ENOUGH;
    const { isSPwdStrong } = this.state;
    if (isStrong !== isSPwdStrong) {
      this.setState({ isSPwdStrong: isStrong });
    }
  };

  getValidationSchema = memoizeone((labels) => {
    const validationSchema = {
      password: {
        required: getLabel(labels, 'validationErrors.password.required'),
        matches: [PASSWORD_REGEX, getLabel(labels, 'validationErrors.password.invalid')],
      },
      renterPassword: {
        required: getLabel(labels, 'validationErrors.reenterpassword.required'),
      },
    };
    return validationSchema;
  });

  validate = (values: Data): Error => {
    const errors = {};
    const { labels, userType } = this.props;

    if (values.password && values.renterPassword && values.password !== values.renterPassword) {
      errors.renterPassword = getLabel(labels, 'validationErrors.reenterpassword.match');
    }

    if (
      userType === ADMIN_USER &&
      values.password &&
      values.currentPassword &&
      values.password === values.currentPassword
    ) {
      errors.password = getLabel(labels, 'validationErrors.currentPassword.match');
    }

    return errors;
  };

  handleSubmit = (values, formikBag): void => {
    const { setErrors } = formikBag;
    const { resetPassword } = this.props;
    resetPassword(values, setErrors);
  };

  render(): Node {
    const { isFetching, serviceError, clearError, labels, hasError, userType } = this.props;
    const { isSPwdStrong } = this.state;
    const passwordConditionsLevel = getLabel(labels, 'passwordConditionsLevel');

    return (
      <GridContainer variation=" ">
        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container reset-password-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {hasError && (
                    <Cell>
                      <Notification {...serviceError} onClose={clearError} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                    <p className="text-center">{getLabel(labels, 'subtitle')}</p>
                  </Cell>
                  <Cell>
                    <Form
                      handleSubmit={this.handleSubmit}
                      validationSchema={this.getValidationSchema(labels)}
                      initialValues={this.initialValues}
                      validate={this.validate}
                      validateOnChange={false}
                      validateOnBlur={false}
                      formRenderFunc={(props) => {
                        const { values } = props;
                        const { password } = values;
                        const passwordStrengthMeterLabel = get(labels, 'passwordStrength');
                        return (
                          <>
                            {userType === ADMIN_USER && (
                              <TextFieldItem
                                name="currentPassword"
                                label={labels.currentPassword}
                                type="password"
                                id="current-password-input"
                                maxLength="25"
                              />
                            )}

                            <Grid>
                              <Cell extraClasses="small-4">
                                <Label htmlFor="password-input">
                                  {getLabel(labels, 'newPassword.title')}
                                </Label>
                              </Cell>
                              <Cell extraClasses="small-8">
                                <PasswordStrengthMeter
                                  password={password}
                                  onChange={this.setPwdStrength}
                                  label={passwordStrengthMeterLabel}
                                  regExps={PASSWORD_STRENGTH_REGEX}
                                />
                              </Cell>
                            </Grid>

                            <TextFieldItem
                              name="password"
                              className="password-input"
                              type="password"
                              id="password-input"
                              maxLength="25"
                            />

                            <div className="helpTextPrevPassword">
                              <span className="icon fil-icon fil-icon-info" />
                              <p>{getLabel(labels, 'newPassword.helpTextPrevPassword')}</p>
                            </div>

                            <PasswordConditions
                              password={password}
                              label={passwordConditionsLevel}
                              regExps={PASSWORD_STRENGTH_REGEX}
                              minCharacter="8"
                            />

                            <TextFieldItem
                              name="renterPassword"
                              label={getLabel(labels, 'rePassword.title')}
                              type="password"
                              id="reenter-password-input"
                              maxLength="25"
                            />
                            <div className="text-center">
                              <Button
                                type="submit"
                                extraClasses="primary-cta reset-password-cta expanded"
                                disabled={!isSPwdStrong}>
                                {getLabel(labels, 'Confirm')}
                              </Button>
                            </div>
                          </>
                        );
                      }}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default ResetPassword;
