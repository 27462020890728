import { put, call, takeLatest } from 'redux-saga/effects';

import get from 'lodash/get';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import { verifyUsernameSuccess, verifyUsernameFailure } from './ForgotLoginDetails.actions';
import {
  setSecurityQuestionToStore,
  getMemberStatusAction,
} from '../../templates/LoginPage/LoginPage.actions';

import {
  SERVICE_ERROR,
  OTP_VERIFY,
  OTP_SECURITY_QA,
  RETRIEVE_USERNAME_PAGE,
  STATUS_M,
  STATUS_L,
  STATUS_M_CODE,
  STATUS_L_CODE,
  INVALID_CREDENTIALS,
  STATUS_D_CODE,
} from '../../../global/constants';
import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';
import {
  NO_USER_CODE,
  RESET_PASSWORD_DISABLE_CODE,
  EMAIL_NOT_VERIFIED_CODE,
  DEVICE_ASSOCIATED_CODE,
  VERIFY_USERNAME,
  NO_USER,
  RESET_PASSWORD_DISABLE,
  VERIFICATION_REQUIRED,
  SECURITY_QUES_CODE,
  USER_VERIFICATION_CODE,
  ANALYTICS_SQ,
  ANALYTICS_VERFIFY_OTP,
  ANALYTICS_UNABLE_RESET,
  ANALYTICS_VERIFICATION_REQUIRED,
  ANALYTICS_USER_REMINDER,
} from './ForgotLoginDetails.constants';

import {
  setActivePage,
  showServiceError,
  setToken,
  setActivePopup,
  setUserStatus,
} from '../../../global/actions';

export function* redirectToActivePage(error) {
  let currentPage = {};

  if (error.data.code === NO_USER_CODE) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: NO_USER,
    };
    yield put(
      setActivePopup(
        currentPage,
        ANALYTICS_USER_REMINDER,
        SITE_SECTION_RESET,
        CHANNEL_ACRONYM_RESET
      )
    );
  } else if (error.data.code === STATUS_M_CODE) {
    yield put(setUserStatus(STATUS_M));
    yield put(setToken(error.data.token));

    yield put(
      setActivePage({
        isPageChanged: true,
        newActivePage: RETRIEVE_USERNAME_PAGE,
      })
    );
  } else if (error.data.code === STATUS_L_CODE) {
    yield put(setUserStatus(STATUS_L));
    yield put(setToken(error.data.token));

    yield put(
      setActivePage({
        isPageChanged: true,
        newActivePage: RETRIEVE_USERNAME_PAGE,
      })
    );
  } else if (error.data.code === STATUS_D_CODE) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: INVALID_CREDENTIALS,
    };
    yield put(setActivePopup(currentPage));
  } else if (
    error.data.code === RESET_PASSWORD_DISABLE_CODE ||
    error.data.code === EMAIL_NOT_VERIFIED_CODE
  ) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: RESET_PASSWORD_DISABLE,
    };
    yield put(
      setActivePopup(currentPage, ANALYTICS_UNABLE_RESET, SITE_SECTION_RESET, CHANNEL_ACRONYM_RESET)
    );
  } else if (error.data.code === DEVICE_ASSOCIATED_CODE) {
    currentPage = {
      isPageChanged: true,
      newActivePage: OTP_VERIFY,
    };
    yield put(setToken(error.data.token));
    yield put(
      setActivePage(currentPage, ANALYTICS_VERFIFY_OTP, SITE_SECTION_RESET, CHANNEL_ACRONYM_RESET)
    );
  } else if (error.data.code === SECURITY_QUES_CODE) {
    currentPage = {
      isPageChanged: true,
      newActivePage: OTP_SECURITY_QA,
    };
    yield put(setToken(error.data.token));
    yield put(setSecurityQuestionToStore(error.data.secq));

    yield put(setActivePage(currentPage, ANALYTICS_SQ, SITE_SECTION_RESET, CHANNEL_ACRONYM_RESET));
  } else {
    yield put(showServiceError(SERVICE_ERROR));
  }
}

export function* verifyUsername(request) {
  const inputData = {
    payload: { userName: get(request, 'formData.formData.username') },
  };
  try {
    const requestData = {
      username: get(request, 'formData.formData.username'),
    };

    const { data } = yield call(axiosServiceClient.post, apiEndPoints.verifyUsername, requestData);

    if (data.code === USER_VERIFICATION_CODE) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: VERIFICATION_REQUIRED,
      };
      yield put(setToken(data.token));
      yield put(
        setActivePopup(
          currentPopup,
          ANALYTICS_VERIFICATION_REQUIRED,
          SITE_SECTION_RESET,
          CHANNEL_ACRONYM_RESET
        )
      );
    }
    yield put(verifyUsernameSuccess(data));
    yield put(getMemberStatusAction(inputData)); // fetchUUIDfromUserName
  } catch (error) {
    yield put(getMemberStatusAction(inputData)); // fetchUUIDfromUserName
    yield call(redirectToActivePage, error.response);
    yield put(verifyUsernameFailure(error));
  }
}

export default function* createnewUserId() {
  yield takeLatest(VERIFY_USERNAME, verifyUsername);
}
