// @flow
import { call } from 'redux-saga/effects';
import { logger } from '@fil-global/eiswagen';

import { axiosServiceClient } from '../../config/axios-client';
import apiEndPoints from '../../constants/api/services';

/**
 * call for encryption happens twice if arcot id is not saved:
 * 1. for getting arcotid
 * 2. for generating signed token
 * cache is used here for memoizing that
 */
const cache = {};

export function* encrypt(Values: Array<string>): any {
  try {
    if (cache[Values]) {
      return cache[Values];
    }
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.encrypt, {
      Values,
    });
    cache[Values] = data.Values;
    return data.Values;
  } catch (e) {
    logger.warn('encryption failed', e);
    throw e;
  }
}

export default encrypt;
