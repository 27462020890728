/**
 *
 * FooterCopyright
 *
 */

// @flow

import React from 'react';
import type { Node } from 'react';
import { Logo, Anchor, List } from '@fil-global/eiswurfel';
import { DESKTOP } from '@fil-global/eiswagen';
import classnames from 'classnames';
import { isMobile } from 'react-device-detect';

import ScrollTop from '../../atoms/ScrollTop';
import type { Props } from './types';
import footerLogo from './footer_logo.svg';

const logoConfig = {
  src: footerLogo,
  width: 50,
};

export const getLogoConfig = (ismobile) => {
  const logo = {};
  if (ismobile) {
    logo.path = null;
    logo.className = 'in-mobile';
  }
  return logo;
};

const FooterCopyright = ({ data, deviceType }: Props): Node => {
  if (!data) return null;
  const { links, copyright } = data;

  const isDesktop = deviceType === DESKTOP || false;

  const containerClasses = classnames(
    'flex-container',
    { 'align-middle': !isDesktop },
    { 'align-stretch': isDesktop },
    { 'flex-dir-column': !isDesktop },
    { 'flex-dir-row': isDesktop }
  );

  return (
    <div className={classnames('footer-copyright', containerClasses)}>
      <div className={classnames('logo-wrapper', containerClasses)}>
        <Logo
          mobile={logoConfig}
          tablet={logoConfig}
          desktop={logoConfig}
          {...getLogoConfig(isMobile)}
        />
        <div
          className={classnames(
            'copyright-content',
            'flex-container',
            { 'align-middle': !isDesktop },
            'flex-dir-column'
          )}>
          <p className="logo-text">{copyright}</p>
          <List isBulleted={false} className="footer-privacy-links flex-container">
            {Array.isArray(links) &&
              links.map((item) => (
                <li key={item.label}>
                  <Anchor href={item.href}>{item.label}</Anchor>
                </li>
              ))}
          </List>
        </div>
      </div>
      <ScrollTop />
    </div>
  );
};

FooterCopyright.defaultProps = {};

export default FooterCopyright;
