// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  children: Node,
  extraProps: any,
};

const Layout = ({ children, ...extraProps }: Props): Node => (
  <>
    <main id="content-wrapper" {...extraProps}>
      {children}
    </main>
  </>
);

export default Layout;
