/**
 *
 * Popup
 *
 */

// @flow

import React, { PureComponent } from 'react';
import {
  Anchor,
  Button,
  Modal,
  UnsafeHtml,
  getLabel,
  RadioButton,
  RadioGroup,
  Grid,
  Cell,
} from '@fil-global/eiswurfel';
import get from 'lodash/get';
import classnames from 'classnames';

import type { Props, State } from './types';

class Popup extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = { optionSelected: '' };
  }

  renderPage = () => {
    const { setActivePage } = this.props;
    const { optionSelected } = this.state;
    setActivePage({
      isPageChanged: true,
      newActivePage: optionSelected,
    });
  };

  changeOption = (e: SyntheticEvent<HTMLInputElement>) => {
    // $FlowFixMe eslint-disable-line
    const { value } = e.target;
    this.setState({ optionSelected: value });
  };

  render() {
    const {
      labels,
      showModal,
      closeModal,
      primaryAction,
      secondaryAction,
      errorMessage,
      ...extraProps
    } = this.props;
    const cancellable = {
      buttonText: getLabel(labels, 'close'),
      closeButtonHandler: closeModal,
    };
    const { optionSelected } = this.state;
    let PrimaryHandler = closeModal;

    if (!labels) {
      return null;
    }
    if (primaryAction) {
      PrimaryHandler = primaryAction;
    }
    if (labels.radioOptions) {
      PrimaryHandler = this.renderPage;
    }

    return (
      <Modal
        showModal={showModal}
        cancellable={cancellable}
        closeModal={closeModal}
        {...extraProps}
        title={getLabel(labels, 'title')}
        className="popup-wrapper reveal">
        {errorMessage && (
          <div className="notification error popup-error-notification">
            <span className="icon fil-icon fil-icon-error " />
            <p>{errorMessage}</p>
          </div>
        )}
        <UnsafeHtml wrapper="div">{getLabel(labels, 'content')}</UnsafeHtml>
        {labels.radioOptions ? (
          <RadioGroup name="option" onChange={this.changeOption}>
            {labels.radioOptions.map((option, key) => (
              <RadioButton
                key={`${key.toString()}`}
                value={option.value}
                label={option.label}
                id={`radio-option-${key.toString()}`}
                variation="small"
              />
            ))}
          </RadioGroup>
        ) : (
          ''
        )}

        <Grid extraClasses="grid-margin-x align-center flex-dir-row-reverse cta-container">
          <Cell
            extraClasses={classnames({
              'medium-6': secondaryAction,
              'text-center': !secondaryAction,
            })}>
            {get(labels, 'isLink') ? (
              <Anchor
                className={classnames('button primary ', labels.linkClass)}
                {...labels.linkProps}
              />
            ) : (
              <Button onClick={PrimaryHandler} disabled={labels.radioOptions && !optionSelected}>
                {getLabel(labels, 'cta')}
              </Button>
            )}
          </Cell>
          {secondaryAction && (
            <Cell extraClasses={classnames('text-right', { 'medium-6': secondaryAction })}>
              <Button onClick={secondaryAction} extraClasses="tertiary">
                {getLabel(labels, 'secondaryCta')}
              </Button>
            </Cell>
          )}
        </Grid>
      </Modal>
    );
  }
}

export default Popup;
