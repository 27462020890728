// @flow
import { put, call, takeLatest, all, fork, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { logger } from '@fil-global/eiswagen';
import get from 'lodash/get';
import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  submitOtpActionSuccess,
  submitOtpActionFailure,
  resendOtpActionSuccess,
  resendOtpActionFailure,
  showOtpNotification,
} from './OtpVerification.actions';
import {
  showServiceError,
  setToken,
  setActivePopup,
  setArcotId,
  setActivePage,
} from '../../../global/actions';
import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';

import { retrieveUserName } from '../RetrieveUserName/RetrieveUserName.actions';
import {
  SERVICE_ERROR,
  REMEMBER_DEVICE,
  RESET_PASSWORD_JOURNEY,
  LOGIN_JOURNEY,
  RESET_PWD_PAGE,
  RETRIEVE_USERNAME_JOURNEY,
} from '../../../global/constants';
import {
  SUBMIT_OTP,
  RESEND_OTP,
  RESEND_SUCCESS,
  RESEND_FAILURE,
  OTP_FAIL_COUNT,
  OTP_FAIL_COUNT_EXCEED,
  INVALID_OTP,
  INCORRECT_OTP,
  USER_VELOCITY_COUNT_REACHED,
  VELOCITY_COUNT_REACHED,
  ANALYTICS_CHOOSE_NEW_PASSWORD,
  ANALYTICS_REMEMBER_DEVICE,
} from './OtpVerification.constants';
import {
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_POPUP,
  TOKEN_EXPIRED_ANALYTICS,
} from '../../templates/LoginPage/LoginPage.constants';

export function* resendOtp(): Saga<void> {
  try {
    const store = yield select();
    const requestData = {
      token: get(store, 'global.loginData.token'),
    };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.resendOtp, requestData);
    yield put(resendOtpActionSuccess(data));
    yield put(showOtpNotification(RESEND_SUCCESS));
    yield put(setToken(data.token));
  } catch (error) {
    const code = get(error, 'response.data.code');
    if (code === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    } else if (code === USER_VELOCITY_COUNT_REACHED) {
      yield put(showOtpNotification(VELOCITY_COUNT_REACHED));
    } else {
      yield put(showServiceError(SERVICE_ERROR));
    }
    logger.info(error);
    yield put(resendOtpActionFailure(error));
  }
}

export function* submitOtp(submitData: Object): Saga<void> {
  try {
    const store = yield select();
    const requestData = {
      token: get(store, 'global.loginData.token'),
      otp: submitData.otp.deviceOtp,
    };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.submitOtp, requestData);
    const journey = get(store, 'global.globalData.currentJourney');

    yield put(setArcotId(data.arcotId));
    yield put(setToken(data.token));
    yield put(submitOtpActionSuccess(data));

    if (journey === RESET_PASSWORD_JOURNEY) {
      const currentPage = {
        isPageChanged: true,
        newActivePage: RESET_PWD_PAGE,
      };

      yield put(
        setActivePage(
          currentPage,
          ANALYTICS_CHOOSE_NEW_PASSWORD,
          SITE_SECTION_RESET,
          CHANNEL_ACRONYM_RESET
        )
      );
    } else if (journey === RETRIEVE_USERNAME_JOURNEY) {
      yield put(retrieveUserName(null, true));
    } else if (journey === LOGIN_JOURNEY) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: REMEMBER_DEVICE,
      };
      yield put(setActivePopup(currentPopup, ANALYTICS_REMEMBER_DEVICE));
    }
  } catch (error) {
    const code = get(error, 'response.data.code');
    if (code === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    } else if (code === OTP_FAIL_COUNT || code === OTP_FAIL_COUNT_EXCEED) {
      yield put(showOtpNotification(RESEND_FAILURE));
      yield put(setToken(error.response.data.token));
    } else if (code === INVALID_OTP) {
      yield put(showOtpNotification(INCORRECT_OTP));
      yield put(setToken(error.response.data.token));
    } else {
      yield put(showServiceError(SERVICE_ERROR));
    }
    logger.info(error);
    yield put(submitOtpActionFailure(error));
  }
}

export function* watchResendOtp(): Saga<void> {
  yield takeLatest(RESEND_OTP, resendOtp);
}

export function* watchSubmitOtp(): Saga<void> {
  yield takeLatest(SUBMIT_OTP, submitOtp);
}

export default function* otpVerification(): Saga<void> {
  yield all([fork(watchResendOtp), fork(watchSubmitOtp)]);
}
