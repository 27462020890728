// @flow
import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { logger } from '@fil-global/eiswagen';
import { getLabel } from '@fil-global/eiswurfel';
import get from 'lodash/get';

import { SAVE_TNC, FETCH_TNC } from './TermsAndConditions.constants';
import { fetchTncSuccess, fetchTncFailure, saveTncFailure } from './TermsAndConditions.actions';
import axiosClient, { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import { proceedToSilentLogin } from '../../templates/LoginPage/LoginPage.actions';

export function* saveTnc(): Saga<void> {
  try {
    const state = yield select();
    const TAndCVersion = get(state, 'global.loginData.activePage.TncVersion');
    const tncData = {
      TAndCVersion,
      acceptance: true,
    };
    yield call(axiosServiceClient.post, apiEndPoints.saveTnc, tncData);
    yield put(proceedToSilentLogin());
  } catch (e) {
    logger.error('unable to update terms and conditions records', e);
    yield put(saveTncFailure());
  }
}

export function* fetchTnc(): Saga<void> {
  try {
    const state = yield select();
    const version = getLabel(state, 'global.loginData.activePage.TncVersion');
    const {
      data: { termsAndConditions },
    } = yield call(axiosClient.get, getLabel(apiEndPoints, 'fetchTnc', { version }), {
      baseURL: process.env.REACT_APP_CONTENT_HOST,
    });
    yield put(fetchTncSuccess(termsAndConditions));
  } catch (e) {
    yield put(fetchTncFailure());
  }
}

export default function* termsAndConditionsSaga(): Saga<void> {
  yield all([takeLatest(SAVE_TNC, saveTnc), takeLatest(FETCH_TNC, fetchTnc)]);
}
