/**
 *
 * LoginForm
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import {
  GridContainer,
  Grid,
  Cell,
  Form,
  TextField,
  withField,
  Button,
  ReactLink,
  Spinner,
  Notification,
  getLabel,
  Checkbox,
  List,
} from '@fil-global/eiswurfel';
import { getLocalStorage } from '@fil-global/eiswagen';
import get from 'lodash/get';
import memoizeone from 'memoize-one';
import classname from 'classnames';
import { isMobile } from 'react-device-detect';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { ANALYTICS_USER_NAME, PASSWORD_REGEX } from './LoginForm.constants';

import {
  ACCOUNT_LOCKED,
  ACCOUNT_DISABLED,
  CHANGE_TEMP_PASSWORD,
  EXPIRED_PASSWORD,
  MIGRATED_USER,
  RESET_PASSWORD_JOURNEY,
  FORGOT_LOGIN_DETAILS,
  LOGIN_JOURNEY,
  SOON_EXPIRED_PASSWORD,
  RESET_PWD_PAGE,
  TEMP_USERNAME_PASSWORD,
  CHANGE_USER,
  INVALID_CREDENTIALS,
  STEP_BY_STEP_GUIDE,
  ACCOUNT_WEAK_PASSWORD,
  LOGIN_PAGE,
  UNSUPPORTED_BROWSER_WARNING,
} from '../../../global/constants';
import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';

import Popup from '../../molecules/Popup';
import {
  INVALID_CREDS,
  NO_USER,
  DISABLED_CREDS,
} from '../../../containers/organisms/LoginForm/LoginForm.constants';
import {
  MOBILE_DISABLED_USER,
  DO_NOT_MEET_SECURITY_STANDARD,
} from '../../../containers/templates/LoginPage/LoginPage.constants';

import getWindowVersion from '../../../utils/getWindowVersion/getWindowVersion';
import type { Props } from './types';
import isBrowserUnSupported from '../../../utils/getBrowserVersion/getBrowserVersion';

const TextFieldItem = withField(TextField);
const CheckboxItem = withField(Checkbox);

class LoginForm extends PureComponent<Props> {
  validateSchema = () => {
    const { loginFormLabels } = this.props;

    const schema = {
      username: {
        required: get(loginFormLabels, 'validationErrors.userName.isRequired', 'isRequired'),
      },
      password: {
        required: get(loginFormLabels, 'validationErrors.password.isRequired', 'isRequired'),
        matches: [
          PASSWORD_REGEX,
          getLabel(loginFormLabels, 'validationErrors.password.invalid', 'invalid'),
        ],
      },
    };
    return schema;
  };

  getInitialValues = () => {
    const { username } = this.props;
    const initialValues = {
      username: '',
      password: '',
      remmemberMe: false,
    };
    if (username) {
      initialValues.username = username;
    } else if (get(getLocalStorage('local-storage-rememberMe'), 'user') && this.isDesktop()) {
      const encryptedUser = get(getLocalStorage('local-storage-rememberMe'), 'user');
      const bytes = aes.decrypt(encryptedUser, process.env.REACT_APP_SECRET_KEY);
      const userName = bytes.toString(enc);
      initialValues.username = userName;
      initialValues.remmemberMe = true;
    }
    return initialValues;
  };

  handleSubmit = (values: Object) => {
    const { getLoggedUser, setCurrentJourney } = this.props;
    setCurrentJourney(LOGIN_JOURNEY);
    getLoggedUser({ formData: values });
  };

  closeMigratedUserModal = () => {
    const { activePopup, closeCurrentElement, keepUserName } = this.props;
    keepUserName(activePopup.token);
    closeCurrentElement();
  };

  closeAndRedirectOnWeakPassword = () => {
    const { closeCurrentElement } = this.props;
    let { changePage } = this.props;
    changePage = changePage.bind(null, {
      isPageChanged: true,
      newActivePage: LOGIN_PAGE,
    });
    changePage();
    closeCurrentElement();
  };

  renderActivePopupExtension = (
    activePopup: Object,
    labels: Object,
    closeCurrentElement: () => mixed
  ) => {
    switch (activePopup.newActivePopup) {
      case INVALID_CREDENTIALS:
        return (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            showModal
            closeModal={closeCurrentElement}
          />
        );
      case STEP_BY_STEP_GUIDE:
        return (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            showModal
            closeModal={closeCurrentElement}
            primaryAction={closeCurrentElement}
          />
        );
      case ACCOUNT_WEAK_PASSWORD:
        return (
          <Popup
            labels={labels[ACCOUNT_WEAK_PASSWORD]}
            showModal={labels.accountWeakPassword.showModal}
            closeModal={this.closeAndRedirectOnWeakPassword}
            primaryAction={this.setJourney}
          />
        );
      case UNSUPPORTED_BROWSER_WARNING:
        return (
          <Popup
            labels={labels[UNSUPPORTED_BROWSER_WARNING]}
            showModal
            closeModal={closeCurrentElement}
            primaryAction={closeCurrentElement}
          />
        );
      default:
        return null;
    }
  };

  renderActivePopup = (
    activePopup: Object,
    labels: Object,
    closeCurrentElement: () => mixed,
    changeButton: () => mixed
  ) => {
    let { changeUserName, keepUserName, expireCheck, changePage } = this.props;
    const label = getLabel(labels, [activePopup.newActivePopup]);
    switch (activePopup.newActivePopup) {
      case ACCOUNT_LOCKED:
        return (
          <Popup
            labels={labels[ACCOUNT_LOCKED]}
            showModal={labels.accountLocked.showModal}
            closeModal={closeCurrentElement}
            primaryAction={this.setJourney}
          />
        );
      case ACCOUNT_DISABLED:
        return (
          <Popup
            labels={labels.accountDisabled}
            showModal={labels.accountDisabled.showModal}
            closeModal={closeCurrentElement}
          />
        );
      case CHANGE_TEMP_PASSWORD:
      case EXPIRED_PASSWORD:
        changePage = changePage.bind(null, {
          isPageChanged: true,
          newActivePage: RESET_PWD_PAGE,
        });
        return (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            showModal={labels[activePopup.newActivePopup].showModal}
            closeModal={closeCurrentElement}
            primaryAction={changePage}
          />
        );
      case SOON_EXPIRED_PASSWORD:
        changePage = changePage.bind(null, {
          isPageChanged: true,
          newActivePage: RESET_PWD_PAGE,
        });
        label.content = getLabel(label, 'content', {
          daysToExpire: activePopup.daysToExpire,
        });
        keepUserName = keepUserName.bind(null, activePopup.token);
        return (
          <Popup
            labels={label}
            showModal={label.showModal}
            closeModal={closeCurrentElement}
            primaryAction={changePage}
            secondaryAction={keepUserName}
          />
        );
      case TEMP_USERNAME_PASSWORD:
        changePage = changePage.bind(null, {
          isPageChanged: true,
          newActivePage: CHANGE_USER,
        });
        keepUserName = keepUserName.bind(null, activePopup.token);
        return (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            showModal={labels[activePopup.newActivePopup].showModal}
            closeModal={closeCurrentElement}
            primaryAction={changePage}
            secondaryAction={keepUserName}
          />
        );
      case MIGRATED_USER:
        changeUserName = changeUserName.bind(null, activePopup.userNameToken);
        keepUserName = keepUserName.bind(null, activePopup.token);
        expireCheck = expireCheck.bind(null, activePopup.token);
        return (
          <Popup
            labels={labels.migratedUser}
            showModal={labels.migratedUser.showModal}
            changeButton={changeButton}
            closeModal={this.closeMigratedUserModal}
            primaryAction={changeUserName}
            secondaryAction={activePopup.isExpiry ? expireCheck : keepUserName}
          />
        );
      case MOBILE_DISABLED_USER:
        return (
          <Popup
            labels={labels[activePopup.newActivePopup]}
            showModal
            primaryAction={closeCurrentElement}
            closeModal={closeCurrentElement}
          />
        );
      default:
        return this.renderActivePopupExtension(activePopup, labels, closeCurrentElement);
    }
  };

  renderLoginError = (InvalidUserError: string, label: Object, isClientSideError: boolean) => {
    const { showInvalidUserError } = this.props;
    if (isClientSideError) {
      showInvalidUserError({});
    }
    switch (InvalidUserError) {
      case INVALID_CREDS:
        return (
          <div className="invalidUser-error field-error">
            <span className="fil-icon fil-icon-error" />
            <p className="invalidUser">{label.invalidUser}</p>
          </div>
        );
      case NO_USER:
        return (
          <div className="invalidUser-error field-error">
            <span className="fil-icon fil-icon-error" />
            <p className="invalidUser">{label.invalidUser}</p>
          </div>
        );
      case DO_NOT_MEET_SECURITY_STANDARD:
        return (
          <div className="invalidUser-error field-error">
            <span className="fil-icon fil-icon-error" />
            <p className="invalidUser">{label.doNotMeetScurityStandard}</p>
          </div>
        );
      case DISABLED_CREDS:
        return (
          <div className="invalidUser-error field-error">
            <span className="fil-icon fil-icon-error" />
            <p className="invalidUser">{label.disabledUser}</p>
          </div>
        );
      default:
        return null;
    }
  };

  renderWindow7Notification = () => {
    const { window7NotificationLabels } = this.props;
    return (
      <div>
        {getWindowVersion() === 7 && window7NotificationLabels && (
          <Notification {...window7NotificationLabels} />
        )}
      </div>
    );
  };

  setJourney = () => {
    const { setCurrentJourney, setForgotUserPage, setUserStatus, closeCurrentElement } = this.props;
    setCurrentJourney(RESET_PASSWORD_JOURNEY);
    setUserStatus('');
    setForgotUserPage(
      { isPageChanged: true, newActivePage: FORGOT_LOGIN_DETAILS },
      ANALYTICS_USER_NAME,
      SITE_SECTION_RESET,
      CHANNEL_ACRONYM_RESET
    );
    closeCurrentElement();
  };

  oepnMemberGuidePopup = () => {
    const { setActivePopup } = this.props;
    setActivePopup({ isShowPopup: true, newActivePopup: STEP_BY_STEP_GUIDE });
  };

  renderUnsupportedBrowserNotification = memoizeone((labels) => {
    const { setActivePopup, unSupportedBrowser, isUnSupportedBrowserOpened } = this.props;
    const allSupportedBrowserList = get(labels, 'allSupportedBrowserList');
    const isEnabledMobile = get(labels, 'unSupportedBrowserPopup.isEnabledMobile') && isMobile;
    const isEnabledWeb = get(labels, 'unSupportedBrowserPopup.isEnabledWeb') && !isMobile;
    if (
      !isUnSupportedBrowserOpened &&
      allSupportedBrowserList &&
      (isEnabledMobile || isEnabledWeb)
    ) {
      if (isBrowserUnSupported(allSupportedBrowserList)) {
        setActivePopup({
          isShowPopup: true,
          newActivePopup: UNSUPPORTED_BROWSER_WARNING,
        });
        unSupportedBrowser(true);
      }
    }
  });

  isDesktop = () => !/iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  renderSystemError = () => {
    const { errorLabels, serviceError, closeCurrentElement, isHideView } = this.props;
    return (
      <GridContainer variation=" ">
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 ">
            {serviceError && isHideView && (
              <Notification {...errorLabels} onClose={closeCurrentElement} />
            )}
          </Cell>
        </Grid>
      </GridContainer>
    );
  };

  render(): Node {
    const {
      loginFormLabels,
      activePopup,
      isFetching,
      labels,
      closeCurrentElement,
      InvalidUserError,
      changeButton,
      errorLabels,
      serviceError,
      scheduledMaintenanceLabels,
      username,
      isNonMigrated,
      isHideView,
      pingToggle,
    } = this.props;

    if (!loginFormLabels && !labels) {
      return null;
    }

    return (
      <div>
        {this.renderSystemError()}
        {isFetching && <Spinner isVisible displayType="fixed" size="large" />}
        <GridContainer variation=" " extraClasses={isHideView ? 'hide' : null}>
          {activePopup && activePopup.isShowPopup
            ? this.renderActivePopup(activePopup, labels, closeCurrentElement, changeButton)
            : ''}

          <Grid margin="grid-margin-x align-center">
            <Cell extraClasses="medium-8 large-6 content-container login-page-container">
              <Grid margin="grid-padding-x align-center">
                <Cell extraClasses="medium-10">
                  <Grid margin="grid-margin-x align-center">
                    {scheduledMaintenanceLabels && scheduledMaintenanceLabels.isEnabled && (
                      <Notification {...scheduledMaintenanceLabels} />
                    )}
                    {this.renderWindow7Notification()}
                    {this.renderUnsupportedBrowserNotification(labels)}
                    {serviceError && (
                      <Notification {...errorLabels} onClose={closeCurrentElement} />
                    )}
                    <Cell extraClasses="heading text-center">
                      <h2> {loginFormLabels.title}</h2>
                    </Cell>
                    <Cell>
                      <Form
                        initialValues={this.getInitialValues()}
                        validationSchema={this.validateSchema()}
                        handleSubmit={this.handleSubmit}
                        id="login-form"
                        formRenderFunc={(props) => (
                          <>
                            <TextFieldItem
                              name="username"
                              className="username-input"
                              label={loginFormLabels.username}
                              maxLength="45"
                              id="username-input"
                              disabled={isFetching || (pingToggle === 'on' && username)}
                            />
                            <TextFieldItem
                              name="password"
                              className={classname('password-input', {
                                'is-invalid-input': InvalidUserError,
                              })}
                              label={loginFormLabels.password}
                              type="password"
                              maxLength="25"
                              id="password-input"
                              disabled={isFetching}
                            />
                            {this.renderLoginError(
                              InvalidUserError,
                              loginFormLabels.errorLabels,
                              get(props, 'errors.password')
                            )}

                            {this.isDesktop() && !isNonMigrated && pingToggle !== 'on' && (
                              <CheckboxItem
                                name="remmemberMe"
                                label={loginFormLabels.rememberMe}
                                id="remmemberMe"
                              />
                            )}
                            <Grid extraClasses="align-center">
                              <Cell extraClasses="text-center">
                                <Button
                                  type="submit"
                                  variation="full-width"
                                  className="button login-button expanded"
                                  id="login-button">
                                  {loginFormLabels.login}
                                </Button>
                              </Cell>
                              <Cell extraClasses="text-center">
                                <Button
                                  theme="link"
                                  extraClasses="forgot-user-link fil-icon fil-icon-arrow-r-light"
                                  id="forgot-user-link"
                                  onClick={this.setJourney}>
                                  {loginFormLabels.userDetails}
                                </Button>
                              </Cell>

                              <Cell extraClasses="text-center hide">
                                <Button
                                  theme="link"
                                  extraClasses="fil-icon user-guide-link"
                                  id="forgot-user-link"
                                  onClick={this.oepnMemberGuidePopup}>
                                  {loginFormLabels.memberGuide}
                                </Button>
                              </Cell>
                            </Grid>
                          </>
                        )}
                      />
                    </Cell>
                  </Grid>
                </Cell>
              </Grid>
            </Cell>
          </Grid>
          <Grid margin="grid-margin-x align-center new-member-registration">
            <Cell extraClasses="medium-8 large-6 registration-container">
              <Grid extraClasses="grid-padding-x align-center ">
                <Cell extraClasses="medium-10 large-10">
                  <h5 className="heading text-center">{loginFormLabels.registration.title}</h5>
                  <p className="text-center help-text-title">
                    {loginFormLabels.registration.helpTextTitle}
                  </p>

                  <List>
                    <>
                      {Object.keys(loginFormLabels.registration.helpTextLevel).map((key) => (
                        <li key={getLabel(loginFormLabels.registration.helpTextLevel[key], 'key')}>
                          {getLabel(loginFormLabels.registration.helpTextLevel[key], 'title')}
                        </li>
                      ))}
                    </>
                  </List>
                </Cell>
                <Cell extraClasses="medium-8 large-8 text-center">
                  <ReactLink
                    className="registration-button button secondary expanded"
                    id="registration-button"
                    {...loginFormLabels.registration.registrationButton}
                  />
                </Cell>
              </Grid>
            </Cell>
          </Grid>
        </GridContainer>
      </div>
    );
  }
}

LoginForm.defaultProps = {};
export default LoginForm;
