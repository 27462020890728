// @flow

const postMessage = (message) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(message);
  }

  return null;
};
export default postMessage;
