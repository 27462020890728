/**
 *
 * ShowUserName
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import { GridContainer, Grid, Cell, Button, getLabel, UnsafeHtml } from '@fil-global/eiswurfel';
import postMessage from '../../../utils/postMessage';

import type { Props, State } from './types';

class ShowUserName extends PureComponent<Props, State> {
  static defaultProps = {};

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  redirectToLogIn = () => {
    const { logIn } = this.props;
    if (window.ReactNativeWebView) {
      postMessage('mobile_app_successful_username_recovery');
    } else {
      logIn();
    }
  };

  render(): Node {
    const { labels, username, resetPassword, userStatus } = this.props;
    return (
      <GridContainer variation=" ">
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container showusername-container ">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  <Cell extraClasses="heading">
                    <h2 className="text-center">{getLabel(labels, 'title')}</h2>
                  </Cell>
                  <Cell>
                    <UnsafeHtml extraClasses="">{getLabel(labels, 'content')}</UnsafeHtml>
                  </Cell>
                  <Cell extraClasses="username-wrapper text-center">
                    <p>{getLabel(labels, 'userNameText')}</p>
                    <h4>{username}</h4>
                  </Cell>
                </Grid>

                <Grid margin="grid-margin-x align-center">
                  <Cell extraClasses="text-center ">
                    <Button theme="primary" variation="expanded" onClick={this.redirectToLogIn}>
                      {getLabel(labels, 'logIn')}
                    </Button>
                  </Cell>
                </Grid>
                <Grid margin="grid-margin-x align-center">
                  <Cell extraClasses="text-center medium-6">
                    <Button
                      theme="link"
                      extraClasses="secondary-cta"
                      onClick={() => {
                        resetPassword(userStatus);
                      }}>
                      {getLabel(labels, 'resetPassword')}
                    </Button>
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default ShowUserName;
