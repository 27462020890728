import { put, call, takeLatest, select } from 'redux-saga/effects';
import { logger } from '@fil-global/eiswagen';
import get from 'lodash/get';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  changeUsernamePwdSuccess,
  changeUsernamePwdFailure,
} from './ChangeUsernamePassword.actions';
import {
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_POPUP,
  TOKEN_EXPIRED_ANALYTICS,
} from '../../templates/LoginPage/LoginPage.constants';
import { showServiceError, setActivePage, setActivePopup } from '../../../global/actions';
import { SERVICE_ERROR, LOGIN_COMPLETE } from '../../../global/constants';
import {
  CHANGE_USERNAME_PWD,
  USER_EXISTS,
  CHANGE_USERNAME_COMPLETE_ANALYTICS,
  WRONG_CURRENT_PASSWORD,
} from './ChangeUsernamePassword.constants';
import encrypt from '../../../utils/passwordEncryption';

export function* changeUserName(request) {
  try {
    const state = yield select();
    const token = get(state, 'global.loginData.activePopup.userNameToken', '');
    const [password, currentPassword] = yield call(encrypt, [
      request.formData.password.trim(),
      request.formData.currentPassword.trim(),
    ]);
    const requestData = {
      token,
      userName: request.formData.username.trim(),
      password,
      currentPassword,
      passwd: request.formData.password.trim(),
    };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.changeUsername, requestData);
    yield put(changeUsernamePwdSuccess(data));
    const currentPage = {
      isPageChanged: true,
      newActivePage: LOGIN_COMPLETE,
    };
    yield put(setActivePage(currentPage, CHANGE_USERNAME_COMPLETE_ANALYTICS));
    return data;
  } catch (error) {
    const errorCode = get(error, 'response.data.code');
    const store = yield select();
    if (errorCode === TOKEN_EXPIRED) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPopup, TOKEN_EXPIRED_ANALYTICS));
    } else if (errorCode === USER_EXISTS && request.setErrors) {
      const errorMessage = get(
        store,
        'global.globalData.labels.changeUsernamePasswordLabels.validationErrors.username.userAlreadyTaken'
      );
      request.setErrors({
        username: errorMessage,
      });
    } else if (errorCode === WRONG_CURRENT_PASSWORD && request.setErrors) {
      request.setErrors({
        currentPassword: get(
          store,
          'global.globalData.labels.changeUsernamePasswordLabels.validationErrors.currentPassword.wrongPassword'
        ),
      });
    } else {
      yield put(showServiceError(SERVICE_ERROR));
    }

    logger.info(error);
    yield put(changeUsernamePwdFailure(error));
    return error;
  }
}

export default function* changeUsernamePwdSaga() {
  yield takeLatest(CHANGE_USERNAME_PWD, changeUserName);
}
