/**
 *
 * LoginForm
 *
 */

// @flow

import React, { PureComponent } from 'react';
import type { Node } from 'react';
import {
  GridContainer,
  Grid,
  Cell,
  Form,
  TextField,
  withField,
  Button,
  Spinner,
  Notification,
} from '@fil-global/eiswurfel';
import classname from 'classnames';
import get from 'lodash/get';
import Popup from '../../molecules/Popup';
import {
  RESEND_FAILURE,
  RESEND_SUCCESS,
  INCORRECT_OTP,
  OTP_LENGTH,
  VELOCITY_COUNT_REACHED,
  ANALYTICS_USER_NAME,
} from '../../../containers/organisms/OtpVerification/OtpVerification.constants';
import {
  REMEMBER_DEVICE,
  ACCOUNT_WEAK_PASSWORD,
  RESET_PASSWORD_JOURNEY,
  FORGOT_LOGIN_DETAILS,
  LOGIN_PAGE,
} from '../../../global/constants';
import {
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';
import RememberDevicePopup from '../../molecules/RememberDevicePopup';

import type { Props, formikPropsType } from './types';

const TextFieldItem = withField(TextField);

class OtpVerification extends PureComponent<Props> {
  static defaultProps = {};

  formProps: ?formikPropsType;

  validateSchema = () => {
    const { otpVerificationLabels } = this.props;

    return {
      deviceOtp: {
        matches: [
          /^[0-9]+$/,
          get(otpVerificationLabels, 'validationErrors.deviceOtp.format', 'format'),
        ],
        required: get(otpVerificationLabels, 'validationErrors.deviceOtp.isRequired', 'isRequired'),
      },
    };
  };

  getInitialValues = () => {
    const initialValues = {
      deviceOtp: '',
    };
    return initialValues;
  };

  handleSubmit = (values: Object) => {
    const { submitOtpButton } = this.props;
    submitOtpButton(values);
  };

  setJourney = () => {
    const {
      setCurrentJourney,
      setForgotUserPage,
      setUserStatus,
      closeCurrentElement,
      showOtpNotification,
    } = this.props;
    setCurrentJourney(RESET_PASSWORD_JOURNEY);
    setUserStatus('');
    setForgotUserPage(
      { isPageChanged: true, newActivePage: FORGOT_LOGIN_DETAILS },
      ANALYTICS_USER_NAME,
      SITE_SECTION_RESET,
      CHANNEL_ACRONYM_RESET
    );
    closeCurrentElement();
    showOtpNotification('');
  };

  resendOtpClick = () => {
    const { resendOtButton } = this.props;
    if (this.formProps && this.formProps.handleReset) {
      this.formProps.handleReset();
    }
    resendOtButton();
  };

  closeAndRedirectOnWeakPassword = () => {
    const { closeCurrentElement } = this.props;
    let { setForgotUserPage } = this.props;
    setForgotUserPage = setForgotUserPage.bind(null, {
      isPageChanged: true,
      newActivePage: LOGIN_PAGE,
    });
    setForgotUserPage();
    closeCurrentElement();
  };

  renderOtpNotifications = (optNotificationKey: string, label: Object) => {
    switch (optNotificationKey) {
      case RESEND_FAILURE:
        return (
          <div className="notification error">
            <span className="icon fil-icon fil-icon-error " />
            <p>{label.resendFailure}</p>
          </div>
        );
      case RESEND_SUCCESS:
        return (
          <div className="notification success">
            <span className="icon fil-icon fil-icon-error " />
            <p>{label.resendSuccess}</p>
          </div>
        );
      case INCORRECT_OTP:
        return (
          <div className="notification error">
            <span className="icon fil-icon fil-icon-error " />
            <p>{label.incorrectOtp}</p>
          </div>
        );

      case VELOCITY_COUNT_REACHED:
        return (
          <div className="notification error">
            <span className="icon fil-icon fil-icon-error " />
            <p>{label.resendLimitCount}</p>
          </div>
        );

      default:
        return null;
    }
  };

  renderActivePopup = (
    activePopup: string,
    labels: Object,
    closeCurrentElement: () => void,
    submitAuthenticate: () => void
  ) => {
    switch (activePopup) {
      case REMEMBER_DEVICE:
        return (
          <RememberDevicePopup
            labels={labels.rememberDeviceLabels}
            showModal={labels.rememberDeviceLabels.showModal}
            closeModal={closeCurrentElement}
            submitAuthenticate={submitAuthenticate}
          />
        );
      case ACCOUNT_WEAK_PASSWORD:
        return (
          <Popup
            labels={labels[ACCOUNT_WEAK_PASSWORD]}
            showModal={labels.accountWeakPassword.showModal}
            closeModal={this.closeAndRedirectOnWeakPassword}
            primaryAction={this.setJourney}
          />
        );
      default:
        return null;
    }
  };

  render(): Node {
    const {
      otpVerificationLabels,
      optNotificationKey,
      activePopup,
      closeCurrentElement,
      submitAuthenticate,
      isFetching,
      hasError,
      serviceError,
    } = this.props;
    if (!otpVerificationLabels) {
      return null;
    }
    return (
      <GridContainer variation=" ">
        {isFetching ? <Spinner isVisible displayType="fixed" size="large" /> : ''}
        {activePopup && activePopup.isShowPopup
          ? this.renderActivePopup(
              activePopup.newActivePopup,
              otpVerificationLabels,
              closeCurrentElement,
              submitAuthenticate
            )
          : ''}
        <Grid margin="grid-margin-x align-center">
          <Cell extraClasses="medium-8 large-6 content-container otp-verification-container">
            <Grid margin="grid-padding-x align-center">
              <Cell extraClasses="medium-10">
                <Grid margin="grid-margin-x align-center">
                  {hasError && (
                    <Cell>
                      <Notification {...serviceError} onClose={closeCurrentElement} />
                    </Cell>
                  )}
                  <Cell extraClasses="heading">
                    <h2 className="text-center"> {otpVerificationLabels.title}</h2>
                  </Cell>
                  <Cell>
                    {otpVerificationLabels.otpInstructions &&
                      otpVerificationLabels.otpInstructions.map((instruction, key) => (
                        <p id={`instruction-${key.toString()}`} key={`${key.toString()}`}>
                          {instruction}
                        </p>
                      ))}
                  </Cell>
                  <Cell>
                    <Form
                      initialValues={this.getInitialValues()}
                      validationSchema={this.validateSchema()}
                      handleSubmit={this.handleSubmit}
                      id="otp-verify-form"
                      formRenderFunc={(props) => {
                        this.formProps = props;
                        return (
                          <>
                            <TextFieldItem
                              className={classname('otp-input', {
                                'is-invalid-input': optNotificationKey === INCORRECT_OTP,
                              })}
                              name="deviceOtp"
                              type="text"
                              pattern="[0-9]{6}"
                              label={otpVerificationLabels.code}
                              minLength="6"
                              maxLength="6"
                              id="deviceOtp"
                            />
                            <Grid extraClasses="align-center">
                              <Cell extraClasses="text-center">
                                <Button
                                  type="submit"
                                  id="submit-otp"
                                  theme="primary"
                                  extraClasses="otp-submit-button expanded"
                                  disabled={
                                    props.values.deviceOtp.length !== OTP_LENGTH ||
                                    optNotificationKey === RESEND_FAILURE ||
                                    optNotificationKey === VELOCITY_COUNT_REACHED
                                  }>
                                  {otpVerificationLabels.submitOtp}
                                </Button>
                              </Cell>
                            </Grid>
                            <Grid extraClasses="align-center">
                              <Cell extraClasses="text-center">
                                <Button
                                  type="button"
                                  theme="link"
                                  id="resend-otp"
                                  disabled={optNotificationKey === VELOCITY_COUNT_REACHED}
                                  extraClasses="primary-cta"
                                  onClick={this.resendOtpClick}>
                                  {otpVerificationLabels.resendOtp}
                                </Button>
                              </Cell>
                            </Grid>
                            {this.renderOtpNotifications(
                              optNotificationKey,
                              otpVerificationLabels.otpNotifications
                            )}
                          </>
                        );
                      }}
                    />
                  </Cell>
                </Grid>
              </Cell>
            </Grid>
          </Cell>
        </Grid>
      </GridContainer>
    );
  }
}

export default OtpVerification;
