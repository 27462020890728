import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);

export const selectActivePopup = (state) => get(state, ['global', 'loginData', 'activePopup']);

export const selectInvalidUserError = (state) =>
  get(state, ['global', 'LoginPage', 'InvalidUserError', 'code']);

export const selectLoginFormLabels = createSelector(selectLabels, (loginFormLabels) =>
  get(loginFormLabels, ['loginFormLabels'])
);

export const selectFetching = (state) =>
  get(state, ['LoginPage', 'isFetching']) ||
  get(state, ['LoginPage', 'keepUsername', 'isFetching']) ||
  get(state, ['LoginPage', 'validateLoggedUser', 'isFetching']) ||
  get(state, ['LoginPage', 'expireCheck ', 'isFetching']) ||
  get(state, ['LoginPage', 'loader', 'isFetching']);
