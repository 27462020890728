// Data Layer Constants
export const CHANNEL = 'Workplace Investing';
export const CHANNEL_ACRONYM = 'WI: PlanViewer';
export const COUNTRY = 'uk';
export const LANGUAGE = 'en';
export const IS_SPA = true;
export const DEFAULT_SITE_SECTION = 'PlanViewer - Login';

export const CHANNEL_ACRONYM_RESET = 'WI: PlanViewer: reset password';
export const SITE_SECTION_RESET = 'PlanViewer - Reset Password';

export const CHANNEL_ACRONYM_RETRIEVE = 'WI: PlanViewer: retrieve username';
export const SITE_SECTION_RETRIEVE = 'PlanViewer - Retrieve Username';
