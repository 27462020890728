// @flow
import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import get from 'lodash/get';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  ANSWER_SECURITY_QUESTION,
  PICK_REF_DETAILS,
  RESET_PASSWORD_DISABLE,
} from './AnswerSecurityQuestions.constantsV2';
import { LOGIN_PAGE } from '../../../global/constants';
import { setActivePopup, setToken } from '../../../global/actions';

import {} from '../../../global/AnalyticsTracking/constants';

import { setSecurityQuestionToStore } from '../../templates/LoginPage/LoginPage.actions';

import {
  answerSecurityQuestionsFailure,
  pickRefDetailsFailure,
  pickRefDetailsSuccess,
} from './AnswerSecurityQuestionsV2.actions';
import getURLParameter from '../../../utils/getURLParameter';
import type { Action } from './types';

export function* answerSecurityQuestions(action: Action): Saga<void> {
  try {
    const values = action.payload;
    const store = yield select();
    const token = get(store, 'global.loginData.token');
    const resumePath = get(store, ['answerSecurityQuestionsV2', 'resumePath']);

    const securityQuestions = Object.keys(values).map((key) => ({
      Id: Number(key.replace('ans-', '').trim()),
      answer: values[key],
    }));
    const payload = { Question: securityQuestions };
    const response = yield call(
      axiosServiceClient.post,
      apiEndPoints.validateRandomSecurityQuestions,
      payload,
      {
        headers: { token },
        baseURL: process.env.REACT_APP_PARTNER_API_HOST,
      }
    );

    const param = {
      journey: 'ResetPassword',
      channel: 'WI',
    };
    const { data } = yield call(axiosServiceClient.get, apiEndPoints.dropRefDetails, {
      baseURL: process.env.REACT_APP_PINGONE_API_HOST,
      params: param,
      headers: { isSuccess: get(response, 'data.dropToken') },
    });
    const { ref } = data;

    setTimeout(() => {
      /* istanbul ignore next */
      window.location = `${process.env.REACT_APP_PING_DOMAIN}${decodeURIComponent(
        resumePath
      )}?REF=${ref}`;
    }, 0);
  } catch (e) {
    yield put(answerSecurityQuestionsFailure());
    const error = e.response;

    const token = get(error, 'data.token');

    if (token && get(error, 'status') === 403) {
      yield put(setToken(token));
      yield put(setSecurityQuestionToStore(get(error, 'data.responseText')));
    } else {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: RESET_PASSWORD_DISABLE,
        redirectPage: LOGIN_PAGE,
      };
      yield put(setActivePopup(currentPopup));
    }
  }
}

export function* fetchSecurityQuestions(): Saga<void> {
  try {
    const param = {
      REF: getURLParameter('REF'),
      journey: 'ResetPassword',
      channel: 'WI',
    };
    const resumePath = getURLParameter('resumePath');
    window.history.pushState({}, '', '/newlogin/#/');
    const { data } = yield call(axiosServiceClient.get, apiEndPoints.pickRefDetails, {
      baseURL: process.env.REACT_APP_PINGONE_API_HOST,
      params: param,
    });
    const { jwtToken } = data;

    yield put(setToken(jwtToken));

    const response = yield call(axiosServiceClient.get, apiEndPoints.fetchRandomSecurityQuestions, {
      headers: { token: jwtToken },
      baseURL: process.env.REACT_APP_PARTNER_API_HOST,
    });

    yield put(setSecurityQuestionToStore(get(response, 'data.responseText')));
    yield put(pickRefDetailsSuccess({ resumePath }));
  } catch (e) {
    yield put(pickRefDetailsFailure());
    const currentPopup = {
      isShowPopup: true,
      newActivePopup: RESET_PASSWORD_DISABLE,
      redirectPage: LOGIN_PAGE,
    };
    yield put(setActivePopup(currentPopup));
  }
}

export default function* answerSecurityQuestionsSaga(): Saga<void> {
  yield all([
    takeLatest(ANSWER_SECURITY_QUESTION, answerSecurityQuestions),
    takeLatest(PICK_REF_DETAILS, fetchSecurityQuestions),
  ]);
}
