import { put, call, takeLatest, all, select, fork } from 'redux-saga/effects';
import { getLocalStorage, setLocalStorage, logger, removeLocalStorage } from '@fil-global/eiswagen';
import { getLabel, measurePasswordStrength } from '@fil-global/eiswurfel';
import { setTheosFidCookie } from '@fil-ukdeploy/theos-headers-ria';
import get from 'lodash/get';
import aes from 'crypto-js/aes';

import AuthMinder from '@fil-global/fil-lib-authminder';

import encrypt from '../../../utils/passwordEncryption';
import getURLParameter from '../../../utils/getURLParameter';
import getURLPathParams from '../../../utils/getURLPathParams';
import postMessage from '../../../utils/postMessage';

import { axiosServiceClient } from '../../../config/axios-client';
import apiEndPoints from '../../../constants/api/services';
import {
  fetchChallengeFailure,
  fetchChallengeSuccess,
  getArcotIdSuccess,
  getArcotIdFailure,
  tokenAuthenticateSuccess,
  tokenAuthenticateFailure,
  validateLoggedUserSuccess,
  validateLoggedUserFailure,
  proceedToSilentLogin,
  setSecurityQuestionToStore,
  verifyRequiredSuccess,
  verifyRequiredFailure,
  validateLoggedUser,
  keepUserNameFailure,
  keepUserNameSuccess,
  migratedUserOption,
  expireCheckSuccess,
  expireCheckFailure,
  redirectLogoutSuccess,
  redirectLogoutFailure,
  setUserType,
  showInvalidUserError,
  getMemberFailure,
  getMemberSuccess,
  getPreAuthFailure,
  getPreAuthTokeSuccess,
  setDeviceReg,
} from './LoginPage.actions';
import {
  setActivePage,
  showServiceError,
  setToken,
  setActivePopup,
  setUserCreds,
  resetUserCreds,
  setArcotId,
  triggerAnalyticsCustomEvent,
  triggerAnalyticsPageView,
} from '../../../global/actions';
import {
  OTP_VERIFY,
  SERVICE_ERROR,
  ACCOUNT_LOCKED_CODE,
  ARCOTID_NOT_ACTIVE,
  ACCOUNT_LOCKED,
  ACCOUNT_WEAK_PASSWORD,
  EMAIL_NOT_VERIFY_CODE,
  SETUP_OTP,
  DEVICE_NOT_ASSOCIATED_CODE,
  ACCOUNT_DISABLED_CODE,
  ACCOUNT_DISABLED,
  MIGRATED_USER,
  SETUP_SECURITY_QUESTIONS,
  REDIRECTION_URL,
  REDIRECTION_DEEP_LINK_URL,
  LOGIN_URL,
  TERMS_AND_CONDITIONS_PAGE,
  OTP_SECURITY_QA,
  CHANGE_USER,
  ADMIN_CHANGE_PASSWORD_CODE,
  CHANGE_TEMP_PASSWORD,
  EXPIRED_PASSWORD,
  SOON_EXPIRED_PASSWORD,
  RESET_ARCOTID_CODE,
  ADMIN_TEMP_PASSWORD_CODE,
  TEMP_USERNAME_PASSWORD,
  MIGRATED_ADMIN_PASSWORD_CODE,
  RESET_PASSWORD_JOURNEY,
  RETRIEVE_USERNAME_JOURNEY,
  INVALID_CREDENTIALS,
} from '../../../global/constants';

import {
  SITE_SECTION_RETRIEVE,
  CHANNEL_ACRONYM_RETRIEVE,
  CHANNEL_ACRONYM_RESET,
  SITE_SECTION_RESET,
} from '../../../global/AnalyticsTracking/constants';
import {
  VALIDATE_LOGGED_USER,
  ORG_NAME,
  INVALID_CREDS,
  NO_USER,
  DISABLED_CREDS,
  PROCEED_TO_SILENT_LOGIN,
  VERIFY_REQUIRED,
  CHANGE_USER_NAME,
  KEEP_USER_NAME,
  ACCOUNT_DISABLED_ANALYTICS,
  ACCOUNT_LOCKED_ANALYTICS,
  CHANGE_USER_NAME_POPUP_ANALYTICS,
  CREATE_OTP_ANALYTICS,
  LOGIN_OTP_ANALYTICS,
  CSQ_ANALYTICS,
  TC_ANALYTICS,
  EXPIRY_CHECK,
  MEMBER_USER,
  ADMIN_USER,
  REDIRECT_LOGOUT,
  CSQA_ANALYTICS,
  REDIRECT_TO_LOGIN_PAGE,
  TOKEN_EXPIRED,
  TOKEN_EXPIRED_POPUP,
  TOKEN_EXPIRED_ANALYTICS,
  RESEND_LIMIT_REACH,
  CHANGE_USER_NAME_ANALYTICS,
  OTP_ANALYTICS,
  MOBILE_DISABLED_CODE,
  MOBILE_DISABLED_USER,
  MOBILE_DISABLED_USER_ANALYTICS,
  LOGIN_ANALYTICS_ACTION,
  DO_NOT_MEET_SECURITY_STANDARD,
  PASSWORD_STRENGTH_REGEX,
  GOOD_ENOUGH,
  MIN_CHARACTER,
  ACCOUNT_WEAK_PASSWORD_ANALYTICS,
  GET_MEMBER_INFO,
  RECOVERY_ANALYTICS_EVENT,
  MOBILE_CHANNEL,
  WEB_CHANNEL,
  GHP_URL,
} from './LoginPage.constants';

import retrieveUsernameSaga from '../../organisms/RetrieveUserName/RetrieveUserName.saga';

export const setFidCookie = (uid) => {
  if (uid) {
    setTheosFidCookie({
      channel: 'PV',
      fid: uid,
      domain: '.fidelity.co.uk',
    });
  }
};

export function* getPreAuthToken(param) {
  try {
    const state = yield select();
    const user = get(state, 'global.loginData.userdata.username') || '';
    const payload = !param ? { userName: user.trim() } : param;

    const { data } = yield call(axiosServiceClient.post, apiEndPoints.preAuthToken, payload);
    yield put(getPreAuthTokeSuccess());
    return data;
  } catch (error) {
    yield put(getPreAuthFailure(error));
    return error;
  }
}

export function* getMemberInfo(action) {
  try {
    const { token } = yield call(getPreAuthToken, get(action, 'param.payload'));
    const store = yield select();
    const journey = get(store, 'global.globalData.currentJourney');
    const config = {
      headers: { token },
    };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.getMemberInfo, {}, config);
    setTheosFidCookie(get(data, 'responseText[0].uuid'), '.fidelity.co.uk');

    if (journey === RETRIEVE_USERNAME_JOURNEY) {
      const eventdetails = {
        action: RECOVERY_ANALYTICS_EVENT,
        eventDetails: {
          uid: get(action, 'param.uid'),
          wi_scheme: get(data, 'responseText[0].schemeCode'),
        },
      };
      yield put(triggerAnalyticsCustomEvent(eventdetails));
    } else {
      yield put(getMemberSuccess(get(data, 'responseText[0].userMigrationstatus')));
    }
  } catch (error) {
    yield put(getMemberFailure());
  }
}

export function* getChallenge() {
  try {
    const { data } = yield call(axiosServiceClient.get, apiEndPoints.challenge);
    yield put(fetchChallengeSuccess(data));
    return data;
  } catch (error) {
    logger.info(error);
    yield put(fetchChallengeFailure(error));
    yield put(validateLoggedUserFailure(error));
    yield put(showServiceError(SERVICE_ERROR));
    return error;
  }
}

export function* redirectToServiceError(error) {
  const errorCode = get(error, 'data.code');
  const accountDiableAnalytics = { pageName: ACCOUNT_DISABLED_ANALYTICS };
  const currentPopup = {
    isShowPopup: true,
    newActivePopup: INVALID_CREDENTIALS,
  };
  switch (errorCode) {
    case INVALID_CREDS:
    case NO_USER:
    case DISABLED_CREDS:
      yield put(showInvalidUserError(error.data));
      if (errorCode === DISABLED_CREDS) {
        yield put(triggerAnalyticsPageView(accountDiableAnalytics));
      }

      break;
    case DO_NOT_MEET_SECURITY_STANDARD:
      yield put(setActivePopup(currentPopup));
      break;
    default:
      yield put(validateLoggedUserFailure(error));
      yield put(showServiceError(SERVICE_ERROR));
      break;
  }
}

export function* redirectToAdminJourney(error) {
  let currentPopup = {};
  const errorCode = get(error, 'data.code');
  if (errorCode === ADMIN_CHANGE_PASSWORD_CODE && get(error, 'data.daysToExpire')) {
    currentPopup = {
      isShowPopup: true,
      newActivePopup: SOON_EXPIRED_PASSWORD,
      token: error.data.token,
      daysToExpire: error.data.daysToExpire,
    };
    yield put(setUserType(ADMIN_USER));
    yield put(setToken(error.data.token));
    yield put(setActivePopup(currentPopup));
  } else if (errorCode === ADMIN_CHANGE_PASSWORD_CODE && get(error, 'data.credExpired')) {
    currentPopup = {
      isShowPopup: true,
      newActivePopup: EXPIRED_PASSWORD,
    };
    yield put(setUserType(ADMIN_USER));
    yield put(setToken(error.data.token));
    yield put(setActivePopup(currentPopup));
  } else if (errorCode === ADMIN_CHANGE_PASSWORD_CODE && get(error, 'data.isTemp')) {
    currentPopup = {
      isShowPopup: true,
      newActivePopup: CHANGE_TEMP_PASSWORD,
      token: error.data.token,
      userNameToken: error.data.userNameToken,
    };
    yield put(setUserType(ADMIN_USER));
    yield put(setToken(error.data.token));
    yield put(setActivePopup(currentPopup));
  } else if (errorCode === ADMIN_TEMP_PASSWORD_CODE) {
    currentPopup = {
      isShowPopup: true,
      newActivePopup: TEMP_USERNAME_PASSWORD,
      userNameToken: error.data.userNameToken,
      token: error.data.token,
    };
    yield put(setUserType(ADMIN_USER));
    yield put(setToken(error.data.token));
    yield put(setActivePopup(currentPopup));
  } else {
    yield call(redirectToServiceError, error);
  }
}

export function* redirectToActivePage(error = {}) {
  let currentPage = {};
  const errorCode = get(error, 'data.code');
  if (errorCode === TOKEN_EXPIRED) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: TOKEN_EXPIRED_POPUP,
    };
    yield put(setActivePopup(currentPage, TOKEN_EXPIRED_ANALYTICS));
  } else if (errorCode === EMAIL_NOT_VERIFY_CODE) {
    currentPage = {
      isPageChanged: true,
      newActivePage: SETUP_OTP,
    };
    yield put(setToken(error.data.token));
    yield put(setActivePage(currentPage, CREATE_OTP_ANALYTICS));
  } else if (errorCode === ACCOUNT_LOCKED_CODE || errorCode === ARCOTID_NOT_ACTIVE) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: ACCOUNT_LOCKED,
    };
    yield put(setActivePopup(currentPage, ACCOUNT_LOCKED_ANALYTICS));
  } else if (get(error, 'data.responseCode') === ACCOUNT_DISABLED_CODE) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: ACCOUNT_DISABLED,
    };
    yield put(setActivePopup(currentPage, ACCOUNT_DISABLED_ANALYTICS));
  } else if (errorCode === MOBILE_DISABLED_CODE) {
    currentPage = {
      isShowPopup: true,
      newActivePopup: MOBILE_DISABLED_USER,
    };
    yield put(setActivePopup(currentPage, MOBILE_DISABLED_USER_ANALYTICS));
  } else if (get(error, 'data.isDeviceAssociated') === false) {
    setTheosFidCookie(get(error, 'data.uid'), '.fidelity.co.uk');
    currentPage = {
      isPageChanged: true,
      newActivePage: OTP_VERIFY,
    };
    yield put(setToken(error.data.token));
    yield put(setActivePage(currentPage, LOGIN_OTP_ANALYTICS));
  } else {
    yield call(redirectToAdminJourney, error);
  }
}

export function* doPostAuthChecks(password) {
  try {
    const regExps = PASSWORD_STRENGTH_REGEX;
    const minCharacter = MIN_CHARACTER;
    const passwordStrength = measurePasswordStrength({
      password,
      regExps,
      minCharacter,
    });
    if (passwordStrength < GOOD_ENOUGH) {
      const currentPopUp = {
        isShowPopup: true,
        newActivePopup: ACCOUNT_WEAK_PASSWORD,
      };
      yield put(migratedUserOption()); // hide any loaders
      yield call(axiosServiceClient.get, apiEndPoints.logout); // logout user as user is authenticated.
      yield put(setActivePopup(currentPopUp, ACCOUNT_WEAK_PASSWORD_ANALYTICS));
    } else {
      const payLoad = {
        loginChannel: WEB_CHANNEL,
      };

      if (getURLParameter('userAgent')) {
        payLoad.loginChannel = MOBILE_CHANNEL;
      }

      const { data } = yield call(
        axiosServiceClient.post,
        apiEndPoints.validateSecurityQuestions,
        payLoad
      );
      const { MemberCode, MemberStsCode, uuid } = data;
      const eventdetails = {
        action: LOGIN_ANALYTICS_ACTION,
        eventDetails: { uuid: MemberCode, status: MemberStsCode },
      };
      if (uuid) {
        setTheosFidCookie(uuid, '.fidelity.co.uk');
      }

      if (MemberCode) {
        yield put(triggerAnalyticsCustomEvent(eventdetails));
      }

      if (data && !data.securityQuestionsAnswered) {
        postMessage('mobile_app_has_not_set_up_security_questions');
        const currentPage = {
          isPageChanged: true,
          newActivePage: SETUP_SECURITY_QUESTIONS,
          TncVersion: data.TncVersion,
        };
        yield put(setActivePage(currentPage, CSQ_ANALYTICS));
      } else if (data && data.TncVersion) {
        postMessage('mobile_app_has_not_agreed_to_the_latest_tcs');
        const currentPage = {
          isPageChanged: true,
          newActivePage: TERMS_AND_CONDITIONS_PAGE,
          TncVersion: data.TncVersion,
        };
        yield put(setActivePage(currentPage, TC_ANALYTICS));
      } else {
        // security questions are already set and TNC not required
        postMessage('mobile_app_has_set_up_security_questions');
        postMessage('mobile_app_has_agreed_to_the_latest_tcs');

        yield put(proceedToSilentLogin());
      }
    }
  } catch (error) {
    logger.info(error);
    yield put(showServiceError(SERVICE_ERROR));
    yield put(validateLoggedUserFailure(error));
  }
}

export function* getArcotId(formData) {
  try {
    const [password] = yield call(encrypt, [formData.password.trim()]);
    const requestData = {
      userName: formData.username.trim(),
      password,
      passwd: formData.password.trim(),
    };
    let config = {};

    if (getURLParameter('userAgent')) {
      config = {
        headers: { userAgent: getURLParameter('userAgent') },
      };
    }
    const response = yield call(
      axiosServiceClient.post,
      apiEndPoints.userValidate,
      requestData,
      config
    );
    const { data, headers } = response;
    const { refno, memberstatuscode } = headers;
    const { token, userNameToken, isExpiry, isAdmin, uid } = data;
    setFidCookie(uid);
    const eventdetails = {
      action: LOGIN_ANALYTICS_ACTION,
      eventDetails: { uuid: refno, status: memberstatuscode },
    };
    if (refno) {
      yield put(triggerAnalyticsCustomEvent(eventdetails));
    }

    if (isAdmin) {
      yield put(setToken(data.token));
      yield put(setUserType(ADMIN_USER));
    } else {
      yield put(setUserType(MEMBER_USER));
    }
    if (userNameToken && data.code === MIGRATED_ADMIN_PASSWORD_CODE) {
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: MIGRATED_USER,
        isExpiry,
        userNameToken,
        token,
      };
      yield put(migratedUserOption()); // hide any loaders
      yield put(setActivePopup(currentPopup));
    } else if (userNameToken) {
      // A case of migrated user; Popup needs to be shown prompting for either changing or keeping the username
      const currentPopup = {
        isShowPopup: true,
        newActivePopup: MIGRATED_USER,
        userNameToken,
        token,
      };
      yield put(migratedUserOption()); // hide any loaders
      yield put(setActivePopup(currentPopup, CHANGE_USER_NAME_POPUP_ANALYTICS));
    } else if (data.isDeviceAssociated) {
      setLocalStorage(btoa(formData.username.toUpperCase()), data.arcotId);
    }
    yield put(getArcotIdSuccess(data));
    return data;
  } catch (error) {
    logger.info(error);
    logger.info(error.response);
    const { headers } = error.response;
    const { refno, memberstatuscode } = headers;
    const eventdetails = {
      action: LOGIN_ANALYTICS_ACTION,
      eventDetails: { uuid: refno, status: memberstatuscode },
    };
    if (refno) {
      yield put(triggerAnalyticsCustomEvent(eventdetails));
    }
    yield call(redirectToActivePage, error.response);
    yield put(getArcotIdFailure(error));
    yield put(validateLoggedUserFailure(error));

    return error;
  }
}

export function* authenticateToken(requestData, username, password) {
  try {
    let { data } = yield call(axiosServiceClient.post, apiEndPoints.authenticate, requestData);
    // TODO: get uuid from api response
    data = { ...data, action: LOGIN_ANALYTICS_ACTION, eventDetails: { uuid: 'testuuid' } };
    logger.info(data);
    yield call(doPostAuthChecks, password);
    yield put(tokenAuthenticateSuccess(data));
    return data;
  } catch (error) {
    if (
      error.response &&
      (get(error, 'response.data.code') === DEVICE_NOT_ASSOCIATED_CODE ||
        get(error, 'response.data.code') === RESET_ARCOTID_CODE ||
        get(error, 'response.data.code') === EMAIL_NOT_VERIFY_CODE)
    ) {
      localStorage.removeItem(btoa(username.toUpperCase()));

      if (get(error, 'response.data.code') === EMAIL_NOT_VERIFY_CODE) {
        yield put(setDeviceReg(true));
      }
      yield put(validateLoggedUser());
    } else {
      yield call(redirectToActivePage, error.response);
    }
    logger.info(error);
    yield put(tokenAuthenticateFailure(error));
    yield put(validateLoggedUserFailure(error));
    return error;
  }
}

export function* userAuthentication(arcotData, values, isArcot) {
  try {
    let validUserData;
    let arcotID = arcotData;
    if (!arcotID) {
      validUserData = yield call(getArcotId, values);
      arcotID = validUserData.arcotId;
    }
    const [encodedPwd] = yield call(encrypt, [values.password.trim()]);
    if (arcotID) {
      const challengeData = yield call(getChallenge);

      const signedChallengeToken = AuthMinder.signChallenge(
        values.username.trim(),
        ORG_NAME,
        challengeData.challenge,
        arcotID,
        encodedPwd
      );

      const store = yield select();
      const authenticateRequest = {
        signedChallenge: signedChallengeToken,
        token: validUserData && validUserData.token ? validUserData.token : '',
        registerDevice: validUserData ? validUserData.isDeviceAssociated : false,
        passwd: values.password.trim(),
      };
      if (isArcot) {
        setLocalStorage(btoa(values.username.trim().toUpperCase()), arcotID);
        authenticateRequest.registerDevice = isArcot;
      }

      if (get(store, 'otpSetUp.verifyInputFieldReducer.data.token')) {
        authenticateRequest.token = get(store, 'otpSetUp.verifyInputFieldReducer.data.token');
      } else {
        authenticateRequest.token = store.global.loginData.token
          ? store.global.loginData.token
          : '';
      }

      const authenticatedToken = yield call(
        authenticateToken,
        authenticateRequest,
        values.username.trim(),
        values.password.trim()
      );
      yield put(validateLoggedUserSuccess(authenticatedToken));
    }
  } catch (error) {
    logger.info(error);
    yield put(validateLoggedUserFailure(error));
  }
}

export function* validateUser(values) {
  try {
    if (get(values, 'data.formData')) {
      yield put(setUserCreds(values.data.formData));
    }
    yield put(setUserType(''));
    const store = yield select();
    const arcotFromStore =
      get(store, 'global.loginData') && get(store, 'global.loginData.arcotId')
        ? get(store, 'global.loginData.arcotId')
        : '';
    const localStorageArcotData = getLocalStorage(
      btoa(getLabel(store, 'global.loginData.userdata.username').trim().toUpperCase()),
      false
    );
    const arcotData = localStorageArcotData || arcotFromStore;
    yield call(
      userAuthentication,
      arcotData,
      get(store, 'global.loginData.userdata'),
      get(values, 'data.isArcot')
    );
  } catch (error) {
    yield put(validateLoggedUserFailure(error));
  }
}

export function getRedirectionURL(username) {
  let redirectURL = REDIRECTION_URL;
  const pathParam = getURLPathParams();
  if (pathParam.size > 0) {
    if (pathParam.get('deeplink')) {
      redirectURL = `${REDIRECTION_DEEP_LINK_URL}/${pathParam.get('deeplink')}`;
    }
  }
  // TODO: will remove after poc will come up with strategic solution
  const smartUsers = ['devmember01', 'devmember11', 'devmember17', 'devmember12'];
  if (username && smartUsers.includes(username)) {
    redirectURL = GHP_URL;
  }
  return redirectURL;
}

export function* proceedToSilentLoginSaga() {
  const state = yield select();
  const TimerID = get(state, 'global.globalData.sessionTimerId');
  const isRememberMe = get(state, 'global.loginData.userdata.remmemberMe');
  const username = get(state, 'global.loginData.userdata.username');
  const user = aes.encrypt(username, process.env.REACT_APP_SECRET_KEY).toString();
  if (isRememberMe) {
    setLocalStorage('local-storage-rememberMe', { user });
  } else {
    removeLocalStorage('local-storage-rememberMe');
  }
  yield put(resetUserCreds());

  if (TimerID) {
    clearTimeout(TimerID);
  }

  setTimeout(() => {
    /* istanbul ignore next */
    window.location = getRedirectionURL(username);
  }, 0);
}

export function* verificationRequired(requestData) {
  try {
    const store = yield select();
    const requestToken = {
      token: get(store, 'global.loginData.token'),
    };
    const currentJourney = get(store, 'global.globalData.currentJourney');
    let siteSection = '';
    let chanelAcronym = '';

    if (currentJourney === RETRIEVE_USERNAME_JOURNEY) {
      siteSection = SITE_SECTION_RETRIEVE;
      chanelAcronym = CHANNEL_ACRONYM_RETRIEVE;
    }

    if (currentJourney === RESET_PASSWORD_JOURNEY) {
      siteSection = SITE_SECTION_RESET;
      chanelAcronym = CHANNEL_ACRONYM_RESET;
    }

    const currentPopup = {
      isShowPopup: false,
      newActivePopup: '',
    };
    yield put(setActivePopup(currentPopup));

    let data;
    if (get(requestData, 'formData.newActivePage') === 'OTP_VERIFY') {
      data = yield call(axiosServiceClient.post, apiEndPoints.forgotSendOtp, requestToken);
      const currentPage = {
        isPageChanged: true,
        newActivePage: OTP_VERIFY,
      };
      yield put(setToken(data.data.token));
      yield put(setActivePage(currentPage, OTP_ANALYTICS, siteSection, chanelAcronym));
    } else if (get(requestData, 'formData.newActivePage') === 'OTP_SECURITY_QA') {
      data = yield call(axiosServiceClient.post, apiEndPoints.ValidateSecQ, requestToken);
      yield put(setToken(data.data.token));
      yield put(setSecurityQuestionToStore(data.data.secq));
      const currentPage = {
        isPageChanged: true,
        newActivePage: OTP_SECURITY_QA,
      };

      yield put(setActivePage(currentPage, CSQA_ANALYTICS, siteSection, chanelAcronym));
    }
    yield put(verifyRequiredSuccess(data));
  } catch (error) {
    const code = get(error, 'response.data.code');
    if (code === TOKEN_EXPIRED) {
      const currentPage = {
        isShowPopup: true,
        newActivePopup: TOKEN_EXPIRED_POPUP,
      };
      yield put(setActivePopup(currentPage, TOKEN_EXPIRED_ANALYTICS));
    } else if (code !== RESEND_LIMIT_REACH) {
      yield put(showServiceError(SERVICE_ERROR));
    }
    yield put(verifyRequiredFailure(error));
  }
}
/**
 * This saga starts the Change username flow when a
 * migrated user decides to change the username.
 * It watches for CHANGE_USER_NAME action for this
 *
 * @export
 * @param {any} action
 */
export function* changeUserNameSaga(action) {
  const currentPage = {
    isPageChanged: true,
    newActivePage: CHANGE_USER,
  };
  yield put(setToken(action.token));
  yield put(setActivePage(currentPage, CHANGE_USER_NAME_ANALYTICS));
}

/**
 * This saga starts the flow of login when a migrated user
 * decides to keep his existing username as it is.
 * It watches for KEEP_USER_NAME action for this
 *
 * @export
 * @param {any} action
 */
export function* keepUserNameSaga(action) {
  try {
    const token = {
      token: action.token,
    };
    yield put(setToken(action.token));
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.keepUsername, token);
    yield put(setToken(data.token));
    if (data.arcotId) {
      yield put(setArcotId(data.arcotId));
    }
    yield put(validateLoggedUser());
    yield put(keepUserNameSuccess());
  } catch (error) {
    yield put(keepUserNameFailure(error));
    yield call(redirectToActivePage, error.response);
  }
}
/**
 * This saga checks for expired password for migrated admin user
 * @export
 * @param {any} action
 */
export function* expireCheckSaga(action) {
  try {
    const token = {
      token: action.token,
    };
    const { data } = yield call(axiosServiceClient.post, apiEndPoints.expiryCheck, token);
    yield put(setToken(data.token));
    yield call(keepUserNameSaga, data);
    yield put(expireCheckSuccess(data));
  } catch (error) {
    yield call(redirectToActivePage, error.response);
    yield put(expireCheckFailure(error));
  }
}

export function* redirectLogout() {
  try {
    const { data } = yield call(axiosServiceClient.get, apiEndPoints.logout);
    yield put(redirectLogoutSuccess(data));
    window.location.assign(LOGIN_URL);
  } catch (error) {
    logger.info(error);
    yield put(redirectLogoutFailure(error));
  }
}

export function redirectToLoginPage() {
  window.location.assign(LOGIN_URL);
}

export default function* loggedUser() {
  yield all([
    takeLatest(VALIDATE_LOGGED_USER, validateUser),
    takeLatest(PROCEED_TO_SILENT_LOGIN, proceedToSilentLoginSaga),
    takeLatest(VERIFY_REQUIRED, verificationRequired),
    takeLatest(CHANGE_USER_NAME, changeUserNameSaga),
    takeLatest(KEEP_USER_NAME, keepUserNameSaga),
    takeLatest(EXPIRY_CHECK, expireCheckSaga),
    takeLatest(REDIRECT_LOGOUT, redirectLogout),
    takeLatest(REDIRECT_TO_LOGIN_PAGE, redirectToLoginPage),
    fork(retrieveUsernameSaga),
    takeLatest(GET_MEMBER_INFO, getMemberInfo),
  ]);
}
