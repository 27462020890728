/*
 *
 * AnswerSecurityQuestions actions
 *
 */
// @flow

import {
  ANSWER_SECURITY_QUESTION,
  ANSWER_SECURITY_QUESTION_FAILURE,
  ANSWER_SECURITY_QUESTION_SUCCESS,
} from './AnswerSecurityQuestions.constants';

const answerSecurityQuestions = (payload: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION,
  payload,
});
export const answerSecurityQuestionsSuccess = (data?: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION_SUCCESS,
  data,
});
export const answerSecurityQuestionsFailure = (error?: { [string]: string }) => ({
  type: ANSWER_SECURITY_QUESTION_FAILURE,
  error,
});

export default answerSecurityQuestions;
