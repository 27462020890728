import SessionTimer from '@fil-uk/fil-lib-sessiontimer';

import SESSION_CONST from './constants';

const initSession = (setActivePopup) =>
  SessionTimer(
    SESSION_CONST.keepAliveUrl,
    setActivePopup,
    SESSION_CONST.keepALiverInterval,
    SESSION_CONST.inactivityCallbackInterval
  );

export default initSession;
