import { createSelector } from 'reselect';
import get from 'lodash/get';

export const selectLabels = (state) => get(state, ['global', 'globalData', 'labels']);

export const selectActivePopup = (state) => get(state, ['global', 'loginData', 'activePopup']);

export const selectHasServiceError = (state) => get(state, ['global', 'loginData', 'serviceError']);

export const selectOptNotificationKey = (state) =>
  get(state, ['OtpVerification', 'showOtpMessages', 'optNotificationKey']);

export const selectOtpVerificationLabels = createSelector(selectLabels, (labels) => ({
  ...get(labels, ['otpVerificationLabels']),
  rememberDeviceLabels: get(labels, ['rememberDevicePopup']),
  accountWeakPassword: get(labels, ['accountWeakPassword']),
}));

export const selectServiceErrors = createSelector(selectLabels, (labels) =>
  get(labels, ['serviceErrors'])
);

export const selectIsFetching = (state) =>
  get(state, ['LoginPage', 'verifyRequired', 'isFetching']) ||
  get(state, ['LoginPage', 'validateLoggedUser', 'isFetching']) ||
  get(state, ['OtpVerification', 'submitOtp', 'isFetching']) ||
  get(state, ['OtpVerification', 'resendOtp', 'isFetching']) ||
  get(state, ['LoginPage', 'keepUsername', 'isFetching']);
