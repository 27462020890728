import { updateState } from '@fil-global/eiswagen';

import {
  RETRIEVE_USER_NAME,
  RETRIEVE_USER_NAME_FAILURE,
  RETRIEVE_USER_NAME_SUCCESS,
  SET_PI_DETAILS,
} from './RetrieveUserName.constants';

export default function RetrieveUserName(state = {}, action) {
  switch (action.type) {
    case RETRIEVE_USER_NAME:
      return updateState(state, { isFetching: true });
    case RETRIEVE_USER_NAME_FAILURE:
      return updateState(state, { isFetching: false, error: true });
    case RETRIEVE_USER_NAME_SUCCESS:
      return updateState(state, {
        isFetching: false,
        username: action.username,
        isNonMigrated: action.isNonMigrated,
      });
    case SET_PI_DETAILS:
      return updateState(state, { piDetails: action.piDetails });
    default:
      return state;
  }
}
